import moment, { Moment } from 'moment-timezone';

export const getDateRangeToToday = (rangeInDays = 30) => {
  const today = new Date();
  const rangeInMilliseconds = rangeInDays * 24 * 60 * 60 * 1000;
  const startDateTime = new Date(today.getTime() - rangeInMilliseconds);

  return { today, startDateTime };
};

export const getIsDefaultDateRange = (filter: string, rangeInDays = 30) => {
  const { startDateTime } = getDateRangeToToday(rangeInDays);
  const dateRegex = /Date Range: ([A-Za-z]+\s\d{1,2},\s\d{4})/;
  const defaultStartDateMatch = filter.match(dateRegex);
  const defaultEndDateMatch = filter.includes('Today');
  return (
    defaultEndDateMatch &&
    defaultStartDateMatch &&
    String(defaultStartDateMatch[1]) ===
      String(moment(startDateTime).format('LL'))
  );
};

// Need to round the unix time to the nearest minute
// to avoid multiple api calls from a quickly changing unix timestamp
export const roundUpToNearestMinute = (unixTimestamp: number) => {
  // Create a Date object from the Unix timestamp (milliseconds)
  let date = new Date(unixTimestamp * 1000);

  // Round to nearest minute
  date.setMinutes(date.getMinutes() + 1);

  // Set seconds to zero
  date.setSeconds(0);
  date.setMilliseconds(0);

  // Return the Unix timestamp (seconds)
  return Math.floor(date.getTime() / 1000);
};

type DateOptions = number | Date | null;
export const formatToUnixRounded = (timestamp: DateOptions) =>
  roundUpToNearestMinute(moment(timestamp)?.tz(moment.tz.guess()).unix());

export const getDateRangeValidationMessage = (start: Moment, end: Moment) => {
  const diffMins = end.diff(start, 'minutes');
  const diffDays = end.diff(start, 'days');
  const exceedsMaxRange = diffDays > 30;
  const startExceedsEnd = start > end;

  if (diffMins === 0) return 'noRangeGiven';
  if (startExceedsEnd) return 'startExceedsEnd';
  if (exceedsMaxRange) return 'exceedsMaxRange';
  return '';
};
