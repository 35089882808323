import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useSitesBackupsDetails } from '@newfold/huapi-js';
import { SitesBackupsDetails200 } from '@newfold/huapi-js/src/index.schemas';

type SiteBackupTypes = keyof SitesBackupsDetails200;

const useSiteBackups = (siteId: number, type: SiteBackupTypes) => {
  const backupDetails = useSitesBackupsDetails(siteId, {
    query: {
      enabled: !!siteId,
      select: ({ data }) => {
        if (!type) return data;
        const backup = !!data
          ? {
              ...data[type],
              has_backup_addon: !!data[type]?.addon_id,
            }
          : {};
        return backup;
      },
    },
  });

  return { ...backupDetails } as UseQueryResult<
    SitesBackupsDetails200['codeguard'] & SitesBackupsDetails200['jetpack'],
    AxiosError<unknown, any>
  >;
};

export default useSiteBackups;
