import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

type ActionHandlersPropOptions = {
  userName?: string;
  isDisabled: boolean;
  id?: number;
};

const ActionHandlers = ({
  userName = '',
  isDisabled = false,
  id = undefined,
}: ActionHandlersPropOptions) => {
  const { t } = useTranslation('sshUserManager');

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const removeUser = `users/remove`;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
      >
        <MenuItem
          disabled={isDisabled}
          onClick={() =>
            navigate(removeUser, {
              state: {
                changeSiteUrl: true,
                userName: userName,
                publicKeyId: id,
                isGlobal: !!id,
              },
            })
          }
        >
          <Typography color="error">{t('removeSSHUSER.removeUser')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionHandlers;
