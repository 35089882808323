export default function getListBySearch(
  list: object[] | undefined = [],
  searchValue: string,
  /**
   * An array of specific "keys" or any "key" (default)
   * to compare its value to the searchValue.
   * Example: ['name', 'url']
   * @default undefined
   */
  searchByKeys?: string[] | undefined,
) {
  return list?.filter((item) => {
    const listOfKeys = searchByKeys ?? Object.keys(item);

    return listOfKeys.some((key) =>
      // @ts-expect-error
      item[key]?.toString().toLowerCase().includes(searchValue.toLowerCase()),
    );
  });
}
