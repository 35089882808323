import { useTranslation } from 'react-i18next';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CTB from '~/components/CTB';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';

const AdvancedHostingUsageOutOfSpace = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'usageStatsCard',
    useSuspense: false,
  });

  const [canUpgrade] = useFeature('hosting_upgrade');
  const { contactLink, optimizeLink } = useBrandInfo();

  return (
    <Stack spacing={1.5}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <LightbulbOutlinedIcon color="action" />
        <Typography variant="h3">{t('runningOutOfSpaceTxt')}</Typography>
      </Stack>

      <Stack spacing={0.5}>
        {optimizeLink ? (
          <Stack
            borderRadius={1}
            direction={'row'}
            justifyContent="space-between"
            spacing={2}
            alignItems={'center'}
            sx={{
              backgroundColor: 'grey.100',
              pl: 2,
              pt: 1.5,
              pb: 1.5,
              pr: 1.5,
              lineHeight: 1,
            }}
          >
            <Typography variant="body2">{t('optimizeOption')}</Typography>
            <Link href={optimizeLink} target="_blank">
              <KeyboardArrowRightIcon
                sx={{ cursor: 'pointer', fontSize: 24 }}
                fontSize="small"
                color="action"
              />
            </Link>
          </Stack>
        ) : null}
        {canUpgrade && (
          <Stack
            borderRadius={1}
            direction={'row'}
            justifyContent="space-between"
            spacing={2}
            alignItems={'center'}
            sx={{
              backgroundColor: 'grey.100',
              pl: 2,
              pt: 1.5,
              pb: 1.5,
              pr: 1.5,
              lineHeight: 1,
            }}
          >
            <Typography variant="body2">{t('upgradeHostingOption')}</Typography>
            <CTB product="HOSTING_UPGRADE">
              {/* @ts-ignore */}
              <KeyboardArrowRightIcon
                sx={{ cursor: 'pointer', fontSize: 24 }}
                fontSize="small"
                color="action"
              />
            </CTB>
          </Stack>
        )}
        {contactLink ? (
          <Stack
            borderRadius={1}
            direction={'row'}
            justifyContent="space-between"
            spacing={2}
            alignItems={'center'}
            sx={{
              backgroundColor: 'grey.100',
              pl: 2,
              pt: 1.5,
              pb: 1.5,
              pr: 1.5,
              lineHeight: 1,
            }}
          >
            <Typography variant="body2">{t('contactSupportOption')}</Typography>
            <Link href={contactLink} target="_blank">
              <KeyboardArrowRightIcon
                sx={{ cursor: 'pointer', fontSize: 24 }}
                fontSize="small"
                color="action"
              />
            </Link>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
export default AdvancedHostingUsageOutOfSpace;
