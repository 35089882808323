import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MetricsSummary from './Summary';

const MetricsCard = () => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'performance.metrics',
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Stack
            direction="row"
            spacing={2}
            display="flex"
            alignItems="flex-start"
          >
            <Typography variant="h2">{t('title')}</Typography>
            {/*TODO: Remove this Chip after 90 days from the date of release.*/}
            <Chip
              size="small"
              label={
                <Typography variant="h5" color="text.primary">
                  {t('new')}
                </Typography>
              }
              sx={{
                backgroundColor: 'success.400',
                borderRadius: 2,
              }}
            />
          </Stack>
        }
        subheader={<Typography pt={1}>{t('desc')}</Typography>}
        action={
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate('metrics')}
          >
            {t('viewAllBtn')}
          </Button>
        }
      />
      <CardContent>
        <MetricsSummary />
      </CardContent>
    </Card>
  );
};

export default MetricsCard;
