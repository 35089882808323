import { SitesDomainsV2200DomainsItem } from '@newfold/huapi-js/src/index.schemas';

import getSiteUrlWithoutProtocol from '~/utils/getSiteUrlWithoutProtocol';

// Compares a URL to a domain to determine whether they match
export const validateSiteUrlDomain = (
  domainData: SitesDomainsV2200DomainsItem | undefined | null,
  siteUrl: string | undefined | null,
) => {
  if (!domainData || !siteUrl) return undefined;

  const siteUrlDomain = getSiteUrlWithoutProtocol(siteUrl);
  const domain = domainData?.domain;
  const baseDomain = domainData?.base_domain;
  const isAutoGenerated = !!domainData?.autogen_id;

  const domainIsUrl =
    (domain && siteUrlDomain && siteUrlDomain === domain) ||
    (isAutoGenerated &&
      baseDomain &&
      siteUrlDomain &&
      siteUrlDomain === baseDomain) ||
    false;

  return domainIsUrl;
};
