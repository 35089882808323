import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  useSitesSettingsPhpVersions,
  useSitesSettingsPhpVersionUpdate,
} from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import { useSiteContext } from '~/scenes/Site';

import PHPModal from './PHPModal';

const UpdatePHPVersion = ({ displayCard = false }) => {
  const { t } = useTranslation('site');
  const [openPHPModal, setOpenPHPModal] = useState(false);

  const showPHPModal = () => setOpenPHPModal(true);
  const [, { generateAlert }] = useAlerts();

  const { siteId } = useParams<{ siteId: string }>();

  const {
    data: siteInfo,
    isInitialLoading: isSiteLoading,
    isSiteOrHostingDisabled: isDisabled,
  }: any = useSiteContext();

  const siteUrl = siteInfo?.url;

  //site info call for atomic just gives the version without PHP eg: 8.2.
  const regexWithoutPHP = new RegExp('^[0-9.]+$');

  const currentPHP: string =
    siteInfo?.php_version && regexWithoutPHP.test(siteInfo?.php_version)
      ? 'PHP ' + siteInfo?.php_version
      : siteInfo?.php_version || 'N/A';

  const {
    data: phpVersions,
    isLoading: phpListLoading,
    isError: phpListError,
  } = useSitesSettingsPhpVersions(Number(siteId), {
    query: {
      enabled: !!siteId,
    },
  });

  const latestVersion = phpVersions?.data?.latest || '';
  const phpList = phpVersions?.data?.versions || [];
  const deprecatedList = phpVersions?.data?.deprecated || [];

  const { mutate: handlePHPUpdate, isLoading: versionUpdateInProgress } =
    useSitesSettingsPhpVersionUpdate({
      mutation: {
        onSuccess: ({ data }) => {
          const isSuccess = data.success;
          if (isSuccess) {
            generateAlert({
              severity: 'success',
              description: t('phpVersion.successMsg'),
              showCloseBtn: true,
            });
          }
        },
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('phpVersion.failureMsg'),
            showCloseBtn: true,
          });
        },
      },
    });

  const isDeprecatedPhpVersion =
    deprecatedList &&
    deprecatedList.length > 0 &&
    deprecatedList.includes(currentPHP);

  return (
    <>
      {!displayCard && (
        <Link
          component="button"
          variant="body2"
          onClick={showPHPModal}
          data-testid="php-overview"
          disabled={isDisabled || phpList?.length === 0 || currentPHP === 'N/A'}
        >
          {t('changeTxt')}
        </Link>
      )}
      {displayCard && (
        <Card id="php-version" data-testid="php-settings">
          <CardHeader title={t('phpVersion.title')} />
          <CardContent>
            <Stack spacing={3}>
              <Typography>{t('phpVersion.desc')}</Typography>
              <Stack spacing={1}>
                {isSiteLoading ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    data-testid="php-loader"
                  >
                    <Skeleton height={30} width={120} />
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="body2"
                      color={'text.secondaryContrast'}
                    >
                      {t('phpVersion.subTitle')}
                    </Typography>
                    <Typography data-testid="php-version">
                      {currentPHP}
                    </Typography>
                    {!phpListLoading && isDeprecatedPhpVersion && (
                      <Typography
                        variant="body2"
                        color={'text.secondaryContrast'}
                      >
                        <Chip label="Deprecated" color="warning" size="small" />
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
              <Stack
                spacing={{ xs: 3, md: 0 }}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Button
                  onClick={showPHPModal}
                  data-testid="change-btn"
                  disabled={
                    isDisabled || phpList?.length === 0 || currentPHP === 'N/A'
                  }
                  variant="outlined"
                >
                  {t('phpVersion.changeBtn')}
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
      {openPHPModal && (
        <PHPModal
          siteId={Number(siteId)}
          siteUrl={siteUrl as string}
          openPHPModal={openPHPModal}
          setOpenPHPModal={setOpenPHPModal}
          currentPHP={currentPHP as string}
          latestVersion={latestVersion as string}
          isDeprecatedPhpVersion={isDeprecatedPhpVersion}
          phpList={phpList as string[]}
          updatePHP={handlePHPUpdate}
          isLoading={versionUpdateInProgress}
          phpListLoading={phpListLoading || phpListError}
        />
      )}
    </>
  );
};

export default UpdatePHPVersion;
