import { PropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';

import SsoLink, { SsoVariantTypes } from '~/components/SsoLink';
import { useBrandInfo } from '~/hooks/useBrandInfo';

const AdvancedDNSEditor = ({
  variant = 'menuItem',
  isAutoGenerated = false,
  baseDomain = undefined,
  isDisabled = false,
  isAtomic = false,
  isDestiny = false,
}: PropsWithoutRef<{
  variant?: SsoVariantTypes;
  isAutoGenerated?: boolean;
  baseDomain?: string | null;
  isDisabled?: boolean;
  isAtomic?: boolean;
  isDestiny?: boolean;
}>) => {
  const { t } = useTranslation('domains');
  const { isDNSManagedInCpanel } = useBrandInfo();
  const advDNSEditorText = t('advancedDNSEditor');
  const ammDNSEditorHref = `/my-account/domain-center/dns-details?domain=${baseDomain}`;
  const variantLowerCase = variant.toLowerCase();

  // HostGator and Bluehost domains on shared are currently not managable
  // by AMM's DNS manager, so they must be managed in cPanel's Zone Editor
  // Advanced DNS Editor Menu item link for destiny should
  // redirect to AMM's DNS manager insted of cPanel's Zone Editor .
  if (!isAtomic && !isDestiny && isDNSManagedInCpanel && baseDomain !== null) {
    return (
      <SsoLink
        id="zoneeditor"
        disabled={isAutoGenerated || isDisabled}
        variant={variant} // menuItem or textLink
        type="hosting"
        queryParams={{
          app: 'zoneeditor',
          domain: baseDomain,
        }}
        data-testid={`advanced-dns-ssolink-${variantLowerCase}`}
      >
        {advDNSEditorText}
      </SsoLink>
    );
  }

  if (variant === 'textLink') {
    return (
      <Link
        aria-disabled={isAutoGenerated || isDisabled}
        target="_blank"
        href={ammDNSEditorHref}
        data-testid={`advanced-dns-amm-${variantLowerCase}`}
      >
        {advDNSEditorText}
      </Link>
    );
  }

  return (
    <MenuItem
      disabled={isAutoGenerated || isDisabled}
      onClick={() => window.open(ammDNSEditorHref, '_blank')}
      data-testid={`advanced-dns-amm-${variantLowerCase}`}
    >
      {advDNSEditorText}
    </MenuItem>
  );
};

export default AdvancedDNSEditor;
