import { bool, func, node, object, oneOfType, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useSiteContext } from '~/scenes/Site';

import CTAComponent from './CTAComponent';

const propTypes = {
  children: node.isRequired,
  avatarIcon: oneOfType([object, node, func]),
  avatarIconColor: string,
  textColor: string,
  tooltipText: oneOfType([string, object]),
  ctaText: string,
  handleCTA: func,
  inlineCTA: node,
  disableCTA: bool,
  ctaIsLoading: bool,
  isLoading: bool,
  isError: bool,
  dataTestId: string,
  tooltipTitle: string,
  updateInProgress: bool,
  inRow: bool,
  hideCTA: bool,
};

const StatusListItem = ({
  children,
  avatarIcon = null,
  avatarIconColor = null,
  textColor = undefined,
  tooltipText = null,
  ctaText = '',
  handleCTA = null,
  inlineCTA = null,
  disableCTA = false,
  ctaIsLoading = false,
  isLoading = false,
  isError = false,
  dataTestId = 'status',
  avatarIcon: AvatarIcon = null,
  tooltipTitle = null,
  updateInProgress = null,
  inRow = false,
  hideCTA = false,
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(
      textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth,
    );
  }, [children, isLoading]);

  const { isDisabled: siteDisabled } = useSiteContext();

  return isLoading ? (
    <Stack direction="row" spacing={1} mb={2}>
      <Skeleton variant="circular" height={24} width={24}>
        <Avatar />
      </Skeleton>
      <Skeleton height={24} width="80%" />
    </Stack>
  ) : (
    !isError && (
      <ListItem
        disableGutters
        dense
        alignItems={handleCTA ? 'flex-start' : 'center'}
      >
        {avatarIcon && (
          <ListItemAvatar>
            <Avatar data-testid="avatar-icon">
              <AvatarIcon
                {...(avatarIconColor && { color: avatarIconColor })}
              />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          data-testid={dataTestId}
          primary={
            <Stack direction={inRow ? 'row' : 'column'} spacing={inRow ? 1 : 0}>
              <Stack direction="row">
                <Tooltip
                  title={tooltipTitle}
                  disableHoverListener={!isOverflowed}
                >
                  <Typography
                    ref={textElementRef}
                    variant="body2"
                    color={textColor}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    noWrap
                  >
                    {children}
                  </Typography>
                </Tooltip>
                {tooltipText && (
                  <Tooltip
                    title={tooltipText}
                    arrow
                    placement="top"
                    data-testid="tooltip"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              <Stack alignItems="flex-start">
                <CTAComponent
                  handleCTA={handleCTA}
                  ctaIsLoading={ctaIsLoading}
                  updateInProgress={updateInProgress}
                  disableCTA={disableCTA || siteDisabled}
                  ctaText={ctaText}
                  dataTestId={dataTestId}
                  hideCTA={hideCTA}
                />
                {inlineCTA}
              </Stack>
            </Stack>
          }
        />
      </ListItem>
    )
  );
};

StatusListItem.propTypes = propTypes;
export default StatusListItem;
