import { useState } from 'react';

import { useHostingAccount } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import { useTenant } from '~/components/TenantProvider';
import HostingLoading from '~/scenes/Hosting/scenes/HostingLoading';
import { disableList, getHostingDisabled } from '~/utils/getHostingDisabled';

import useAccount from './useAccount';

export const defaultOptions = {
  maxAttempts: 20,
  maxFailureAttempts: 400,
  retryDelay: 3000,
  refetchInterval: 3000,
  isProvisioning: false,
  enabled: true,
};

const useHostingInfo = (
  props = {
    hostingId: undefined,
    key: undefined,
    options: defaultOptions,
    hostingList: undefined,
  },
) => {
  // Disabling lint rule for unused var `otherProps` as it's expected to be used in the future.
  // eslint-disable-next-line no-unused-vars
  const { hostingId, key, options, hostingList, ...otherProps } = props;
  const [pendingAttemptCount, setPendingAttemptCount] = useState(0);
  const [failureAttemptCount, setFailureAttemptCount] = useState(
    props?.options?.maxFailureAttempts,
  );
  const suspendedStates = ['disable', 'disabled'];
  const decomStates = ['decom'];
  const {
    hostingStatus: { isProvisioning },
  } = useMFEContext();

  const { isValidated: isTenantValidated } = useTenant();
  const { id: contextId } = useAccount();

  const id = hostingId || contextId;

  const {
    data: hostingAccount,
    error,
    failureCount,
    failureReason,
    isLoading,
    ...restProps
  } = useHostingAccount(id, {
    query: {
      enabled: !!id && options?.enabled && isTenantValidated,
      // Propose we remove the `queryKey` OR update it to something that's unique as it's sometimes called using the hosting ID and somtimes using the SiteID
      ...(key && { queryKey: [key] }),

      /*
      if AMM provides a "provisioning" hosting status and we get 403 responses
        keep retrying util we get a 200 response or after 20 minutes show an error message
      if AMM provides an "active" hosting status and we get 403
        stop retrying and show an error message
      any type of other error status (400, 512, etc...) will stop retrying and show an error message
      */
      retry: (failureCount, error) => {
        const keepPolling = error?.response?.status === 403 && isProvisioning;
        keepPolling
          ? setFailureAttemptCount(failureCount)
          : setFailureAttemptCount(options?.maxFailureAttempts);
        return keepPolling ? failureCount < options?.maxFailureAttempts : false;
      },
      retryDelay: options?.retryDelay,
      refetchInterval: (data, error) =>
        ['pending_create', 'create'].includes(data?.data?.status) &&
        pendingAttemptCount < options?.maxAttempts &&
        options?.refetchInterval,
      onSuccess: (data) => {
        setPendingAttemptCount((old) => old + 1);
      },
    },
    ...otherProps,
  });

  const isDeleted = ['deleted'].includes(hostingAccount?.data?.status);

  const isPendingCreate =
    ['pending_create', 'create'].includes(hostingAccount?.data?.status) &&
    pendingAttemptCount < options?.maxAttempts;

  // Should break the isProvisioning check out from a separate (new) value from HUAPI/FG
  const isProvisioningExtended =
    failureReason?.response?.status === 403 &&
    isProvisioning &&
    failureAttemptCount < options?.maxFailureAttempts;

  const isError =
    error?.response?.status ||
    pendingAttemptCount >= options?.maxAttempts ||
    (hostingAccount?.data?.status?.indexOf('failed') > -1 && 'error');

  const allHostingDisabled =
    hostingList?.data?.accounts?.filter(({ status }) =>
      disableList.includes(status),
    ).length === hostingList?.data?.accounts?.length;

  return {
    ...restProps,
    hostingId: id,
    isLoading,
    isPending: isPendingCreate || isProvisioningExtended,
    isDeleted,
    isError,
    isDisabled: isLoading || getHostingDisabled(hostingAccount?.data?.status),
    isSuspended:
      isLoading || suspendedStates.includes(hostingAccount?.data?.status),
    isDecommissioned:
      isLoading || decomStates.includes(hostingAccount?.data?.status),
    // isValid - general indiciation that hosting is ready to use in any component
    isValid: !isError && !isLoading && hostingAccount && !isPendingCreate,
    hostingAccount,
    errorStatusCode: failureReason?.response?.status,
    failureCount,
    allHostingDisabled,
    HostingLoading: () => (
      <HostingLoading
        loading={isLoading}
        pending={isPendingCreate || isProvisioningExtended}
        deleted={isDeleted}
        statusCode={failureReason?.response?.status}
        isError={isError}
      />
    ),
  };
};

export default useHostingInfo;
