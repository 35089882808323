// Remove SX props
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import SsoLink from '~/components/SsoLink';

import { UsersFilteredPropOptions } from '.';
import SsoMenu from './SsoMenu';
import UserTableHead from './UserTableHead';

type UsersTablePropOptions = {
  isLoading: boolean;
  users?: UsersFilteredPropOptions[];
};

type OrderByType = 'id' | 'display_name' | 'user_email' | 'role';

const tableContainerSx = {
  boxShadow: 0,
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
};

const tableSx = { border: '1px solid', borderColor: 'divider' };

const UsersTable = ({ users = [], isLoading }: UsersTablePropOptions) => {
  const { t } = useTranslation('users');

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<OrderByType | string>('');

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array: UsersFilteredPropOptions[]) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const userA = a[0] as UsersFilteredPropOptions;
      const userB = b[0] as UsersFilteredPropOptions;
      const userAIndex = a[1] as number;
      const userBIndex = b[1] as number;

      const orderSort =
        order === 'desc'
          ? descendingComparator(userA, userB, orderBy as OrderByType)
          : -descendingComparator(userA, userB, orderBy as OrderByType);

      if (orderSort !== 0) return orderSort;
      return userAIndex - userBIndex;
    });
    return stabilizedThis.map((el) => el[0] as UsersFilteredPropOptions);
  };

  const descendingComparator = (
    a: UsersFilteredPropOptions,
    b: UsersFilteredPropOptions,
    orderBy: OrderByType,
  ) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedUserList = isEmpty(orderBy) ? users : stableSort(users);

  const tableHeader = (
    <UserTableHead
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
    />
  );

  if (isLoading) {
    return (
      <TableContainer sx={tableContainerSx} data-testid="user-table-loader">
        <Table sx={tableSx}>
          {tableHeader}
          <TableBody>
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <TableRow key={key}>
                <TableCell>
                  <Skeleton variant="text" width={100} sx={{ my: 1 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={60} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={20} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer sx={tableContainerSx}>
      <Table sx={tableSx} aria-labelledby="tableTitle">
        {tableHeader}
        <TableBody>
          {users.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} data-testid="no-users-found">
                {t('noDataFound')}
              </TableCell>
            </TableRow>
          ) : (
            sortedUserList.map((user) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={user?.id}>
                  <TableCell>{user?.display_name}</TableCell>
                  <TableCell>{user?.user_email}</TableCell>
                  <TableCell data-testid="user-role">{user?.role}</TableCell>
                  <TableCell>
                    <SsoLink
                      type="site"
                      variant="text"
                      queryParams={{ app: 'profile' }}
                      // @ts-expect-error
                      userId={user?.id}
                    >
                      {t('login')}
                    </SsoLink>
                  </TableCell>
                  <TableCell>
                    <SsoMenu userId={user?.id} />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
