import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingAccount } from '@newfold/huapi-js';

import CTB from '~/components/CTB';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';

import Sites from '../Sites';
import AdvancedHostingDiskUsageStats from './components/AdvancedHostingDiskUsageStats';
import AdvancedHostingServerInfo from './components/AdvancedHostingServerInfo';
import AdvancedHostingServerStatus from './components/AdvancedHostingServerStatus';
import Wrapper from './components/Wrapper';

const AdvancedHostingOverView = ({
  container = {},
  hasPurchasedRecently = false,
}: PropsWithChildren<{
  container?: object;
  hasPurchasedRecently?: boolean;
}>) => {
  const { t } = useTranslation('hosting');
  const { id: hostingId } = useAccount();
  const { isDreamscape } = useBrandInfo();

  const { data: hostingAccount } = useHostingAccount(hostingId, {
    query: {
      enabled: !!hostingId,
    },
  });

  const [showServerControls] = useFeature('server_controls');
  const [canUpgrade] = useFeature('hosting_upgrade');
  const [hasSites] = useFeature('sites');

  return (
    <Wrapper container={container} hasPurchasedRecently={hasPurchasedRecently}>
      <Card>
        <CardContent>
          <Stack direction="column" spacing={5}>
            {!isDreamscape && (
              <Stack spacing={1}>
                <Typography variant="h2">{t('hosting:planTitle')}</Typography>
                <Stack direction={'row'} spacing={0.5}>
                  <Typography variant="body2">
                    {hostingAccount?.data?.billing?.product_name}
                  </Typography>
                  {canUpgrade && (
                    <CTB product="HOSTING_UPGRADE">
                      {/* @ts-ignore */}
                      <Typography variant="body2" noWrap>
                        {t('upgradeHosting')}
                      </Typography>
                    </CTB>
                  )}
                </Stack>
              </Stack>
            )}

            {showServerControls && (
              <>
                <Stack spacing={1}>
                  <AdvancedHostingServerStatus />
                </Stack>
                <Stack spacing={3}>
                  <AdvancedHostingServerInfo />
                  <Stack>
                    <AdvancedHostingDiskUsageStats />
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>

      {/* show site list if capability is enabled */}
      {hasSites && <Sites />}
    </Wrapper>
  );
};

export default AdvancedHostingOverView;
