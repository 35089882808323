import moment, { MomentInput } from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import HighlightedBox from '~/components/HighlightedBox';

type JetpackBackupProps = {
  planName?: String;
  event_time?: MomentInput;
  status?: String;
};

const JetpackBackup = ({
  planName = '',
  event_time = '',
  status = '',
}: JetpackBackupProps) => {
  const { t } = useTranslation('site');
  const isToday = moment(event_time).isSame(moment(), 'day');
  const isYesterday = moment(event_time).isSame(
    moment().subtract(1, 'day'),
    'day',
  );

  const lastBackUpDate = (() => {
    if (isToday) return t('cloud.backups.jetpack.todayTxt');
    if (isYesterday) return t('cloud.backups.jetpack.yesterdayTxt');
    return moment(event_time).format('MMMM D, YYYY');
  })();

  const inProgress = status?.toLowerCase() === 'inprogress';
  const noBackUps = event_time === null;

  if (noBackUps) {
    return (
      <Stack justifyContent={'space-between'} spacing={1}>
        <Typography variant="h3" noWrap>
          {t('cloud.backups.jetpack.plan')}
        </Typography>
        <Typography noWrap>{planName}</Typography>
        <HighlightedBox bgColor="primary">
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography fontWeight={600}>
              {t('cloud.backups.jetpack.noBackups')}
            </Typography>
          </Stack>
        </HighlightedBox>
      </Stack>
    );
  }

  return (
    <Stack justifyContent={'space-between'} spacing={1}>
      <Typography variant="h3" noWrap>
        {t('cloud.backups.jetpack.plan')}
      </Typography>
      <Typography noWrap>{planName}</Typography>
      {inProgress ? (
        <HighlightedBox bgColor="primary">
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CircularProgress size={18} color="primary" />
            <Typography fontWeight={600}>
              {t('cloud.backups.jetpack.inprogress')}
            </Typography>
          </Stack>
        </HighlightedBox>
      ) : (
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <SettingsBackupRestoreIcon />
          <Typography>{t('cloud.backups.jetpack.lastBackupTxt')}</Typography>
          <Typography fontWeight={600}>{lastBackUpDate}</Typography>
          <Typography variant="body1" fontWeight={600}>
            {t('cloud.backups.jetpack.atTxt')}
          </Typography>
          <Typography fontWeight={600}>
            {moment(event_time).tz(moment.tz.guess()).format('hh:mma')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default JetpackBackup;
