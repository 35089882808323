import { Trans, useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useBrandInfo } from '~/hooks/useBrandInfo';
import useResponsive from '~/hooks/useResponsive';

import {
  StepAttributeTypes,
  stepErrors,
  stepStatuses,
} from '../utils/stepData';
import {
  getIsActionableError,
  getStepIcon,
  getStepIconColor,
} from '../utils/stepDataHelpers';
import StatusChip from './StatusChip';

export type StepContentPropOptions = {
  stepType: string;
  stepStatus?: string;
  stepError?: string | null;
  isAnyError: boolean;
  handleRetry?: (state: boolean) => void;
};

const StepContent = ({
  stepType,
  stepStatus = stepStatuses.notstarted,
  stepError = null,
  isAnyError,
  handleRetry = () => {},
}: StepContentPropOptions) => {
  const { t } = useTranslation('domains', { keyPrefix: 'setupProgress' });
  const { phoneNumberFormatted } = useBrandInfo();
  const { isTablet: getIsTablet } = useResponsive();

  const canShowEta: string[] = [stepStatuses.active, stepStatuses.notstarted];
  const isActive: boolean = stepStatus === stepStatuses.active;
  // Whether this "error" state is non-fatal/temporary
  const isRetrying: boolean = stepErrors.inprogress.includes(stepError!);
  // Whether this "error" state is fatal/requires user action
  const isActionableError: boolean = getIsActionableError(stepError);
  const isUnregisteredError: boolean = stepErrors.unregistered.includes(
    stepError!,
  );

  const defaultStepAttributes: StepAttributeTypes = {
    status: stepStatuses.notstarted,
    statusChip: <StatusChip blank />,
    errorColor:
      isActionableError || isUnregisteredError ? 'error' : 'secondary',
    errorType:
      isActionableError || isUnregisteredError ? 'default' : 'takingLonger',
    actionType: stepErrors.retry.includes(stepError!)
      ? 'retry'
      : 'unrecoverable',
    canShowErrorSection: isAnyError,
  };

  interface ISteps {
    [key: string]: StepAttributeTypes;
  }
  const steps: ISteps = {
    // errored: will have both error and action rows
    actionableError: {
      ...defaultStepAttributes,
      status: stepStatuses.errored,
      statusChip: (
        <StatusChip
          label={t(`steps.${stepType}.statusChipError`)}
          textColor={'error.dark'}
          backgroundColor={'error.light'}
        />
      ),
      error: true,
      action: true,
    },
    // in progress - retrying: could have error row, will not have action row
    retrying: {
      // Let's hide this for now
      ...defaultStepAttributes,
      status: stepStatuses.active,
      statusChip: (
        <StatusChip
          label={'stillInProgress'}
          textColor={'secondary.dark'}
          backgroundColor={'secondary.light'}
          icon={<CircularProgress size={14} color={'secondary'} />}
        />
      ),
      error: true,
    },
    // succeeded or skipped: will not have error or action row
    completed: {
      ...defaultStepAttributes,
      status: stepStatuses.completed,
      statusChip: (
        <StatusChip
          label={'completed'}
          textColor={'success.dark'}
          backgroundColor={'success.light'}
        />
      ),
    },
    // in progress - initial attempt: will not have error or action row
    active: {
      ...defaultStepAttributes,
      status: stepStatuses.active,
      statusChip: (
        <StatusChip
          label={'inProgress'}
          textColor={'secondary.dark'}
          backgroundColor={'secondary.light'}
          icon={<CircularProgress size={14} color={'secondary'} />}
        />
      ),
    },
    default: { ...defaultStepAttributes },
  };

  const stepAttributes = (function () {
    if (isActive && (isActionableError || isUnregisteredError))
      return steps.actionableError;
    if (isActive && isRetrying) return steps.retrying;
    if (isActive) return steps.active;
    if (stepStatus === stepStatuses.completed) return steps.completed;
    return steps.default;
  })();

  // return actual thing
  return (
    <Stack
      data-testid={`step-content-${stepType}`}
      spacing={1.5}
      alignItems={'start'}
      justifyContent={'space-between'}
      height={'100%'}
    >
      {/* Name - always displayed */}
      <Stack flex={1} direction={'row'} alignItems={'center'} spacing={1}>
        {getIsTablet() ? (
          getStepIcon(stepType, getStepIconColor(stepAttributes.status))
        ) : (
          <></>
        )}
        <Typography fontWeight={500} fontSize={16}>
          {t(`steps.${stepType}.name`)}
        </Typography>
      </Stack>
      {/* Description - always displayed */}
      <Typography
        sx={{ flex: 2 }}
        fontSize={12}
        textAlign={'start'}
        lineHeight={'16px'}
        fontWeight={500}
      >
        {t(`steps.${stepType}.description`)}
      </Typography>
      {/* Estimated time to complete once started - displayed when 1) notstarted or 2) active */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={0.5}
        color={'grey.400'}
        fontSize={12}
        flex={1}
        sx={{
          visibility: canShowEta.includes(stepStatus) ? 'visible' : 'hidden',
        }}
      >
        <ScheduleOutlinedIcon fontSize={'inherit'} />
        <Typography fontSize={'inherit'}>
          {t(`steps.${stepType}.eta`)}
        </Typography>
      </Stack>
      {/* Current status chip - displayed when 1) active, 2) completed, or 3) errored */}
      <Typography component={'span'} sx={{ flex: 1 }} fontSize={12}>
        {stepAttributes.statusChip}
      </Typography>
      {/* Error message - displayed when errored; Content will vary depending on error type */}
      {stepAttributes.canShowErrorSection && (
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          textAlign={'start'}
          spacing={0.5}
          fontWeight={500}
          fontSize={12}
          flex={1}
          sx={{
            visibility: stepAttributes.error ? 'visible' : 'hidden',
          }}
        >
          <InfoOutlinedIcon
            fontSize={'small'}
            color={stepAttributes.errorColor}
          />
          <Stack spacing={1}>
            <Typography fontSize={'inherit'}>
              <Trans
                i18nKey={`domains:setupProgress.error.${stepAttributes.errorType}`}
                values={{
                  action: t(`steps.${stepType}.errorAction`),
                  product: t(`steps.${stepType}.errorProduct`),
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      )}
      {/* Error remediation - displayed when error is actionable; Content will vary depending on error type */}
      {isActionableError &&
        (stepAttributes.actionType === 'retry' ? (
          <Button
            variant={'contained'}
            onClick={() => handleRetry(true)}
            sx={{
              visibility: stepAttributes.action ? 'visible' : 'hidden',
              maxWidth: '70%',
            }}
          >
            <Trans i18nKey="domains:setupProgress.error.button" />
          </Button>
        ) : (
          <Typography
            fontSize={12}
            fontWeight={'bold'}
            textAlign={'start'}
            sx={{
              visibility: stepAttributes.action ? 'visible' : 'hidden',
            }}
          >
            <Trans
              i18nKey="domains:setupProgress.error.unrecoverable"
              values={{
                phoneNumber: phoneNumberFormatted,
              }}
            />
          </Typography>
        ))}
    </Stack>
  );
};

export default StepContent;
