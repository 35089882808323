import { XyzTransition } from '@animxyz/react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTenant } from '~/components/TenantProvider';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import useOnboarding from '~/hooks/useOnboarding';
import useQueryFeature from '~/hooks/useQueryFeature';

import AlertBanner from './components/AlertBanner';
import FirstSiteInstalling from './scenes/HostingDetail/components/FirstSiteInstalling';
import HostingLoading from './scenes/HostingLoading';

const Hosting = () => {
  const { t } = useTranslation('hosting');

  const { isLoading: tenantLoading, isProvisioning: tenantProvisioning } =
    useTenant();

  const {
    hostingAccount,
    isInitialLoading: isLoadingHostingAccount,
    isError,
    errorStatusCode,
    isLoading: hostingLoading,
    isPending: hostingPending,
    isDeleted,
  } = useHostingInfo();

  const { onboarding, firstSiteId } = useOnboarding();

  const [isPlesk] = useFeature('plesk');
  const [isWindows] = useFeature('windows');
  const [sitesEnabled] = useFeature('sites');
  const [canShowDomainSetup] = useQueryFeature('domainsetup');

  const showOnboardingModal =
    !isPlesk &&
    !isWindows &&
    sitesEnabled &&
    onboarding?.completed === false &&
    firstSiteId &&
    !canShowDomainSetup;

  const isLoading = hostingLoading || tenantLoading;
  const isPending = hostingPending || tenantProvisioning;

  // Tying the capabilities isLoading state to the HostingLoading state
  // so we don't show blank screens on initial load
  // when determining which hosting UI to render (i.e. Cloud/Shared)
  // TODO: combine capabilties into hosting account call
  const [, , isClientReady] = useFeature('sites');

  return (
    <XyzTransition
      // xyz="fade left"
      // appear
      // duration="auto"
      // mode="out-in"
      data-testid="hosting-detail"
    >
      <Container data-testid="hosting-details-container">
        {isLoading || isError || isDeleted || !hostingAccount || isPending ? (
          <HostingLoading
            loading={isLoading || !isClientReady}
            pending={isPending}
            deleted={isDeleted}
            statusCode={errorStatusCode}
            isError={isError}
          />
        ) : (
          <Stack spacing={3}>
            <AlertBanner />
            <Typography variant="h1">
              {isLoadingHostingAccount ? (
                <Skeleton variant="text" width="30%" />
              ) : (
                hostingAccount?.data?.billing?.product_name ?? t('planTitle')
              )}
            </Typography>
            <Outlet />
            {showOnboardingModal && (
              <FirstSiteInstalling
                siteId={firstSiteId}
                // @ts-expect-error
                onboarding={onboarding?.completed}
              />
            )}
          </Stack>
        )}
      </Container>
    </XyzTransition>
  );
};

export default Hosting;
