import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowRight from '@mui/icons-material/ChevronRight';
import CircleRounded from '@mui/icons-material/CircleRounded';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesSso } from '@newfold/huapi-js';

import useAlertStore from '~/components/Alerts/alertsStore';

const SiteCardScan = ({ status }: any) => {
  const { t } = useTranslation('site');
  const { siteId } = useParams();
  const malwareStatus = status?.malware_status;
  const scannerState = status?.scanner_state;
  const isScanning = status?.scanner_state === 'scanning';

  const [, { generateAlert }] = useAlertStore();
  const options = {
    query: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  };

  const { refetch } = useSitesSso(Number(siteId), undefined, options);
  const ssoLoadingPage = `${process.env.PUBLIC_URL}/loading/wordpress.html`;

  const redirectToWpAdmin = async (addonPage: any) => {
    var ssoWindow = window.open(ssoLoadingPage, '_blank');
    const { data, error } = await refetch();
    if (error) {
      ssoWindow?.close();
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    const url = data?.data?.sso;
    if (url) {
      // Parse the url and set the page
      let urlArray = url.split('/');
      let newUrl = `${urlArray[0]}//${urlArray[2]}/${urlArray[3]}/${addonPage}`;
      if (ssoWindow) ssoWindow.location = newUrl;
      return;
    }
    ssoWindow?.close();
  };
  return (
    <Stack spacing={1} alignItems={'center'}>
      <VerifiedUserIcon />
      <Stack direction="row" spacing={1}>
        {!!scannerState && isScanning ? (
          <CircularProgress size={20} />
        ) : !!malwareStatus && malwareStatus !== 'off' ? (
          <CircleRounded
            sx={{ fontSize: 12, fontWeight: 'medium', height: 20 }}
            color={malwareStatus === 'green' ? 'success' : 'error'}
          />
        ) : (
          ''
        )}
        <Typography variant="h3">{t('cloud.malwareScan')}</Typography>
        <ArrowRight
          style={{
            marginLeft: '0px',
          }}
        />
      </Stack>
      <Typography variant="body2">
        <Link
          disabled={isScanning}
          onClick={() => redirectToWpAdmin('admin.php?page=jetpack-protect')}
          component="button"
        >
          {isScanning ? 'Scanning' : t('cloud.startScan')}
        </Link>
      </Typography>
    </Stack>
  );
};

export default SiteCardScan;
