import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SitesNotFound = () => {
  const { t } = useTranslation('sites');

  return (
    <Paper
      variant="outlined"
      sx={{ borderStyle: 'dashed', p: 4, textAlign: 'center' }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <Typography variant="h3">{t('cloud.noSitesFound.header')}</Typography>
      </Stack>
    </Paper>
  );
};

export default SitesNotFound;
