import { useTranslation } from 'react-i18next';

import OpenInNew from '@mui/icons-material/OpenInNew';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  SitesDomainsV2200DomainsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import { validateSiteUrlDomain } from '../utils/validateSiteUrlDomain';

type DomainNameOptions = {
  domain?: SitesDomainsV2200DomainsItem;
  siteInfo?: SitesInfo200;
};

const DomainName = ({
  domain = undefined,
  siteInfo = undefined,
}: DomainNameOptions) => {
  const { t } = useTranslation('domains');

  const domainName = domain?.domain;
  const domainHref = `http://${domainName}`;

  const siteType =
    siteInfo?.type === 'wordpress' && siteInfo?.detected_type === 'wordpress'
      ? 'wordpress'
      : 'generic';

  const isAutoGenerated = domain?.autogen_id !== null;
  const isPendingDomain = validateSiteUrlDomain(domain, siteInfo?.pending_url);
  const isSiteUrlDomain = validateSiteUrlDomain(domain, siteInfo?.url);
  const hasChip = isPendingDomain || isAutoGenerated || isSiteUrlDomain;

  return (
    <Stack
      spacing={1}
      alignItems={'flex-start'}
      data-testid={'domain-row-domain-name'}
    >
      <Typography
        variant="body2"
        sx={{ fontStyle: isAutoGenerated ? 'italic' : undefined }}
      >
        <Link
          href={domainHref}
          underline="hover"
          color="inherit"
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          {domainName}
          <OpenInNew className="icon" fontSize="inherit" sx={{ ml: 0.5 }} />
        </Link>
      </Typography>

      {hasChip && (
        <Stack direction={'row'} spacing={1}>
          {isSiteUrlDomain && (
            <Chip
              data-testid={'domain-chip-site-url'}
              size={'small'}
              variant={'outlined'}
              label={t(`labels.${siteType}`)}
              color="primary"
            />
          )}
          {isAutoGenerated && (
            <Chip
              data-testid={'domain-chip-autogen'}
              size={'small'}
              variant={'outlined'}
              label={t('labels.tempDomain')}
            />
          )}
          {isPendingDomain && (
            <Chip
              data-testid={'domain-chip-pending'}
              size={'small'}
              variant={'outlined'}
              label={t('labels.pendingDomain')}
              color="secondary"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DomainName;
