import { useHostingAccount, useHostingCapabilities } from '@newfold/huapi-js';

import { useTenant } from '~/components/TenantProvider';
import useAccount from '~/hooks/useAccount';

import { isDevMode } from '../components/DevMode';

// get hosting capabilities
// example usage:
// const [isDestiny,, clientReady, didTimeout] = useFeature('destiny');
// const [isCPanel,, clientReady, didTimeout] = useFeature('cpanel');
type FeatureTypes = string;

const useFeature = (featureName: FeatureTypes) => {
  const {
    isLoading: isTenantLoading,
    isProvisioning: isTenantProvisioning,
    isValidated: isTenantValidated,
  } = useTenant();
  const isTenantReady = !isTenantLoading && !isTenantProvisioning;
  const { id } = useAccount();

  const enableHostingQuery = [
    'cpanel',
    'destiny',
    'plesk',
    'windows',
    'reseller',
    'dedicated',
    'shared',
    'atomic',
  ].includes(featureName);

  // for some features, we need to check the hosting parameters directly, such as `panel`
  const { data: hostingData, isLoading: isHostingLoading } = useHostingAccount(
    id,
    {
      query: {
        enabled: enableHostingQuery && !!id && isTenantValidated,
      },
    },
  );

  type HostingFeatureMapTypes = {
    [key: FeatureTypes]: boolean;
  };
  const hostingFeatureMap: HostingFeatureMapTypes = {
    cpanel: hostingData?.data?.panel === 'cpanel',
    destiny: ['ginac', 'destiny'].includes(hostingData?.data?.panel || ''),
    plesk: hostingData?.data?.panel === 'plesk',
    windows: hostingData?.data?.panel === 'windows',
    reseller: hostingData?.data?.type === 'reseller',
    dedicated: hostingData?.data?.type === 'dedicated',
    shared: hostingData?.data?.type === 'shared',
    atomic: hostingData?.data?.type === 'cluster_atomic',
  };

  // for other features, we must check the capabilities endpoint
  const {
    data,
    isLoading: isCapabilitiesLoading,
    isError,
    error,
  } = useHostingCapabilities(id, {
    query: { enabled: !!id && !enableHostingQuery && isTenantValidated },
  });
  const capabilitiesData: HostingFeatureMapTypes | undefined = data?.data;

  const checkFeature = (featureName: FeatureTypes) => {
    if (enableHostingQuery) return hostingFeatureMap[featureName] ?? undefined;
    return capabilitiesData?.[featureName] ?? undefined;
  };

  const hasFeature = checkFeature(featureName);
  const variables = {
    username: hostingData?.data?.username,
    accountType: hostingData?.data?.type + '_' + hostingData?.data?.panel,
  };

  // wait for both calls to finish before considering the client ready
  const clientReady = enableHostingQuery
    ? !isHostingLoading && isTenantReady
    : !isHostingLoading && isTenantReady && !isCapabilitiesLoading;

  const featureNotFound =
    !isCapabilitiesLoading && typeof hasFeature !== 'boolean';
  const didTimeout = isError || featureNotFound;

  if (featureNotFound && isDevMode)
    console.error(
      `useFeature: feature "${featureName}" not found, the following are available features:
        ${JSON.stringify(data?.data)}`,
    );

  if (isError && isDevMode)
    console.error(
      'Call to fetch capabilities failed. See Network or endpoint error.',
      error,
    );

  return [!!hasFeature, variables, clientReady, didTimeout] as [
    boolean,
    {
      username: string | undefined;
      accountType: string;
    },
    boolean,
    boolean,
  ];
};

export default useFeature;
