// Remove SX props
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { UsersFilteredPropOptions } from '.';
import SsoMenu from './SsoMenu';

type UsersCardPropOptions = {
  isLoading: boolean;
  users?: UsersFilteredPropOptions[];
};

const cardHeaderSx = {
  backgroundColor: 'info.light',
  p: 2,
};

const UsersCard = ({ users = [], isLoading }: UsersCardPropOptions) => {
  const { t } = useTranslation('users');

  if (isLoading) {
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map((key) => (
          <Card
            key={key}
            sx={{ mb: 4 }}
            data-testid={`user-card-loader-${key}`}
          >
            <CardHeader
              sx={cardHeaderSx}
              titleTypographyProps={{ variant: 'body1' }}
              title={<Skeleton variant="text" width={100} />}
            />
            <CardContent>
              <Typography sx={{ pb: 2 }}>
                <Skeleton variant="text" width={160} height={16} />
              </Typography>
              <Divider />
              <Typography sx={{ py: 2 }}>
                <Skeleton variant="text" width={120} height={16} />
              </Typography>
              <Divider />
            </CardContent>
          </Card>
        ))}
      </>
    );
  }

  if (users?.length === 0)
    return <Card data-testid="no-users-found">{t('noDataFound')}</Card>;

  return (
    <>
      {users?.map((user) => (
        <Card key={user?.id} sx={{ mb: 4 }}>
          <CardHeader
            sx={cardHeaderSx}
            titleTypographyProps={{ variant: 'body1' }}
            action={<SsoMenu userId={user?.id} />}
            title={user?.display_name}
          />
          <CardContent>
            <Typography sx={{ pb: 2 }}>{user?.user_email}</Typography>
            <Divider />
            <Typography sx={{ py: 2 }}>{user?.role}</Typography>
            <Divider />
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default UsersCard;
