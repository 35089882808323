// Remove SX and Style props
// import { string } from 'prop-types';
import { Global } from '@emotion/react';
import { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/material/styles/styled';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';

import { useSitesInfo } from '@newfold/huapi-js';

import useResponsive from '~/hooks/useResponsive';
import useSiteBackups from '~/hooks/useSiteBackups';
import useSitePath from '~/hooks/useSitePath';

import { getPages } from './TabsContent';

const drawerBleeding = 22;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Tabs = () => {
  const { isMobile, isDesktop } = useResponsive();
  const { siteId } = useParams();
  const { subPath } = useSitePath(siteId);
  // Need only the part consiting of the tab details, filtering in case any other values exist
  let value = subPath.split('/')[1] || ''; // value is undefined on Overview tab

  if (value.includes('#')) value = value.substring(0, value.indexOf('#'));

  const { data } = useSitesInfo(Number(siteId));
  const siteInfo = data?.data;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer =
    (open: boolean, callback?: (to?: string) => void) => (event: any) => {
      setIsDrawerOpen(open);

      if (callback && typeof callback === 'function') {
        callback();
      }
    };

  const { data: codeguardDetails } = useSiteBackups(siteId, 'codeguard');

  const pages = getPages(siteInfo, codeguardDetails);
  const currentTab = pages.find(({ path: to }) => to === value);

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      {isMobile() && currentTab && (
        <>
          <MUITabs
            variant="fullWidth"
            value={value}
            onClick={toggleDrawer(true)}
          >
            <Tab
              label={currentTab.title}
              icon={<ArrowDropDownIcon />}
              iconPosition="end"
              value={value}
              sx={{ justifyContent: 'start', paddingLeft: 0, minHeight: 40 }}
            />
          </MUITabs>

          {/* drawer */}

          <Global
            styles={{
              '.MuiDrawer-root > .MuiPaper-root': {
                height: `auto`,
                overflow: 'visible',
              },
            }}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <StyledBox
              sx={{
                position: 'absolute',
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: 'visible',
                right: 0,
                left: 0,
                minHeight: `${drawerBleeding}px`,
                borderBottom: `1px solid ${grey[300]}`,
              }}
            >
              <Puller />
              &nbsp;
            </StyledBox>
            <Box
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List sx={{ p: 0 }}>
                {pages.map(
                  ({ title, canShow, path: to }, index) =>
                    canShow && (
                      <Fragment key={`mobile-list-${to}`}>
                        <ListItem key={title} disablePadding>
                          <ListItemButton
                            onClick={() => {
                              toggleDrawer(false, navigate(to)!);
                            }}
                            // TODO: style should be overridden at theme level
                            selected={to === value}
                          >
                            <ListItemText primary={title} />
                          </ListItemButton>
                        </ListItem>
                        <Divider component="li" />
                      </Fragment>
                    ),
                )}
              </List>
            </Box>
          </SwipeableDrawer>
        </>
      )}
      {isDesktop() && (
        <MUITabs variant="scrollable" scrollButtons="auto" value={value}>
          {pages.map(
            ({ title, path: to, canShow, disabled }) =>
              canShow && (
                <Tab
                  sx={{ justifyContent: 'start' }}
                  label={title}
                  href={`./${to}`}
                  key={`tab-${to}`}
                  value={to}
                  disabled={disabled}
                />
              ),
          )}
        </MUITabs>
      )}
    </>
  );
};

export default Tabs;
