import { useBrandInfo } from '~/hooks/useBrandInfo';

export default function Main({ children }: { children: React.ReactNode }) {
  const { isDreamscape } = useBrandInfo();

  return (
    <section
      className="main"
      style={{
        maxWidth: isDreamscape ? undefined : '1200px',
        minHeight: '60vh',
        backgroundColor: 'inherit',
        paddingTop: isDreamscape ? undefined : '56px',
        paddingBottom: '56px',
      }}
    >
      {children}
    </section>
  );
}
