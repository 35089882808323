import { XyzTransition } from '@animxyz/react';
import { Trans, useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useResponsive from '~/hooks/useResponsive';

import ChangeSiteURLModal from '../../../scenes/Domains/components/ChangeSiteURLModal';
import { DomainSetupStep, stepKeys } from '../utils/stepData';
import { getStepIcon, getStepIconColor } from '../utils/stepDataHelpers';
import TempDomainInfoBox from './TempDomainInfoBox';
import UpdateURLPrompt from './UpdateURLPrompt';

export type DomainSetupProgressPropOptions = {
  activeStepIndex: number;
  isActionableError: boolean;
  isOnLastStep: boolean;
  isUnregisteredError: boolean;
  openChangeURLModal: boolean;
  setOpenChangeURLModal: (state: boolean) => void;
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  steps: DomainSetupStep[];
};

/*
 * Shows the status of all validation steps in a domain's setup
 * process as they relate to a site being accessible.
 */
const DomainSetupProgress = ({
  activeStepIndex,
  isActionableError,
  isOnLastStep,
  isUnregisteredError,
  openChangeURLModal,
  setOpenChangeURLModal,
  site,
  steps,
}: DomainSetupProgressPropOptions) => {
  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();

  const { t } = useTranslation('domains', { keyPrefix: 'setupProgress' });

  // If the caluclated active step number is outside the range of possible step numbers
  if (
    ![...Array(Object.keys(stepKeys).length).keys()].includes(activeStepIndex)
  )
    return <></>;

  const displayedActiveStep = activeStepIndex + 1;

  const ConnectorStyle = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: '16px',
      left: 'calc(-80% + 16px)',
      right: 'calc(100% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor:
          isActionableError || isUnregisteredError
            ? theme.palette.error.main
            : theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.success.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.grey[500],
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));

  const stepperStyle = (function () {
    return {
      [`& .${stepLabelClasses.root}`]: {
        alignItems: 'flex-start',
      },
      // add height to the root, labelContainer, and label to make the step content fill the height
      [`& .${stepLabelClasses.root}, & .${stepLabelClasses.labelContainer}, & .${stepLabelClasses.label}`]:
        {
          height: '100%',
        },
      // for labelContainer.alternativeLabel, maintain marginTop
      [`& .${stepLabelClasses.labelContainer}.${stepLabelClasses.alternativeLabel}`]:
        {
          marginTop: '16px',
        },
      // for label.alternativeLabel, remove marginTop
      [`& .${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
        marginTop: 0,
      },
    };
  })();

  return (
    // Effects don't work at all currently - will fix or remove
    <XyzTransition xyz="fade up stagger-2 delay-4">
      <Card data-testid="domain-setup-progress">
        <CardHeader title={t('title')} />
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={3}>
            {isTablet ? (
              <Card
                sx={{
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'grey.300',
                  boxShadow: 'none',
                  overflow: 'visible',
                }}
              >
                <CardHeader
                  action={
                    <Typography variant="body2" fontWeight={800}>
                      <Trans
                        i18nKey="domains:setupProgress.stepsRemainingText"
                        values={{
                          activeStep: displayedActiveStep,
                          totalSteps: steps.length,
                        }}
                      />
                    </Typography>
                  }
                />
                <CardContent
                  sx={{
                    py: 1,
                    '&:last-child': { paddingBottom: 2 },
                  }}
                >
                  <Stack spacing={2}>
                    {steps[activeStepIndex].component}
                    {!isOnLastStep && (
                      <>
                        <Divider variant="middle" />
                        <Stack direction={'row'} justifyContent={'flex-end'}>
                          <Typography fontSize={12}>
                            <Trans
                              i18nKey="domains:setupProgress.nextStepLabel"
                              values={{
                                nextStep: steps[displayedActiveStep].name,
                              }}
                              components={{ b: <b /> }}
                            />
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ) : (
              <Stepper
                activeStep={activeStepIndex}
                alternativeLabel
                // nonLinear // This prevents 'not started' steps from rendering as disabled, but breaks color on 'succeeded' connectors
                connector={<ConnectorStyle />}
                sx={stepperStyle}
              >
                {steps.map((step) => (
                  <Step key={step?.key} sx={{ height: '100%', gap: 3 }}>
                    <StepLabel
                      icon={getStepIcon(
                        step?.key,
                        getStepIconColor(step?.status),
                      )}
                    >
                      {step.component}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            {!!site &&
              (!isOnLastStep ? (
                <>
                  <TempDomainInfoBox site={site} />
                  {openChangeURLModal && site.pending_url_data?.domain && (
                    <ChangeSiteURLModal
                      open={openChangeURLModal}
                      setOpen={setOpenChangeURLModal}
                      domain={site.pending_url_data?.domain}
                      siteId={site.id}
                    />
                  )}
                </>
              ) : (
                <UpdateURLPrompt site={site} />
              ))}
          </Stack>
        </CardContent>
      </Card>
    </XyzTransition>
  );
};

export default DomainSetupProgress;
