import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSiteUpdate } from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import { useSiteContext } from '~/scenes/Site';

import WordPressModal from './WordpressModal';

const ManageWordpressVersion = () => {
  const { t } = useTranslation('site');
  const [openWordPressModal, setOpenWordPressModal] = useState(false);
  const showWordPressModal = () => setOpenWordPressModal(true);
  const [, { generateAlert }] = useAlerts();
  const { siteId } = useParams<{ siteId: string }>();

  const {
    data: siteInfo,
    isInitialLoading: isSiteLoading,
    isSiteOrHostingDisabled: isDisabled,
    refetch: refetchSiteInfo,
  } = useSiteContext();

  const currentWordPress: string = siteInfo?.version || 'N/A';
  const wordpressType = siteInfo?.version_ptr || '';
  const wordPressList = ['previous', 'latest', 'beta'];
  const { mutate: handleWordPressUpdate, isLoading: versionUpdateInProgress } =
    useSiteUpdate({
      mutation: {
        onSuccess: ({ data }) => {
          const isSuccess = data.success;
          if (isSuccess) {
            refetchSiteInfo();
            generateAlert({
              severity: 'success',
              description: t('wordpressVersion.successMsg'),
              showCloseBtn: true,
            });
          }
        },
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('wordpressVersion.failureMsg'),
            showCloseBtn: true,
          });
        },
      },
    });

  return (
    <>
      <Card id="wordPress-version" data-testid="wordPress-settings">
        <CardHeader title={t('wordpressVersion.title')} />
        <CardContent>
          <Stack spacing={3}>
            <Typography>{t('wordpressVersion.desc')}</Typography>
            <Stack spacing={1}>
              {isSiteLoading ? (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  data-testid="wordPress-loader"
                >
                  <Skeleton height={30} width={120} />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" color={'grey.800'}>
                    {t('wordpressVersion.subTitle')}
                  </Typography>
                  <Typography data-testid="wordpress-version">
                    {currentWordPress} (
                    {wordpressType.charAt(0).toUpperCase() +
                      wordpressType.slice(1)}
                    )
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              spacing={{ xs: 3, md: 0 }}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Button
                onClick={showWordPressModal}
                data-testid="change-btn"
                disabled={
                  isDisabled ||
                  wordPressList?.length === 0 ||
                  currentWordPress === 'N/A'
                }
                variant="outlined"
              >
                {t('wordpressVersion.changeBtn')}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {openWordPressModal && (
        <WordPressModal
          siteId={Number(siteId)}
          openWordPressModal={openWordPressModal}
          setOpenWordPressModal={setOpenWordPressModal}
          currentWordPress={currentWordPress as string}
          wordPressList={wordPressList as string[]}
          updateWordPress={handleWordPressUpdate}
          isLoading={versionUpdateInProgress}
          WordPressListLoading={false}
          WordpressType={wordpressType}
        />
      )}
    </>
  );
};

export default ManageWordpressVersion;
