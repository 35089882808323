import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useParams } from 'react-router-dom';

import StorageIcon from '@mui/icons-material/StorageOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SsoLink from '~/components/SsoLink';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';

import { useSiteContext } from '../..';
import SuspendedSiteBanner from '../../components/SuspendedSiteBanner';
import { HeaderAppBar } from '../../HeaderAppBar';
import CloudTabNavigation from './components/Tabs';
// TODO: consider adding lazy to the following pages
import Backups from './scenes/Backups';
import Database from './scenes/Database';
import Domains from './scenes/Domains';
import Logs from './scenes/Logs';
import Overview from './scenes/Overview';
import Performance from './scenes/Performance';
import Settings from './scenes/Settings';
import SSH from './scenes/SSH';
import Users from './scenes/Users';

const FallbackCard = () => (
  <Card>
    <CardHeader title={<Skeleton width={220} />} />
    <CardContent>
      <Stack spacing={2}>
        <Typography>
          <Skeleton width={'100%'} />
        </Typography>
        <Typography>
          <Skeleton width={'100%'} />
        </Typography>
        <Typography>
          <Skeleton width={180} height={40} />
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);

// Atomic/WP Cloud Detail
export default function SiteDetailCloud() {
  const { t } = useTranslation('site');
  const { siteId } = useParams();

  const {
    isDisabled: isSiteDisabled,
    isHostingDisabled,
    isSiteOrHostingDisabled,
  } = useSiteContext();

  return (
    <>
      <HeaderAppBar>
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
          order={{ xs: 3, sm: 2 }}
        >
          <Stack spacing={2} direction="row">
            {/* PHP MyAdmin */}
            <SsoLink
              type="site"
              variant="outlined"
              disabled={!siteId || isSiteOrHostingDisabled}
              queryParams={{ app: 'phpmyadmin' }}
              // @ts-ignore
              startIcon={<StorageIcon />}
            >
              {t('cloud.phpMyAdmin')}
            </SsoLink>
            <SsoLink
              type="site"
              variant="contained"
              disabled={!siteId || isSiteOrHostingDisabled}
              // @ts-ignore
              startIcon={<WordPressIcon color="white" />}
            >
              {t('cloud.wpAdmin')}
            </SsoLink>
          </Stack>
        </Grid>
        <CloudTabNavigation disabled={isHostingDisabled} />
      </HeaderAppBar>
      <Container
        sx={{
          pl: { xs: 1, sm: 3 },
          pr: { xs: 1, sm: 3 },
          mt: 2,
        }}
      >
        {isSiteDisabled && <SuspendedSiteBanner />}
        <Suspense fallback={<FallbackCard />}>
          <Routes>
            <Route path="/">
              <Route path={``} element={<Overview />} />
              <Route path={`domains/*`} element={<Domains />} />
              <Route path={`backups/*`} element={<Backups />} />
              <Route path={`performance/*`} element={<Performance />} />
              <Route path={`logs/*`} element={<Logs />} />
              <Route path={`database/*`} element={<Database />} />
              <Route path={`ssh/*`} element={<SSH />} />
              <Route path={`users/*`} element={<Users />} />
              <Route path={`settings/*`} element={<Settings />} />
            </Route>
          </Routes>
        </Suspense>
      </Container>
    </>
  );
}
