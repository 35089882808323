import { useHostingOnboardingData } from '@newfold/huapi-js';

import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';

import useAccount from './useAccount';

// NOTE: Hooks will be converted to typescript in the very near future
const useOnboarding = (enabled = true, fetchSite = true) => {
  const { id: hostingId } = useAccount();

  const { data: onboardingData } = useHostingOnboardingData(hostingId, {
    query: {
      enabled: !!hostingId && enabled,
    },
  });

  const onboarding = onboardingData?.data;
  const hasOnboardingCompleted = typeof onboarding?.completed === 'boolean';
  const { data: hostingSites } = useFilteredHostingSites(hostingId, {
    query: {
      enabled: fetchSite && hasOnboardingCompleted && !!hostingId,
    },
  });
  const sites = hostingSites?.data?.items;
  const numberOfSites = hostingSites?.data?.items?.length;

  const firstSite = sites?.find(
    ({ type, docroot }) =>
      type?.toLowerCase() === 'wordpress' && docroot === 'public_html',
  );

  return { onboarding, firstSiteId: firstSite?.id, firstSite, numberOfSites };
};

export default useOnboarding;
