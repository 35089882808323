import Grid from '@mui/material/Grid';

import useHashScroll from '~/components/useHashScroll';
import ManageWordpressVersion from '~/scenes/Site/components/ManageWordpressVersion';
import UpdatePHPVersion from '~/scenes/Site/components/UpdatePHPVersion';
import AdjustStorage from '~/scenes/Site/scenes/Cloud/scenes/Settings/AdjustStorage';
import DeleteSite from '~/scenes/Site/scenes/Cloud/scenes/Settings/DeleteSite';

import Static404 from './Static404';

export default function Settings() {
  useHashScroll(500);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <AdjustStorage />
      </Grid>
      <Grid item xs={12} md={6}>
        <Static404 />
      </Grid>
      <Grid item xs={12}>
        <ManageWordpressVersion />
      </Grid>
      <Grid item xs={12}>
        <UpdatePHPVersion displayCard={true} />
      </Grid>
      <Grid item xs={12}>
        <DeleteSite />
      </Grid>
    </Grid>
  );
}
