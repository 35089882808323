import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import {
  getSitePluginsQueryKey,
  useSitePlugins,
  useSitePluginsUpdate,
  useSitesAutoupdatesInfo,
  useSitesSso,
  useSitesVersion,
} from '@newfold/huapi-js';
import { SitesSsoBounceName } from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import StatusListItem from '~/components/StatusListItem';
import { useSiteContext } from '~/scenes/Site';
import AutoUpdateType from '~/scenes/Site/components/AutoUpdateType';
import UpdatePHPVersion from '~/scenes/Site/components/UpdatePHPVersion';
// @ts-expect-error
import { ReactComponent as IconPhpVersion } from '~/scenes/Sites/assets/icons/IconPhpVersion.svg';
import WooCommerceIcon from '~/scenes/Sites/assets/icons/WooCommerceIcon';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';

const UpdateStatus = ({ wpSsoBounceName = 'updatecore' }) => {
  const { t } = useTranslation('site');
  const { siteId } = useParams();
  const siteIdNum = Number(siteId);

  const [, { generateAlert }] = useAlerts();

  const { data: siteInfo, isSiteOrHostingDisabled: isDisabled } =
    useSiteContext();

  // Condition for calls if site type is wordpress
  const canShow = siteInfo?.type === 'wordpress';

  const params = {
    bounce_name: wpSsoBounceName as SitesSsoBounceName,
  };

  const {
    data: wpVersion,
    isInitialLoading: isLoadingWPVersion,
    isSuccess: isSuccessWPVersion,
  } = useSitesVersion(siteIdNum, {
    query: {
      enabled: canShow,
      select: ({ data }) => data,
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('updateStatus.wpVersionError', {
            displayName: wpDisplayName,
          }),
          showCloseBtn: true,
        });
      },
    },
  });

  const { isFetching, refetch: fetchSso } = useSitesSso(siteIdNum, params, {
    query: {
      enabled: false,
    },
  });

  const pluginId = 'woocommerce';

  const sitePluginsParams = {
    plugin_id: pluginId,
  };

  const {
    data: WooCommercePlugin,
    isInitialLoading: isLoadingPlugins,
    isSuccess: isSuccessWooVersion,
    refetch: fetchPluginData,
  } = useSitePlugins(siteIdNum, sitePluginsParams, {
    query: {
      enabled: canShow && !!siteIdNum,
      select: ({ data }) =>
        data.plugins.find((plugin) => plugin.id === 'woocommerce'),
    },
  });

  const queryClient = useQueryClient();
  const queryKeyPlugin = getSitePluginsQueryKey(siteIdNum, {});

  const { isLoading: isLoadingPluginUpdate, mutate: pluginUpdate } =
    useSitePluginsUpdate({
      mutation: {
        onMutate: async () => {
          await queryClient.cancelQueries(queryKeyPlugin);
        },
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('updateStatus.errorOnPluginUpdate'),
            showCloseBtn: true,
          });
        },
        onSettled: (data, error) => {
          if (error) {
            queryClient.invalidateQueries(queryKeyPlugin);
          }
        },
        onSuccess: () => {
          // TODO: This isn't needed since the query invalidates in the HTTPClient response interceptor.
          fetchPluginData();
          generateAlert({
            severity: 'success',
            description: t('updateStatus.successOnUpdatePlugin'),
            showCloseBtn: true,
          });
        },
      },
    });

  const handlePluginUpdate = () => {
    pluginUpdate({
      siteId: siteId!,
      data: {
        id: 'woocommerce',
      },
    });
  };

  const redirectToSsoUrl = async () => {
    const { data, error } = await fetchSso();

    const url = data?.data?.sso;

    if (error || !url) {
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    window.open(url, '_blank');
  };

  const { data: wpDataAU, isInitialLoading: isLoadingAutoUpdates } =
    useSitesAutoupdatesInfo(siteIdNum, {
      query: {
        enabled: !!siteId && canShow,
        select: ({ data }) => data,
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('error.alertError', {
              item: t('updateStatus.title') + '. ',
            }),
            showCloseBtn: true,
          });
        },
      },
    });

  const wpDisplayName = siteInfo?.type === 'wordpress' ? 'WordPress' : '';
  const phpVersion = siteInfo?.php_version;

  return (
    <Grid item xs={4} sm={4} md={4}>
      <Typography variant="h3">{t('updateStatus.title')}</Typography>
      <List>
        {canShow && (
          // @ts-expect-error
          <StatusListItem
            isLoading={isLoadingWPVersion}
            ctaIsLoading={isFetching}
            // @ts-expect-error
            avatarIcon={WordPressIcon}
            // @ts-expect-error
            avatarIconColor="black"
            ctaText={t('updateNowBtn')}
            disableCTA={isDisabled}
            {...(wpVersion?.update && { handleCTA: redirectToSsoUrl })}
            dataTestId="wpVersion"
          >
            {`${t('updateStatus.versionText', {
              displayName: wpDisplayName,
            })} ${isSuccessWPVersion ? wpVersion?.current : 'N/A'}`}
          </StatusListItem>
        )}

        {WooCommercePlugin && canShow && (
          // @ts-expect-error
          <StatusListItem
            isLoading={isLoadingPlugins}
            ctaIsLoading={isLoadingPluginUpdate}
            // @ts-expect-error
            avatarIcon={WooCommerceIcon}
            // @ts-expect-error
            avatarIconColor="black"
            ctaText={t('updateNowBtn')}
            disableCTA={isDisabled}
            {...(WooCommercePlugin?.update && {
              handleCTA: handlePluginUpdate,
            })}
            dataTestId="wooCommerce"
          >
            {`${t('updateStatus.versionText', {
              displayName: WooCommercePlugin.title,
            })} ${isSuccessWooVersion ? WooCommercePlugin?.version : 'N/A'}`}
          </StatusListItem>
        )}

        {siteInfo &&
          canShow &&
          siteInfo?.type === siteInfo?.detected_type &&
          // @ts-expect-error
          t('updateStatus.autoUpdate.items', { returnObjects: true }).map(
            ({ type, label }: { type: string; label: string }) => (
              <AutoUpdateType
                component="overview"
                key={type}
                type={type}
                typeLabel={label}
                wpDataAU={wpDataAU}
                isLoading={isLoadingAutoUpdates}
                disabled={isDisabled}
              />
            ),
          )}
        {/* @ts-expect-error */}
        <StatusListItem
          avatarIcon={IconPhpVersion}
          dataTestId="PhpVersion"
          // @ts-expect-error
          inlineCTA={<UpdatePHPVersion />}
          inRow={true}
        >
          {t('phpVerTxt', {
            version: !phpVersion ? 'N/A' : phpVersion.replace('PHP ', ''),
          })}
        </StatusListItem>
      </List>
    </Grid>
  );
};

export default UpdateStatus;
