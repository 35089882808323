import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import useQueryFeature from '~/hooks/useQueryFeature';
import DocRoot from '~/scenes/Site/components/DocRoot';

import useFeature from '../../../../../hooks/useFeature';
import NameserverInfo from '../../../../Hosting/components/NameserverInfo';

const QuickConnect = () => {
  const { t } = useTranslation('site');
  const [isDestiny] = useFeature('destiny');
  const [enableDestiny] = useQueryFeature('destiny');

  type QuickConnectTransTypes = {
    nameservers: {
      id: string;
      label: string;
      tooltipText: string;
    };
    ssh: {
      id: string;
      label: string;
      secondayLabel: string;
      secondaryCta: string;
      tooltipText: string;
    };
  };
  const { nameservers, ssh } = t('quickConnect.items', {
    returnObjects: true,
  }) as QuickConnectTransTypes;

  return (
    <Stack spacing={1}>
      <Typography variant="h3">{t('quickConnect.title')}</Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', lg: 'space-between' }}
        alignItems="flex-start"
        spacing={2}
      >
        <InfoListItem
          key={ssh?.id}
          // @ts-expect-error
          title={ssh?.secondayLabel}
          // @ts-expect-error
          tooltipText={ssh?.tooltipText}
          {...{
            inlineCTA:
              isDestiny || enableDestiny ? (
                <Button variant="text" href={'/ssh-keys'} color="primary">
                  {ssh?.secondaryCta}
                </Button>
              ) : (
                <SsoLink
                  variant="text"
                  type={'hosting'}
                  queryParams={{ app: 'sshkeys' }}
                  data-testid="btn-advanced"
                  aria-disabled
                >
                  {ssh?.secondaryCta}
                </SsoLink>
              ),
          }}
        />

        <InfoListItem
          title={t('docRoot.title')}
          tooltipText={t('docRoot.tooltip')}
        >
          {/* @ts-expect-error */}
          <DocRoot />
        </InfoListItem>
        <InfoListItem
          key={nameservers.id}
          // @ts-expect-error
          title={nameservers.label}
          // @ts-expect-error
          tooltipText={nameservers.tooltipText}
        >
          {/* @ts-expect-error */}
          <NameserverInfo />
        </InfoListItem>
      </Stack>
    </Stack>
  );
};

export default QuickConnect;
