import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useSite } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';

type DeleteSiteModalProps = {
  openDeleteSiteModal: boolean;
  setOpenDeleteSiteModal: Function;
  siteUrl: string;
  siteId: number;
  siteName: string | undefined | null;
};

const DeleteSiteModal = ({
  openDeleteSiteModal,
  setOpenDeleteSiteModal,
  siteUrl,
  siteId,
  siteName,
}: DeleteSiteModalProps) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'settings.deleteSite.deleteModal',
  });

  const navigate = useNavigate();
  const generateAlert = useAlert();

  const { mutate: deleteSite, isLoading } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'info',
          description: t('alertMessage.success', {
            siteName: siteName,
          }),
          showCloseBtn: true,
        });
        navigate('/'); // navigate to hosting list
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('alertMessage.error'),
          showCloseBtn: true,
        });
        setOpenDeleteSiteModal(false);
      },
    },
  });

  const [isDeleteVerifiedByUserInput, setIsDeleteVerifiedByUserInput] =
    useState(false);

  const handleChange = (event: any) => {
    const deleteVal = t('deleteBtn');
    setIsDeleteVerifiedByUserInput(
      deleteVal.toUpperCase() === siteUrl.toUpperCase()
        ? event.target.value === siteUrl
        : siteUrl.toLowerCase() === event.target.value.toLowerCase(),
    );
  };

  const handleCancel = (event: any) => {
    setOpenDeleteSiteModal(false);
  };

  const handleSubmit = (event: any) => {
    deleteSite({ siteId });
  };

  return (
    <Dialog
      open={openDeleteSiteModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Trans i18nKey={t('title')} />
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Stack direction="row" spacing={2} py={2}>
          <WarningIcon color="error" />
          <Typography component="div" fontWeight={'bold'}>
            <Trans i18nKey={t('desc.0')} />
          </Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="div" pb={3}>
              <Trans i18nKey={t('desc.1')} />
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container my={3}>
          <Grid item xs={12}>
            <Typography component="div" variant="body1" pb={4}>
              <Trans
                i18nKey={t('confirmation')}
                values={{ siteUrl }}
                components={{
                  SiteUrl: <Typography color="error" />,
                }}
              />
            </Typography>
            <TextField
              data-testid="confirmURL"
              id="outlined-basic"
              label={t('enterSiteUrl')}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel} disabled={isLoading}>
          {t('cancelBtn')}
        </Button>
        <LoadingButton
          data-testid="confirmDeleteBtn"
          variant="contained"
          color="error"
          disabled={!isDeleteVerifiedByUserInput}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t('deleteBtn')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSiteModal;
