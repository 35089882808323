import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// TODO: define tab names/labels/paths/values programatically
function CloudTabNavigation({ disabled = false }) {
  const { siteId } = useParams();
  const location = useLocation();

  const getActiveTab = () => {
    const defaultTab = 'overview';
    const validTabs = [
      defaultTab,
      'domains',
      'database',
      'backups',
      'performance',
      'logs',
      'users',
      'ssh',
      'settings',
    ];
    const section = location.pathname.split('/')[3];
    if (!section) return defaultTab;
    if (!validTabs.includes(section)) return defaultTab;
    return section;
  };

  return (
    <Grid xs={12} item order={{ xs: 2, sm: 3 }} data-testid="navigation-tabs">
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={getActiveTab()}
        role="navigation"
      >
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}`}
          label={'Overview'}
          value="overview"
          disabled={disabled}
        />

        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/domains`}
          label="Domains"
          value="domains"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/backups`}
          label="Backups"
          value="backups"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/performance`}
          label="Performance"
          value="performance"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/logs`}
          label="Logs"
          value="logs"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/database`}
          label="Database"
          value="database"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/ssh/users`}
          label="SSH/SFTP"
          value="ssh"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/users`}
          label="Users"
          value="users"
          disabled={disabled}
        />
        <Tab
          component={RouterLink}
          to={`/sites/${siteId}/settings`}
          label="Settings"
          value="settings"
          disabled={disabled}
        />
      </Tabs>
    </Grid>
  );
}
export default CloudTabNavigation;
