// Remove SX props
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

// import Divider from '@mui/material/Divider';
// import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import WebIcon from '@mui/icons-material/Web';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MUIMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import {
  SitesListV2200,
  SitesListV2200ItemsItem,
} from '@newfold/huapi-js/src/index.schemas';

/**
 * Menu Component to display list of sites
 * - Future ideas:
 *   - [x] support extra long lists of sites
 *   - [ ] add small site thumbnail image
 *   - [ ] update site call to show all sites across all hosting ids
 *   - [ ] support add site flow
 */

type MenuPropOptions = {
  sites: SitesListV2200;
  anchorEl: null | HTMLElement;
  handleClose: VoidFunction;
};

export default function Menu({
  sites,
  anchorEl,
  handleClose,
}: MenuPropOptions) {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();

  const sitesList = sites?.items;

  const handleSiteMenu = (site: SitesListV2200ItemsItem) => {
    // @ts-expect-error
    queryClient.invalidateQueries('siteInfo');
    navigate(`../${site.id}`);
  };

  if (!Array.isArray(sitesList)) return null;

  return (
    <MUIMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      MenuListProps={{
        sx: { maxHeight: 248 },
      }}
    >
      {sitesList.map((site) => {
        const isCurrentSite = site.id === siteId ? true : false;
        return (
          <MenuItem
            key={`SiteTitle__MenuItem--${site.id}`}
            onClick={isCurrentSite ? undefined : () => handleSiteMenu(site)}
            selected={isCurrentSite}
          >
            <ListItemIcon>
              <WebIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {site.name}
              <br />
              <Typography noWrap variant="body2" color="text.secondary">
                {site.url || 'Site URL not set'}
              </Typography>
            </ListItemText>
          </MenuItem>
        );
      })}

      {/* TODO: in the future, we may allow adding sites from this menu */}
      {/* <Divider />
      <MenuItem>
        <ListItemIcon>
          <LibraryAddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Add Site</ListItemText>
      </MenuItem> */}
    </MUIMenu>
  );
}
