import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { useHostingAddons } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import BuilderSSOButtons from '~/scenes/Hosting/scenes/HostingDetail/components/BuilderSiteLogin/BuilderSSO';

const BuilderSSO = () => {
  const { id: accountId } = useAccount();
  const { data, isLoading, isError } = useHostingAddons(accountId);
  const hostingAddons = data?.data?.rows;

  const weebly = useMemo(
    () =>
      hostingAddons?.filter(
        (addon) => addon?.type === 'weebly' && addon?.status === 'active',
      ),
    [hostingAddons],
  );

  const webzai = useMemo(
    () =>
      hostingAddons?.filter(
        (addon) => addon?.type === 'webzai' && addon?.status === 'active',
      ),
    [hostingAddons],
  );

  if (isLoading || isError || (isEmpty(weebly) && isEmpty(webzai))) return null;

  return (
    <Stack direction="row" spacing={2} data-testid="builder-sso">
      {weebly?.length! > 0 && (
        <BuilderSSOButtons type="weebly" list={weebly!} id={weebly?.[0]?.id!} />
      )}
      {webzai?.length! > 0 && (
        <BuilderSSOButtons type="webzai" list={webzai!} id={webzai?.[0]?.id!} />
      )}
    </Stack>
  );
};

export default BuilderSSO;
