import { OverridableStringUnion } from '@mui/types';

export const stepKeys = {
  cdn: 'cdn',
  dns: 'dns',
  site: 'site',
  ssl: 'ssl',
  url: 'url',
};

export const stepStatuses = {
  active: 'active',
  completed: 'completed',
  errored: 'errored',
  notstarted: 'notstarted',
};

export const stepColors = {
  active: 'primary.main',
  completed: 'success.main',
  errored: 'error.main',
  notStarted: 'grey.500',
};

// This should exist somewhere more centralized
export type stepIconColor = OverridableStringUnion<
  | 'action'
  | 'disabled'
  | 'error'
  | 'info'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
>;

export const pendingURLJobTimeout = 'job_timeout';
export const domainUnregisteredError = 'not_registered';
export const stepErrors = {
  // These show up when the job is re-attempting to validate
  inprogress: [
    'A record doesnt match. Waiting for TTL',
    'DNS repair performed on domain.',
    'dns_a_record_flappy',
    'NS record doesnt match',
    'ssl_acme_check_fail',
    'ssl_acme_check_start',
    'ssl_waiting_resolve',
  ],
  // This is when a user should be allowed to re-queue the validation job
  retry: [pendingURLJobTimeout],
  // This is when external intervention is required to fix an issue
  unrecoverable: ['dns_a_record_fail', 'ssl_website_broken', 'invalid cert'],
  // We should give the option to purchase the domain (for a later release)
  unregistered: [domainUnregisteredError],
};

export type DomainSetupStep = {
  key: string;
  name: string;
  status: string;
  component: JSX.Element;
};

export type StepAttributeTypes = {
  action?: boolean;
  actionType?: string;
  error?: boolean;
  errorColor?: stepIconColor;
  errorType?: string;
  status: string;
  statusChip: JSX.Element;
};
