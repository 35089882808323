import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesSshUserListV2QueryKey,
  useSitesSshUserDelete,
} from '@newfold/huapi-js';
import { SitesSshUserListV2200 } from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';

type RemoveUserPropOptions = {
  siteId: number;
};

const RemoveUser = ({ siteId }: RemoveUserPropOptions) => {
  const { t } = useTranslation('sshUserManager');
  const generateAlert = useAlert();
  const navigate = useNavigate();

  const location = useLocation();
  const { userName, publicKeyId, isGlobal } = location.state;

  const queryClient = useQueryClient();
  const sshUserListV2QueryKey = getSitesSshUserListV2QueryKey(siteId);

  const { mutate: deleteSshUser, isLoading: isDeleteLoading } =
    useSitesSshUserDelete({
      mutation: {
        onSuccess: async () => {
          await queryClient.cancelQueries(sshUserListV2QueryKey);

          const previousUserList:
            | { data: SitesSshUserListV2200 | undefined }
            | undefined = queryClient.getQueryData(sshUserListV2QueryKey);

          queryClient.setQueryData(sshUserListV2QueryKey, () => {
            if (!previousUserList) return previousUserList;

            return {
              ...previousUserList,
              data: {
                ...previousUserList?.data,
                users: previousUserList?.data?.users?.filter(
                  (item) => item?.user !== userName,
                ),
              },
            };
          });

          return { previousUserList };
        },
        onError: (error: any, _key: any, context: any) => {
          queryClient.setQueryData(
            sshUserListV2QueryKey,
            context.previousKeysData,
          );
          generateAlert({
            severity: 'error',
            description:
              error?.response?.data?.error === 'sshKeyExists'
                ? t('errorExistingKeyNameToast')
                : t('removeSSHUSER.removeUserError'),
            showCloseBtn: true,
            persist: false,
          });
          navigate('..');
        },
        onSettled: (data: any) => {
          if (data?.status === 200) {
            generateAlert({
              severity: 'success',
              description: t('removeSSHUSER.success'),
              showCloseBtn: true,
              persist: false,
            });
            navigate('..');
          }
        },
      },
    });

  const handleDelete = () => {
    deleteSshUser({
      siteId: siteId!,
      data: {
        ssh_user: userName,
        public_key_id: isGlobal ? publicKeyId : 0,
      },
    });
  };

  return (
    <Dialog open onClose={() => navigate('..')}>
      <DialogTitle>
        <Stack direction="row" justifyContent="left" spacing={1}>
          <Typography variant="h2" color="error">
            {t('removeSSHUSER.title')}
          </Typography>
          <Typography variant="h2" color="error">
            {userName.toUpperCase()}
          </Typography>
        </Stack>
      </DialogTitle>
      {isGlobal ? (
        <DialogContent>
          <DialogContentText>
            {t('removeSSHUSER.globalSSHUserdesc')}
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>{t('removeSSHUSER.desc')}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button href=".." sx={{ mr: 1 }}>
          {t('removeSSHUSER.cancelTxt')}
        </Button>

        <LoadingButton
          loading={isDeleteLoading}
          variant="contained"
          onClick={handleDelete}
          color="error"
        >
          {t('removeSSHUSER.removeUser')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUser;
