import { XyzTransition } from '@animxyz/react';
// Remove SX props
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import useAlerts from '~/components/Alerts/alertsStore';
import DocrootMismatchAlert from '~/components/DocrootMismatchAlert';
import FixSiteAlertBox from '~/components/FixSiteAlertBox';
import LoadingScene from '~/components/MFEProvider/lib/ReactSuspense/LoadingScene.js';
import useQueryFeature from '~/hooks/useQueryFeature';
import useResponsive from '~/hooks/useResponsive';
import useSitePath from '~/hooks/useSitePath';
import {
  getCanShowDocrootMismatchAlert,
  getCanShowFixSiteModal,
} from '~/utils/siteMeta';

import { useSiteContext } from '.';
import DomainSetup from './components/DomainSetup';
import RenameModal from './components/RenameModal';
import SsoButtonGroup from './components/SsoButtonGroup';
import SuspendedSiteBanner from './components/SuspendedSiteBanner';
import Tabs from './components/Tabs';
import TabsContent from './components/TabsContent';
import { HeaderAppBar } from './HeaderAppBar';
import SiteError from './scenes/Error';

// TODO: move to its own file
export const HideOnScroll = ({ children, window = undefined }) => {
  const { isMobile } = useResponsive();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return isMobile() ? (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  ) : (
    <>{children}</>
  );
};

const Site = (props) => {
  const { t } = useTranslation('site');
  const { isMobile } = useResponsive();

  const [canShowDomainSetup] = useQueryFeature('domainsetup');
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [, { generateAlert }] = useAlerts();
  const { isValid, subPath } = useSitePath(siteId);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const closeModal = useCallback(() => setShowRenameModal(false), []);

  const {
    // @ts-expect-error
    data: siteInfo,
    // @ts-expect-error
    isDisabled: isSiteDisabled,
    isError,
  } = useSiteContext();

  const ssoList = ['wordpress', 'builder'];
  const hasSSO = ssoList.includes(siteInfo?.type);
  const hasMultipleSSOs = siteInfo?.type === 'builder';

  const statuses = {
    deleted: ['deleted', 'orphaned', 'orphaned_db'],
    deleting: [
      'pending_stop_decom',
      'stop_decom',
      'pending_destroy',
      'destroy',
      'delete',
    ],
    content: [
      'pending_restore',
      'restore',
      'pending_reprovision',
      'reset',
      'pending_reset',
    ],
    creating: ['create', 'pending_create'],
    failed: ['failed_create', 'failed_reset'],
  };

  useEffect(() => {
    const siteDeleted = statuses.deleted.includes(siteInfo?.status)
      ? 'deleted'
      : null;
    const siteDeleting = statuses.deleting.includes(siteInfo?.status)
      ? 'deleting'
      : null;
    const siteCreating = statuses.creating.includes(siteInfo?.status)
      ? 'creating'
      : null;
    const siteFailed = statuses.failed.includes(siteInfo?.status)
      ? 'failed'
      : null;
    const redirNeeded =
      siteDeleted || siteDeleting || siteCreating || siteFailed;

    if (redirNeeded) {
      navigate('/');
      generateAlert({
        severity: 'info',
        description: (
          <Trans
            components={{ Bold: <b>...</b> }}
            values={{ site: siteInfo?.name }}
            i18nKey={`site:actionStatus.${redirNeeded}`}
          />
        ),
        persist: true,
        showCloseBtn: true,
        preventDuplicate: true,
      });
    }
  }, [
    generateAlert,
    navigate,
    siteInfo,
    statuses.deleted,
    statuses.deleting,
    statuses.creating,
    statuses.failed,
  ]);

  if (isError) return <SiteError />;

  if (!isValid) return <Navigate to={subPath} replace />;

  let xyzTransitionInner = <></>;
  if (siteInfo) {
    if (getCanShowFixSiteModal(siteInfo)) {
      xyzTransitionInner = (
        <FixSiteAlertBox siteId={siteId} description={siteInfo?.description} />
      );
    } else if (getCanShowDocrootMismatchAlert(siteInfo)) {
      xyzTransitionInner = <DocrootMismatchAlert siteId={siteId} />;
    }
  }

  return (
    <>
      <HeaderAppBar props={props} setShowRenameModal={setShowRenameModal}>
        {' '}
        {hasSSO && (
          <Grid
            xs={hasMultipleSSOs ? 12 : 6}
            sm={6}
            item
            display="flex"
            justifyContent={
              isMobile() && hasMultipleSSOs ? 'flex-start' : 'flex-end'
            }
            order={{ xs: 3, sm: 2 }}
          >
            <SsoButtonGroup
              type={siteInfo?.type}
              detected_type={siteInfo?.detected_type}
              status={siteInfo?.status}
            />
          </Grid>
        )}
        <Grid
          xs={6}
          sm={12}
          item
          order={{ xs: 2, sm: 3 }}
          data-testid="navigation-tabs"
        >
          <Tabs type={siteInfo?.type} />
        </Grid>
      </HeaderAppBar>
      <Box
        sx={{
          pl: { xs: 1, sm: 3 },
          pr: { xs: 1, sm: 3 },
          mt: 2,
        }}
      >
        <Stack spacing={3}>
          <XyzTransition xyz="fade up">
            {isSiteDisabled && <SuspendedSiteBanner />}
          </XyzTransition>
          <XyzTransition xyz="fade up">
            {statuses.content.includes(siteInfo?.status) && (
              <Box>
                <Alert data-testid="site-action-delete-banner" severity="info">
                  <AlertTitle>
                    {t('actionStatus.deletingContent.title', {
                      site: siteInfo.name,
                    })}
                  </AlertTitle>
                  {t('actionStatus.deletingContent.description')}
                </Alert>
              </Box>
            )}
          </XyzTransition>
          {canShowDomainSetup && <DomainSetup site={siteInfo} />}
          <XyzTransition xyz="fade up">{xyzTransitionInner}</XyzTransition>
          <Stack spacing={3}>
            {/* Show LoadingScene when waiting for i18n, etc. */}
            <Suspense fallback={<LoadingScene />}>
              <TabsContent />
            </Suspense>
          </Stack>
        </Stack>
      </Box>
      <RenameModal
        showModal={showRenameModal}
        closeModal={closeModal}
        siteName={siteInfo?.name}
        siteId={siteId}
      />
    </>
  );
};

export default Site;
