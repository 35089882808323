// Create card variant for height 100
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

import { useMFEContext } from '~/components/MFEProvider';
import SsoLink from '~/components/SsoLink';
import { useTenant } from '~/components/TenantProvider';
import useFeature from '~/hooks/useFeature';
import { useSiteContext } from '~/scenes/Site';

const QuickLinks = () => {
  const tenant = useTenant();
  const { t } = useTranslation('site');
  // @ts-expect-error
  const { brand } = useMFEContext();
  const [isDestiny] = useFeature('destiny');

  // @ts-expect-error
  const { data: siteInfo, isDisabled } = useSiteContext();

  const dirPath = siteInfo?.path
    ? `${siteInfo?.docroot}/${siteInfo?.path}`
    : siteInfo?.docroot;
  //move the list of buttons into an array in this file instead of using the t() response
  let buttonList = [
    {
      title: t('quickLinks.cmsinstaller'),
      type: 'hosting',
      queryParams: { app: 'softaculous' },
      canShow:
        siteInfo && siteInfo?.type !== 'wordpress' && isDestiny === false,
    },
    {
      title: t('quickLinks.staging'),
      type: 'site',
      queryParams: { app: 'staging' },
      canShow:
        siteInfo &&
        siteInfo.type === 'wordpress' &&
        siteInfo.type === siteInfo.detected_type &&
        brand.toLowerCase() === 'bluehost',
    },
    {
      title: t('quickLinks.phpmyadmin'),
      type: 'hosting',
      queryParams: { app: 'phpmyadmin' },
      canShow: true,
    },
    {
      title: t('quickLinks.fileManager'),
      type: 'hosting',
      queryParams: {
        app: 'filemanager',
        ...(siteInfo?.docroot ? { dir: dirPath } : {}),
      },
      canShow: true,
    },
  ];

  buttonList = buttonList.filter((item) => item.canShow);

  return (
    <Card>
      <CardHeader title={t('quickLinks.cardTitle')} />
      <CardContent>
        <Grid container spacing={3} columns={1}>
          {Array.isArray(buttonList) &&
            buttonList.map(({ title, type, queryParams }) => (
              // @ts-expect-error
              <Grid
                xyz="fade left stagger-1 delay-3"
                className="xyz-nested"
                item
                lg={3}
                xs={12}
                sx={{ textAlign: 'center' }}
                key={`button-grid-${title}`}
              >
                <SsoLink
                  fluid
                  type={type}
                  queryParams={queryParams}
                  disabled={!tenant.isValidated || isDisabled}
                >
                  {title}
                </SsoLink>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuickLinks;
