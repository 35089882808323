import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesDomainsV2 } from '@newfold/huapi-js';
import {
  SitesDomainsV2Params,
  SitesDomainsV2200DomainsItem,
} from '@newfold/huapi-js/src/index.schemas';

import InfoListItem from '~/components/InfoListItem';
import VisionUITable from '~/components/VisionUI/Table';

import { useSiteContext } from '../..';
import DocRoot from '../../components/DocRoot';
import DomainRow from './DomainRow';
import TableLoading from './TableLoading';
import { validateDomainCanShow } from './utils/validateDomainCanShow';
import { validateSiteUrlDomain } from './utils/validateSiteUrlDomain';

const DomainsContent = () => {
  const { t } = useTranslation(['site', 'domains']);
  const { siteId } = useParams();

  const {
    isSiteOrHostingDisabled: isDisabled,
    data: siteInfo,
    isFetching: siteFetching,
    isInitialLoading: siteLoading,
  }: any = useSiteContext();

  const queryParams: SitesDomainsV2Params = {};
  const {
    data: sitesDomainsList,
    isInitialLoading: isSitesDomainListInitialLoading,
  } = useSitesDomainsV2(Number(siteId), queryParams, {
    query: {
      staleTime: 0,
      enabled: !!siteId,
    },
  });

  const domainsOnThisSite: SitesDomainsV2200DomainsItem[] | undefined =
    sitesDomainsList?.data?.domains?.filter(
      // Exclude unassigned domains for now - will include when domain assignment is added
      ({ status }) => validateDomainCanShow(status!),
    );

  // Move the site url domain to the top of the list
  const sortedSiteDomains: SitesDomainsV2200DomainsItem[] | undefined = orderBy(
    domainsOnThisSite,
    (domainObj: SitesDomainsV2200DomainsItem) =>
      validateSiteUrlDomain(domainObj, siteInfo?.url),
    ['desc'],
  );

  const numberOfDomains = Array.isArray(sortedSiteDomains)
    ? sortedSiteDomains.length
    : 0;

  const isLoading =
    isSitesDomainListInitialLoading || siteLoading || siteFetching;

  const domainRows = !isLoading
    ? sortedSiteDomains?.map((domain: SitesDomainsV2200DomainsItem) => (
        <DomainRow
          siteInfo={siteInfo}
          key={domain?.id}
          domain={domain}
          numberOfDomains={numberOfDomains}
          isDisabled={isDisabled}
        />
      ))
    : [];

  const headCells = [
    {
      id: 'domain',
      label: t('domains:headers.domain'),
      sort: true,
    },
    {
      id: 'dns-status',
      label: t('domains:headers.dnsStatus'),
    },
    {
      id: 'ssl-status',
      label: t('domains:headers.sslStatus'),
    },
    {
      id: 'actions',
    },
  ];

  return (
    <Stack spacing={2} sx={{ mb: 3 }} data-testid="domains-content">
      <Card>
        <CardHeader
          title={t('domains:title')}
          titleTypographyProps={{
            variant: 'h2',
            display: 'inline-block',
            mr: 1,
          }}
          subheader={`(${numberOfDomains})`}
          subheaderTypographyProps={{ sx: { display: 'inline-block' } }}
          action={
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              href="add"
              disabled={isDisabled}
              data-testid="domains-add-button"
            >
              {t('domains:addDomainBtn')}
            </Button>
          }
        />
        <CardContent sx={{ pt: 2 }}>
          <Stack spacing={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
              alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
              spacing={2}
            >
              {siteInfo?.docroot ? (
                <InfoListItem
                  title={t('site:docRoot.title')}
                  tooltipText={t('site:docRoot.tooltip')}
                >
                  {/* @ts-ignore */}
                  <DocRoot />
                </InfoListItem>
              ) : (
                <Typography sx={{ mb: 4 }}>
                  <Skeleton width={320} />
                </Typography>
              )}
            </Stack>

            {/* TODO: Incorporate ResponsiveTable once we have a mobile card design */}

            {/* domains list */}
            <VisionUITable
              headCells={headCells}
              isLoading={isLoading}
              // @ts-ignore
              loader={<TableLoading />}
              list={sortedSiteDomains}
              type={t('domains:listType')}
              emptyListCopy={t('domains:noDomainsFound')}
            >
              {/* @ts-expect-error */}
              {domainRows}
            </VisionUITable>
          </Stack>
        </CardContent>
      </Card>
      <Outlet />
    </Stack>
  );
};

export default DomainsContent;
