import { useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CTB from '~/components/CTB';

type WarningModalPropOptions = {
  openSiteLimit: boolean;
  setOpenSiteLimit: (state: boolean) => void;
};

const WarningModal = ({
  openSiteLimit,
  setOpenSiteLimit,
}: WarningModalPropOptions) => {
  const { t } = useTranslation('sites');

  const handleClose = () => setOpenSiteLimit(false);

  return (
    <Dialog
      fullWidth
      open={openSiteLimit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="add-site-warning-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>{t('siteLimit')}</DialogTitle>
        </Stack>
        <Divider />
        <Typography variant="body1" py={3}>
          {t('availableSites')}
        </Typography>
        <CTB product="HOSTING_UPGRADE">
          {/* @ts-expect-error */}
          <Typography variant="body" noWrap>
            {t('purchaseHostingPlan')}
          </Typography>
        </CTB>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
