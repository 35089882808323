import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { getSitesInfoQueryKey, useAccountHostingList } from '@newfold/huapi-js';
import {
  HostingSitesV2200,
  HostingSitesV2200ItemsItem,
  SitesListV2200ItemsItem,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import { invalidateMultipleQueries } from '~/components/MFEProvider/lib/HttpClient/queryClient';
import ResponsiveTable from '~/components/VisionUI/ResponsiveTable';
import SearchBar from '~/components/VisionUI/SearchBar';
import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';
import useFilteredSitesV2 from '~/hooks/useFilteredSitesV2';
import useHostingInfo from '~/hooks/useHostingInfo';
import useRefetchInterval from '~/hooks/useRefetchInterval';
import useResponsive from '~/hooks/useResponsive';
import AddSiteButton from '~/scenes/Hosting/components/AddSiteButton';
import getListBySearch from '~/utils/getListBySearch';

import DBWarningModal from './components/DBWarningModal';
import SitesCard from './components/SitesCard';
import SitesEmpty from './components/SitesEmpty';
import SitesTableBody from './components/SitesTableBody';
import WarningModal from './components/WarningModal';

type SitesPropOptions = {
  showAll?: boolean;
};
// TODO: add target path support `?i=` like in SiteCard.js
const Sites = ({
  showAll = false, // show all sites, minus any features that should not show when all are visible
}: SitesPropOptions) => {
  const { t } = useTranslation('sites');
  const theme = useTheme();
  const navigate = useNavigate();
  const generateAlert = useAlert();
  const queryClient = useQueryClient();

  const [openSiteLimit, setOpenSiteLimit] = useState(false);
  const [openDBLimit, setOpenDBLimit] = useState(false);
  const [hasPendingSite, setHasPendingSite] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [activateView, setActivateView] = useState('grid');
  const [pendingSiteQueries, setPendingSiteQueries] = useState<any[]>([]);

  const { isTablet } = useResponsive();

  const { refetchTime } = useRefetchInterval();

  const headCells = [
    {
      id: 'name',
      label: t('sitesTable.cells.0'),
      sort: true,
    },
    {
      id: 'type',
      label: t('sitesTable.cells.2'),
    },
    {
      id: 'login',
    },
  ];

  // API: GET all hosting plans
  const {
    data: hostingList,
    refetch: getHostingAccounts,
    isInitialLoading: loadingHostingAccounts,
  } = useAccountHostingList();
  const { hostingId: accountId, isDisabled } = useHostingInfo({
    hostingList: hostingList?.data?.accounts,
  });
  const {
    data,
    isLoading,
    // isError, // TODO: handle error, see comments below
  } = useFilteredHostingSites(accountId, {
    query: {
      enabled: !showAll,
      refetchInterval: hasPendingSite && refetchTime,
      refetchIntervalInBackground: hasPendingSite,
      onSuccess: async (data: AxiosResponse<HostingSitesV2200, any>) => {
        const sites = data?.data?.items;
        const pendingSites = sites?.filter((i) => {
          if (i.status === 'failed_create')
            return i.jobs && Object.values(i.jobs).includes('sync');
          return i.status !== 'active';
        }) as HostingSitesV2200ItemsItem[];

        const hasPendingSites = pendingSites?.length > 0;
        const hasPendingSiteQueries = pendingSiteQueries?.length > 0;
        const donePolling = !hasPendingSites && hasPendingSiteQueries;

        // Track sites that are pending, once they become active,
        // invalidate the site info call(s) to keep cache in sync
        if (hasPendingSites) {
          pendingSites?.forEach((site) => {
            const siteQueryKey = getSitesInfoQueryKey(Number(site?.id));
            const queryKeys = hasPendingSiteQueries
              ? [pendingSiteQueries, siteQueryKey]
              : [siteQueryKey];
            setPendingSiteQueries(queryKeys);
          });
        }
        if (donePolling) {
          await invalidateMultipleQueries(queryClient, pendingSiteQueries);
          setPendingSiteQueries([]);
        }
        return data;
      },
    },
  });

  // API: GET sites for all hosting plans
  const { data: v2Sites, isLoading: v2SitesLoading } = useFilteredSitesV2({
    query: { enabled: showAll },
  });

  const sites = !showAll
    ? (data?.data?.items as HostingSitesV2200ItemsItem[])
    : (v2Sites?.data?.items as SitesListV2200ItemsItem[]);

  // const productName = hostingAccountData?.data?.billing?.product_name;
  const maxSites = data?.data?.max_sites;
  const maxAllowedSitesFormatted = maxSites === null ? 'Unlimited' : maxSites;

  const usedSites = data?.data?.used_sites;

  useEffect(() => {
    setHasPendingSite(
      sites?.some((i: any) => {
        if (i.status === 'failed_create')
          return i.jobs && Object.values(i.jobs).includes('sync');
        return i.status !== 'active';
      }),
    );
  }, [sites]);

  const showNoSitesAvailableModal = () => setOpenSiteLimit(true);

  const handleSearch = (value: string) => {
    setPage(1);
    setSearchQuery(value);
  };

  const handleMultiHostingAddSite = async () => {
    const { data, isError } = await getHostingAccounts();
    const accounts = data?.data?.accounts;

    if (isError || !accounts) {
      return generateAlert({
        severity: 'error',
        description: t('addSiteErrMsg'),
        showCloseBtn: true,
        persist: false,
      });
    }

    if (accounts?.length === 1) {
      const { current_sites, max_sites } = accounts[0];

      const maxSitesValue =
        max_sites === 'unlimited' ? Infinity : parseInt(max_sites!) || null;

      const availableSites = maxSitesValue
        ? Math.max(maxSitesValue - current_sites!, 0)
        : null;

      if (!availableSites) {
        return showNoSitesAvailableModal();
      }

      return navigate('./add');
    }

    if (accounts?.length > 1) {
      window.location.href = '/my-account/hosting/list';
      return;
    }
  };

  const siteList = sites?.map((i) => ({
    ...i,
    hosting: hostingList?.data?.accounts?.find(
      (r) => Number(r.hosting_id) === Number(i?.account_id),
    ),
  }));

  const searchBy = ['name', 'url', 'pending_url'];
  const filteredSites = getListBySearch(siteList, searchQuery, searchBy);

  const handleToggleView = (view: string) => {
    setActivateView(view);
  };

  const noFilteredSites = filteredSites?.length < 1;
  const noSites = sites?.length < 1;
  const emptyListCopy = noSites ? t('noSites') : t('noSitesAfterSearch');
  const isLoadingAll =
    loadingHostingAccounts || (showAll ? v2SitesLoading : isLoading);

  return (
    <>
      <Card>
        <CardHeader
          disableTypography
          action={
            !(showAll && filteredSites?.length === 0) && (
              <AddSiteButton
                showingAllSites={showAll}
                isLoading={isLoadingAll}
              />
            )
          }
          title={
            <Typography variant="h2" component="span" sx={{ mr: 1 }}>
              {t('sitesListTitle')}
            </Typography>
          }
          subheader={
            !showAll &&
            maxAllowedSitesFormatted && (
              <Typography
                variant="h4"
                component="span"
                sx={{ color: theme.palette.grey[500] }}
              >
                {`(${usedSites || 0} of ${maxAllowedSitesFormatted})`}
              </Typography>
            )
          }
        />
        <CardContent>
          <Stack spacing={3}>
            {/* Search & Toggle Bar */}
            <Stack justifyContent="space-between" direction="row">
              <SearchBar
                searchQuery={searchQuery}
                placeholder={t('searchSites')}
                handleSearch={handleSearch}
                inputProps={{ sx: { width: { xs: '100%', sm: 400 } } }}
              />

              {/* Toggle view buttons */}
              {!showAll && !isTablet() && (
                <ToggleButtonGroup
                  value={activateView}
                  aria-label="Toggle View"
                  size="small"
                >
                  <ToggleButton
                    value="grid"
                    onClick={() => handleToggleView('grid')}
                  >
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="list"
                    onClick={() => handleToggleView('list')}
                  >
                    <ViewListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Stack>

            {/* Error State */}
            {/* TODO: Visit your hosting plan to purchase a site <Link href=".."> */}
            {/* {isError && <Typography></Typography>} */}

            <ResponsiveTable
              type="sites"
              showCardsUI={showAll || activateView === 'grid'}
              headCells={headCells}
              list={filteredSites}
              isLoading={isLoadingAll}
              // @ts-expect-error
              emptyListCopy={emptyListCopy}
              mobile={{ ui: <SitesCard /> }}
              pageSize={12}
              paginationProps={{ page, onChange: (e, value) => setPage(value) }}
              {...(noFilteredSites && {
                empty: (
                  <SitesEmpty
                    showAll={showAll}
                    noSites={noSites}
                    emptyListCopy={emptyListCopy}
                    handleAddSite={handleMultiHostingAddSite}
                  />
                ),
              })}
              {...(!showAll && {
                showAllCTA: (
                  <Button
                    disabled={isDisabled || isLoadingAll}
                    disableRipple
                    href="/sites"
                  >
                    {t('viewAll')}
                  </Button>
                ),
              })}
            >
              <SitesTableBody />
            </ResponsiveTable>
          </Stack>
        </CardContent>
      </Card>
      <WarningModal
        openSiteLimit={openSiteLimit}
        setOpenSiteLimit={setOpenSiteLimit}
      />
      <DBWarningModal
        openSiteLimit={openDBLimit}
        setOpenSiteLimit={setOpenDBLimit}
      />
    </>
  );
};

export default Sites;
