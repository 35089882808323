import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';
import useResponsive from '~/hooks/useResponsive';

import HostingInfo from '../../../../components/HostingInfo';
import RenewalSettings from '../../../../components/RenewalSettings';

const OverviewCard = () => {
  const { t } = useTranslation(['site', 'hosting']);
  const { BrowserView } = useResponsive();

  const { id: accountId } = useAccount();
  const {
    // @ts-expect-error
    data: { hostingData },
  } = useMFEContext();
  const hasAutoRenew = hostingData?.hasAutoRenew;

  return (
    <Card>
      <Grid container>
        <Grid item sm>
          <CardContent>
            <Typography variant="h3">{t('hosting:planTitle')}</Typography>
            <HostingInfo accountId={accountId} />
          </CardContent>
        </Grid>

        <BrowserView>
          <Divider variant="fullWidth" orientation="vertical" flexItem />
        </BrowserView>

        {/* only show the renew section when the user has it disabled */}
        {hasAutoRenew === false && (
          <Grid item sm md={4}>
            <CardContent>
              <Typography variant="h3" sx={{ pb: 0.5 }}>
                {t('hosting:renewal.title')}
              </Typography>
              <RenewalSettings />
            </CardContent>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default OverviewCard;
