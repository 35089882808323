import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

import { SitesInfo200 } from '@newfold/huapi-js/src/index.schemas';

import SiteInformation from './SiteInformation';
import SiteThumbnail from './SiteThumbnail';
import UpdateStatus from './UpdateStatus';

type OverviewCardPropOptions = {
  siteInfo?: SitesInfo200;
  loading?: boolean;
};

const OverviewCard = ({
  siteInfo = {
    url: '',
    version: '',
    type: '',
  },
  loading = false,
}: OverviewCardPropOptions) => {
  const { t } = useTranslation('site');

  return (
    <Card>
      <CardHeader title={t('cardTitle')} />
      <CardContent>
        <Grid
          container
          spacing={4}
          columns={{ xs: 4, sm: 4, md: 12 }}
          alignItems="flex-start"
        >
          <SiteThumbnail isLoadingSite={loading} siteInfo={siteInfo} />
          <SiteInformation loading={loading} />
          <UpdateStatus />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OverviewCard;
