import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSiteContext } from '~/scenes/Site';

const UserEmpty = () => {
  const { t } = useTranslation('sshUserManager');

  // @ts-expect-error
  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  return (
    <Paper
      variant="outlined"
      sx={{ borderStyle: 'dashed', p: 4, textAlign: 'center' }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <Stack spacing={1}>
          <Typography variant="h3">{t('sshUser.nodataTitle')}</Typography>
          <Typography variant="body2">{t('sshUser.nodataSubText')}</Typography>
        </Stack>

        <Button variant="outlined" href="users/add" disabled={isDisabled}>
          {t('sshUser.noDataBtn')}
        </Button>
      </Stack>
    </Paper>
  );
};

export default UserEmpty;
