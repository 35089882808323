const styles = (color) => {
  return {
    palette: {
      text: {
        light: color.semantic.neutral[500],
        dark: color.semantic.secondary.dark,
      },
      button: {
        primary: {
          main: color.semantic.primary[300],
          shadow: '#cc9e00',
          hover: color.semantic.primary[400],
        },
        secondary: {
          main: color.semantic.secondary[200],
          shadow: color.semantic.secondary[500],
          hover: color.semantic.secondary[300],
        },
        disabled: {
          main: color.semantic.secondary.light,
          text: color.semantic.secondary[400],
          outlined: color.semantic.neutral.main,
        },
      },
      loader: {
        main: color.spectrum.rose[600],
      },
    },
    typography: {
      fontFamily: ['"Roboto"', 'Helvetica', 'sans-serif'].join(','),
      h2: {
        fontSize: 18,
      },
      h3: {
        lineHeight: '24px',
      },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h2' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
            }),
          },
          {
            props: { variant: 'h3' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
            }),
          },
          {
            props: { variant: 'h6' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
            }),
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'primary' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
              fontWeight: 700,
              background: theme.palette.button.primary.main,
              letterSpacing: 0,
              borderRadius: '6px',
              height: '40px',
              boxShadow: `0 1px ${theme.palette.button.primary.shadow}, inset 0 -1px ${theme.palette.button.primary.shadow}`,
              '&:hover': {
                background: theme.palette.primary.main,
                boxShadow: `0 1px ${theme.palette.button.primary.shadow}, inset 0 -1px ${theme.palette.button.primary.shadow}`,
              },
              '&.Mui-disabled': {
                background: theme.palette.button.disabled.main,
                color: theme.palette.button.disabled.text,
              },
            }),
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
              fontWeight: 700,
              background: theme.palette.button.secondary.main,
              letterSpacing: 0,
              borderRadius: '6px',
              height: '40px',
              boxShadow: `0 1px ${theme.palette.button.secondary.shadow}, inset 0 -1px ${theme.palette.button.secondary.shadow}`,
              '&:hover': {
                background: theme.palette.button.secondary.hover,
                boxShadow: `0 1px ${theme.palette.button.secondary.shadow}, inset 0 -1px ${theme.palette.button.secondary.shadow}`,
              },
              '&.Mui-disabled': {
                background: theme.palette.button.disabled.main,
                color: theme.palette.button.disabled.text,
              },
            }),
          },
          {
            props: { variant: 'text', color: 'primary' },
            style: ({ theme }) => ({
              color: theme.palette.secondary.dark,
              fontWeight: 700,
              letterSpacing: 0,
            }),
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: ({ theme }) => ({
              '&.MuiButtonBase-root': {
                borderRadius: '6px',
                borderColor: theme.palette.button.secondary.main,
                color: theme.palette.secondary.dark,

                '&.Mui-disabled': {
                  color: theme.palette.button.disabled.outlined,
                },

                '&:hover': {
                  borderColor: theme.palette.secondary.dark,
                },

                '.MuiSvgIcon-root': {
                  color: 'currentColor',
                },
              },
            }),
          },
        ],
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            display: 'none',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: '50px',
            background: theme.palette.button.secondary.main,
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.info.main,
            textDecoration: 'none',
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.Mui-focused': {
              color: theme.palette.secondary.dark,
            },
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiOutlinedInput-root': {
              borderRadius: '6px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.dark,
                borderWidth: '1px',
              },
            },
            '.MuiInputBase-input': {
              border: 'none',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.button.disabled.text,
            },
          }),
        },
      },
      MuiList: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.light,
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation: {
            boxShadow: 'none',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (min-width: 300px)': {
              padding: 0,
            },

            '> .MuiStack-root > .MuiStack-root': {
              marginTop: 0,

              '> .MuiPaper-root > .MuiCardContent-root': {
                padding: 0,
              },
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          subheader: ({ theme }) => ({
            color: theme.palette.text.light,
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: ({ theme }) => ({
            '.MuiBackdrop-root.MuiModal-backdrop': {
              background: 'rgba(255, 255, 255, 0.90)',
            },
            '.MuiBox-root': {
              border: 'none',
            },
            '.MuiDialog-container': {
              '.MuiPaper-root.MuiDialog-paper': {
                borderRadius: '6px',
                boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.18)',

                '@media (max-width: 767px)': {
                  margin: '0',
                  width: '100%',
                  maxWidth: 'calc(100% - 16px)',
                },

                '.MuiDialogContent-root': {
                  '@media (max-width: 767px)': {
                    flex: 'none',
                  },

                  '.MuiTypography-body1': {
                    color: theme.palette.text.dark,
                  },
                },
              },
            },
          }),
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: ({ theme }) => ({
            color: theme.palette.loader.main,
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            '.MuiPaper-root .MuiGrid-root .MuiGrid-item[class*="MuiGrid-grid-"]':
              {
                '> .MuiStack-root': {
                  '&:first-child .MuiTypography-body2:first-child': {
                    color: theme.palette.text.light,

                    '+ .MuiStack-root .MuiTypography-body2:first-child': {
                      color: theme.palette.text.dark,
                    },
                  },
                },
              },
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ theme }) => ({
            backgroundColor: theme.palette.text.light,
            color: theme.palette.common.white,
            padding: theme.spacing(1, 1.2),
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 400,
          }),
          arrow: ({ theme }) => ({
            color: theme.palette.text.light,
          }),
        },
      },
    },
  };
};

export default styles;
