import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BackupIcon from '@mui/icons-material/Backup';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';

import StatusListItem from '~/components/StatusListItem';
import useSiteBackups from '~/hooks/useSiteBackups';

type BackupStatusPropOptions = {
  siteId?: number | string;
  siteType?: string;
};

const BackupStatus = ({
  siteId = undefined,
  siteType = undefined,
}: BackupStatusPropOptions) => {
  const { t } = useTranslation(['site', 'backups']);

  const {
    data: codeguardDetails,
    isLoading: codeguardIsLoading,
    isError: codeguardError,
  } = useSiteBackups(Number(siteId), 'codeguard');

  const {
    data: jetpackDetails,
    isLoading: jetpackIsLoading,
    isError: jetpackError,
  } = useSiteBackups(Number(siteId), 'jetpack');

  const isLoading = codeguardIsLoading || jetpackIsLoading;
  const isError = codeguardError || jetpackError;

  const genericBackupStatusOn =
    siteType === 'generic' && !!codeguardDetails?.website_id;

  const wordpressBackupStatusOn =
    siteType === 'wordpress' &&
    (codeguardDetails?.activated ||
      (jetpackDetails?.addon_id && jetpackDetails?.has_active_plugin));

  const isProvisioning =
    codeguardDetails?.url &&
    codeguardDetails?.website_id !== null &&
    codeguardDetails?.database_id !== null &&
    !codeguardDetails.activated;

  const backupStatus = isProvisioning
    ? 'provisioning'
    : genericBackupStatusOn || wordpressBackupStatusOn
    ? 'on'
    : 'off';

  const statusConfig = {
    provisioning: {
      dataTestId: 'backupStatusProvisioning',
      avatarIcon: CloudQueueIcon,
      avatarIconColor: 'warning',
      tooltipText: t(`backups:status.${backupStatus}.tooltipDesc`),
      backupMessage: t(`backups:status.${backupStatus}.titleShort`),
    },
    on: {
      dataTestId: 'backupStatusON',
      avatarIcon: BackupIcon,
      avatarIconColor: 'success',
      tooltipText: '',
      backupMessage: t('backupMsg', {
        siteBackupStatus: `${backupStatus}`,
      }),
    },
    off: {
      dataTestId: 'backupStatusOFF',
      avatarIcon: CloudOffIcon,
      avatarIconColor: 'error',
      tooltipText: t(`backups:status.${backupStatus}.tooltipDesc`),
      backupMessage: t('backupMsg', {
        siteBackupStatus: `${backupStatus}`,
      }),
    },
  };

  const {
    dataTestId,
    avatarIcon,
    avatarIconColor,
    tooltipText,
    backupMessage,
  } = statusConfig[backupStatus];

  const navigate = useNavigate();

  const handleLink = (url: string) => {
    navigate(url);
  };

  // Status will be displayed regardless the site type
  // if codeguard addon is not associated, manage link will provide an option navigate to backup page and purchase codeguard
  return (
    // @ts-expect-error
    <StatusListItem
      isLoading={isLoading}
      isError={isError}
      dataTestId={dataTestId}
      // @ts-expect-error
      avatarIcon={avatarIcon}
      // @ts-expect-error
      avatarIconColor={avatarIconColor}
      // @ts-expect-error
      tooltipText={tooltipText}
      ctaText={t('backups:manageBtnLabel')}
      // @ts-expect-error
      handleCTA={() => handleLink('backups')}
    >
      {backupMessage}
    </StatusListItem>
  );
};

export default BackupStatus;
