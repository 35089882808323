import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useHostingInfo from '~/hooks/useHostingInfo';

type ProServicesCardPropOptions = {
  proServicesData?: {
    link: string;
  };
};

const ProServicesCard = ({
  proServicesData = undefined,
}: ProServicesCardPropOptions) => {
  const { t } = useTranslation('hosting');
  const { isDisabled } = useHostingInfo();

  return (
    <Grid item xs={12} className="xyz-nested">
      <Card>
        <CardHeader title={t('proServices.title')} />
        <Stack justifyContent="space-between" direction="row">
          <CardContent>
            <Typography variant="body2">
              {t('proServices.description')}
            </Typography>
          </CardContent>
          <CardContent sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              data-testid="btn-manage"
              href={proServicesData?.link || ''}
              disabled={isDisabled}
              target="_blank"
            >
              {t('proServices.action')}
            </Button>
          </CardContent>
        </Stack>
      </Card>
    </Grid>
  );
};

export default ProServicesCard;
