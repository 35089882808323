import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesMetricsDimensionAveraged } from '@newfold/huapi-js';
import { SitesMetricsDimensionAveragedParams } from '@newfold/huapi-js/src/index.schemas';

import useAlertStore from '~/components/Alerts/alertsStore';
import {
  formatToUnixRounded,
  getDateRangeToToday,
} from '~/utils/dateRangeHelpers';

import SummaryCharts from './Charts';

const MetricsSummary = () => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'performance.metrics',
  });

  const [, { generateAlert }] = useAlertStore();

  const { siteId } = useParams();

  const { today, startDateTime } = getDateRangeToToday(14);

  const params: SitesMetricsDimensionAveragedParams = {
    start: String(formatToUnixRounded(startDateTime)),
    end: String(formatToUnixRounded(today)),
  };

  const DEFAULT_DIMENSION = 'http_host';

  const {
    data: sitesMetricsSummaryData,
    isLoading: siteMetricsSummaryLoading,
    isError: siteMetricsSummaryError,
  } = useSitesMetricsDimensionAveraged(
    Number(siteId),
    params,
    DEFAULT_DIMENSION,
    {
      query: {
        enabled: !!siteId,
        select: (data) => data?.data,
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('metricsSummaryServiceFailure'),
            showCloseBtn: true,
          });
        },
      },
    },
  );

  const xLabels = Array.from(Array(14).keys());

  if (!siteMetricsSummaryError && siteMetricsSummaryLoading) {
    return (
      <>
        <Stack spacing={2}>
          <Skeleton width={150} />
          <Grid container gap={2}>
            {[1, 2, 3, 4].map((key) => (
              <Grid item xs key={key.toString()}>
                <Skeleton key={key} width={'100%'} height={120} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </>
    );
  }

  return (
    <>
      {sitesMetricsSummaryData && (
        <Stack spacing={2}>
          <Typography variant="h4">
            {t('previewMetrics', { days: '14' })}
          </Typography>
          <Grid container gap={2}>
            {Object.keys(sitesMetricsSummaryData?.metrics as any).map(
              (key, index) => {
                return (
                  <SummaryCharts
                    dimensions={
                      (sitesMetricsSummaryData?.metrics as any)[key] as number[]
                    }
                    metric={t(camelCase(key))}
                    xLabels={xLabels as number[]}
                    isLoading={siteMetricsSummaryLoading}
                    isError={siteMetricsSummaryError}
                    key={index}
                  />
                );
              },
            )}
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default MetricsSummary;
