import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import {
  getSitesInfoQueryKey,
  useHostingAddons,
  useSitesPerformanceCachepurge,
  useSitesPerformanceEdgecache,
} from '@newfold/huapi-js';
import { SitesInfo200 } from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import { useSiteContext } from '~/scenes/Site';

import translateCloudflareStatus from '../../../Speed/utils/getCfEpcValues';
import MetricsCard from './components/MetricsCard';
import PHPWorkers from './components/PHPWorkers';

export default function Performance() {
  const { t } = useTranslation('cloud', { keyPrefix: 'performance' });
  const generateAlert = useAlert();
  const { siteId } = useParams<{ siteId: string }>();
  const queryClient = useQueryClient();

  const sitesInfoQueryKey = getSitesInfoQueryKey(Number(siteId));

  const { id: accountId } = useAccount();

  const {
    data: site,
    isLoading: siteInfoLoading,
    isError: siteInfoError,
    isHostingDisabled,
    isSiteOrHostingDisabled: isDisabled,
  } = useSiteContext();
  const domain = site?.domain || '';
  const cacheStatus = site?.edge_cache;

  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: descKey,
      showCloseBtn: true,
    });
  };

  // clear cache
  const { mutate: handleClearCache, isLoading: clearAllCacheLoading } =
    useSitesPerformanceCachepurge({
      mutation: {
        onSuccess: ({ data }) => {
          if (data?.success) {
            setAlertMessage('success', t('clearCache.sucessClearCacheToast'));
          }
        },
        onError: () => {
          setAlertMessage('error', t('clearCache.clearCacheErrorToast'));
        },
      },
    });

  // Get cloudflare addon info
  const { data: addonInfo } = useHostingAddons(
    accountId,
    { type: 'cloudflare' },
    { query: { enabled: !!accountId } },
  );

  const cloudflareData = addonInfo?.data?.rows?.find((row) => {
    const rowDomain = row?.meta?.domain;
    return !!rowDomain && !!domain && rowDomain?.domain === domain;
  });

  const handleCache = () => {
    const { cf, epc } = translateCloudflareStatus(
      cloudflareData?.status_user!,
      cloudflareData?.subtype!,
    );

    handleClearCache({
      siteId: Number(siteId),
      data: {
        hosts: [domain],
        assets: [],
        cf: cf,
        epc: !!epc ? 1 : 0,
      },
    });
  };

  // POST toggle cache
  const { mutate: toggleCache } = useSitesPerformanceEdgecache({
    mutation: {
      onMutate: async () => {
        const previousSitesInfoData: { data: SitesInfo200 } | undefined =
          queryClient.getQueryData(sitesInfoQueryKey);

        if (!previousSitesInfoData) return previousSitesInfoData;

        queryClient.setQueryData(sitesInfoQueryKey, () => {
          return {
            ...previousSitesInfoData,
            data: {
              ...previousSitesInfoData.data,
              edge_cache: !cacheStatus,
            },
          };
        });

        return { previousSitesInfoData };
      },
      onSettled: (data, error, variables, context) => {
        if (error) {
          generateAlert({
            severity: 'error',
            description: t(`edgeCache.toggleError`),
            showCloseBtn: true,
          });

          if (context?.previousSitesInfoData) {
            return queryClient.setQueryData(
              sitesInfoQueryKey,
              context?.previousSitesInfoData,
            );
          }
        }

        return queryClient.invalidateQueries(sitesInfoQueryKey);
      },
    },
  });

  return (
    <Stack spacing={2} data-testid="performance-card">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={t('edgeCache.title')}
              action={
                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={
                    <Switch
                      checked={cacheStatus}
                      disabled={isDisabled || siteInfoError}
                      onChange={() =>
                        toggleCache({
                          siteId: Number(siteId),
                          data: {
                            edge_cache: cacheStatus ? 0 : 1,
                          },
                        })
                      }
                    />
                  }
                  label={
                    !siteInfoError
                      ? cacheStatus
                        ? t('edgeCache.on')
                        : t('edgeCache.off')
                      : null
                  }
                  labelPlacement="start"
                />
              }
            />
            <CardContent>
              <Stack spacing={3} alignItems={'flex-start'}>
                <Typography variant="body1">{t('edgeCache.desc')}</Typography>
                <LoadingButton
                  variant="outlined"
                  disabled={!cacheStatus || isDisabled}
                  onClick={handleCache}
                  loading={clearAllCacheLoading}
                >
                  {t('edgeCache.clearCacheBtn')}
                </LoadingButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <PHPWorkers
            isDisabled={isHostingDisabled}
            allocatedWorkers={Number(site?.resources?.workers)}
            isLoading={siteInfoLoading}
          />
        </Grid>
        <Grid item xs>
          <MetricsCard />
        </Grid>
      </Grid>
    </Stack>
  );
}
