import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { getSitesInfoQueryKey, useSiteUpdate } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import { useSiteContext } from '~/scenes/Site';

export default function Static404() {
  const { t } = useTranslation('cloud', { keyPrefix: 'settings.static404' });

  const generateAlert = useAlert();
  const queryClient = useQueryClient();

  const { siteId } = useParams<{ siteId: string }>();
  const sitesInfoQueryKey = getSitesInfoQueryKey(Number(siteId));

  const {
    data: siteData,
    isLoading: siteInfoLoading,
    isError: siteInfoError,
    isSiteOrHostingDisabled: isDisabled,
  } = useSiteContext();

  const isStatic404PageEnabled =
    siteData?.handler_404 === 'wordpress'
      ? true
      : siteData?.handler_404 === 'lightweight'
      ? false
      : undefined;

  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: descKey,
      showCloseBtn: true,
    });
  };

  const { mutate: toggleCache, isLoading: toggleLoading } = useSiteUpdate({
    mutation: {
      onSuccess: async ({ data }) => {
        if (data?.success) {
          setAlertMessage(
            'success',
            isStatic404PageEnabled ? t('toggleOff') : t('toggleOn'),
          );
        }
      },
      onError: () => {
        setAlertMessage('error', t('toggleError'));
      },
      onSettled: async () => {
        queryClient.invalidateQueries(sitesInfoQueryKey);
      },
    },
  });

  const isToggleLoading = siteInfoLoading || toggleLoading;

  return (
    <Card>
      <CardHeader
        title={t('title')}
        action={
          <FormControlLabel
            sx={{ mr: 0 }}
            control={
              isToggleLoading ? (
                <CircularProgress size={30} />
              ) : (
                <Switch
                  checked={isStatic404PageEnabled}
                  disabled={siteInfoError || isDisabled}
                  onChange={() => {
                    toggleCache({
                      siteId: Number(siteId),
                      data: {
                        handler_404: !isStatic404PageEnabled,
                      },
                    });
                  }}
                />
              )
            }
            label={
              !isToggleLoading && !siteInfoError
                ? isStatic404PageEnabled
                  ? t('on')
                  : t('off')
                : null
            }
            labelPlacement="start"
          />
        }
      />
      <CardContent>
        <Typography variant="body1">{t('desc')}</Typography>
      </CardContent>
    </Card>
  );
}
