import { HostingInfoDiskUsage200 } from '@newfold/huapi-js/src/index.schemas';

import { UsageTypes } from '~/scenes/Hosting/scenes/HostingDetail/components/UsageStats';

const USAGE_ERR_PERCENTAGE = 90;
const USAGE_WARN_PERCENTAGE = 70;
const MAX_FILE_LIMIT = 1000000;

const getUsageStats = (data: HostingInfoDiskUsage200) => {
  let percentUsed = 0,
    filePercentUsed = 0;
  let variant = 'primary',
    fileVariant = 'primary';
  let hasIcon = false,
    hasFileIcon = false;

  if (data?.disklimit !== 'Unlimited') {
    const used = data?.diskused!;
    const limit = data?.disklimit!;
    percentUsed = Number(+used.slice(0, -2) / +limit.slice(0, -2)) * 100;
    variant = 'primary';
    switch (true) {
      case percentUsed >= USAGE_ERR_PERCENTAGE:
        variant = 'error';
        hasIcon = true;
        break;
      case percentUsed < USAGE_ERR_PERCENTAGE &&
        percentUsed >= USAGE_WARN_PERCENTAGE:
        variant = 'warning';
        break;
      default:
        variant = 'success';
    }
  }

  if (data?.fileslimit !== MAX_FILE_LIMIT) {
    filePercentUsed = Number(data?.filesused! / data?.fileslimit!) * 100;
    fileVariant = 'primary';
    switch (true) {
      case filePercentUsed >= USAGE_ERR_PERCENTAGE:
        fileVariant = 'error';
        hasFileIcon = true;
        break;
      case filePercentUsed < 90 && filePercentUsed >= USAGE_WARN_PERCENTAGE:
        fileVariant = 'warning';
        break;
      default:
        fileVariant = 'success';
    }
  }

  const alertState = getAlertState(percentUsed, filePercentUsed);

  const stats = {
    percentUsed,
    variant,
    filePercentUsed,
    fileVariant,
    hasIcon,
    hasFileIcon,
    alertState,
  } as UsageTypes;

  return stats;
};

const getAlertState = (diskPercentUsed: number, filePercentUsed: number) => {
  const diskUsed = diskPercentUsed ?? 0;
  const fileUsed = filePercentUsed ?? 0;

  if (diskUsed >= 100 || fileUsed >= 100) return 'critical';
  if (diskUsed > 98 || fileUsed > 98) return 'warning';
  if (diskUsed > 90 || fileUsed > 90) return 'alert';

  return null;
};

export { getAlertState, getUsageStats };
