import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import { useSitesAccessLogs, useSitesErrorLogs } from '@newfold/huapi-js';

import {
  formatToUnixRounded,
  getDateRangeToToday,
} from '~/utils/dateRangeHelpers';

import LogsCard from './components/LogsCard';

export default function Logs() {
  const { siteId } = useParams();

  const { today, startDateTime } = getDateRangeToToday();
  const params = {
    start: String(formatToUnixRounded(startDateTime)),
    end: String(formatToUnixRounded(today)),
  };
  const {
    data: errorLogsInfo,
    isLoading: isLoadingErrorLogs,
    isError: isErrorErrorLogs,
  } = useSitesErrorLogs(Number(siteId), params, {
    query: {
      select: (data) => data?.data,
    },
  });

  const {
    data: accessLogsInfo,
    isLoading: isLoadingAccessLogs,
    isError: isErrorAccessLogs,
  } = useSitesAccessLogs(Number(siteId), params, {
    query: {
      select: (data) => data?.data,
    },
  });

  return (
    <Stack spacing={2}>
      <LogsCard
        type="error"
        total={errorLogsInfo?.total!}
        isLoading={isLoadingErrorLogs}
        isError={isErrorErrorLogs}
      />
      <LogsCard
        type="access"
        total={accessLogsInfo?.total!}
        isLoading={isLoadingAccessLogs}
        isError={isErrorAccessLogs}
      />
    </Stack>
  );
}
