import { ReactNode } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

type ResponsiveFuncTypes = {
  children: ReactNode;
};

const useResponsive = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const isTablet = !useMediaQuery(theme.breakpoints.up('md'));

  return {
    isMobile: () => isMobile,
    isTablet: () => isTablet,
    isDesktop: () => !isMobile, // Can we changes this?
    // These functions appear to break some transition/transform attrs for the swipable drawer
    MobileView: ({ children }: ResponsiveFuncTypes) =>
      !isMobile ? null : <>{children}</>,
    TabletView: ({ children }: ResponsiveFuncTypes) =>
      isTablet ? <>{children}</> : null,
    BrowserView: ({ children }: ResponsiveFuncTypes) =>
      isMobile ? null : <>{children}</>,
  };
};

export default useResponsive;
