import { Trans, useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { useBrandInfo } from '~/hooks/useBrandInfo';

const SuspendedSiteBanner = () => {
  const { t } = useTranslation('site');
  const { phoneNumberFormatted, phoneNumberHref } = useBrandInfo();

  return (
    <Box>
      <Alert data-testid="site-suspended-banner" severity="warning">
        <AlertTitle>{t('suspendedAlert.title')}</AlertTitle>
        <Trans
          i18nKey="site:suspendedAlert.description"
          values={{ phoneNumber: phoneNumberFormatted }}
          components={{ Link: <Link href={phoneNumberHref} /> }}
        />
      </Alert>
    </Box>
  );
};

export default SuspendedSiteBanner;
