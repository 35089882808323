// @ts-ignore
import { PropsWithChildren, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  SitesDomainsV2200DomainsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useFeature from '~/hooks/useFeature';

import AdvancedDNSEditor from '../../components/AdvancedDNSEditor';
import ChangeSiteURLModal from './components/ChangeSiteURLModal';
import DnsStatus from './components/DnsStatus';
import DomainName from './components/DomainName';
import SslStatus from './components/SslStatus';
import DomainRowActions from './DomainRowActions';
import RemoveDomain from './Remove';
import { validateSiteUrlDomain } from './utils/validateSiteUrlDomain';

const DomainRow = ({
  siteInfo = undefined,
  domain = undefined,
  numberOfDomains = 0,
  isDisabled = false,
}: PropsWithChildren<{
  siteInfo?: SitesInfo200;
  domain?: SitesDomainsV2200DomainsItem;
  numberOfDomains?: number;
  isDisabled?: boolean;
}>) => {
  const { t } = useTranslation('domains');
  const [isAtomic] = useFeature('atomic');
  const [isDestiny] = useFeature('destiny');
  const [openChangeURLModal, setOpenChangeURLModal] = useState(false);
  const [openRemoveDomainModal, setOpenRemoveDomainModal] = useState(false);
  const showRemoveDomainModal = () => setOpenRemoveDomainModal(true);

  const domainId = domain?.id;
  const domainName = domain?.domain;
  const isAuthoritative =
    !!domain?.authoritative && domain?.authoritative !== null;
  const isAutoGenerated = !!domain?.autogen_id && domain?.autogen_id !== null;
  const isMainDomain = !!domain?.type && domain?.type === 'main';
  const isPendingDomain = validateSiteUrlDomain(domain, siteInfo?.pending_url);
  const isSiteUrlDomain = validateSiteUrlDomain(domain, siteInfo?.url);

  const actions: {
    canShow: boolean;
    disabled?: boolean;
    label?: string;
    tooltipTxt?: string | null | undefined;
    color?: string;
    onClick?: () => void;
    Component?: <T>(props: T) => ReactElement<T>;
    dataTestId?: string;
  }[] = [
    {
      label: t('actions.advancedDNSEditor'),
      // Currently unregistered domains will result in a 404 from AMM
      // Is there another check we can make before allowing this to fail?
      canShow: !isAutoGenerated && isAuthoritative,
      Component: () => {
        return (
          <AdvancedDNSEditor
            variant={'menuItem'}
            isAutoGenerated={isAutoGenerated}
            baseDomain={domain?.base_domain}
            isDisabled={isDisabled}
            isAtomic={isAtomic}
            isDestiny={isDestiny}
          ></AdvancedDNSEditor>
        );
      },
    },
    {
      label: isPendingDomain
        ? t('actions.retryAsSiteURL')
        : t('actions.setAsSiteURL'),
      canShow: !isSiteUrlDomain,
      disabled: isDisabled,
      onClick: () => setOpenChangeURLModal(true),
      dataTestId: 'change-site-url-menuitem',
    },
    {
      label: t('actions.removeDomain'),
      color: 'error',
      canShow: !isAutoGenerated,
      disabled: isDisabled,
      onClick: showRemoveDomainModal,
      dataTestId: 'remove-domain-menuitem',
    },
  ];

  const showableActions = actions.filter(({ canShow }) => canShow);

  return (
    <TableRow data-testid="domain-row">
      <TableCell component="th" scope="row">
        <DomainName domain={domain} siteInfo={siteInfo} />
      </TableCell>

      <TableCell>
        <DnsStatus domain={domain} isDisabled={isDisabled} />
      </TableCell>

      <TableCell>
        <SslStatus domain={domain} />
      </TableCell>

      <TableCell align="right">
        {showableActions.length > 0 && (
          <DomainRowActions>
            {showableActions.map(
              ({
                disabled = false,
                label,
                tooltipTxt,
                color = undefined,
                onClick,
                Component,
                dataTestId = 'domain-row-menuitem',
              }) =>
                Component ? (
                  <Component key={`${label}-${domainName}`} />
                ) : (
                  <Tooltip
                    key={`${label}-${domainName}`}
                    title={tooltipTxt}
                    arrow
                    placement="left"
                  >
                    {/* This div allows the Tooltip to trigger while allowing the MenuItem to be disabled */}
                    <div>
                      <MenuItem
                        disabled={disabled}
                        onClick={onClick}
                        data-testid={dataTestId}
                      >
                        <Typography color={color}>{label}</Typography>
                      </MenuItem>
                    </div>
                  </Tooltip>
                ),
            )}
          </DomainRowActions>
        )}
        {openChangeURLModal && (
          <ChangeSiteURLModal
            open={openChangeURLModal}
            setOpen={setOpenChangeURLModal}
            domain={domainName}
            isRetry={isPendingDomain ?? false}
          />
        )}
        {openRemoveDomainModal && (
          <RemoveDomain
            domain={domainName}
            domainId={domainId}
            isLastDomain={numberOfDomains <= 1}
            isMainDomain={isMainDomain}
            isSiteUrlDomain={isSiteUrlDomain}
            isOpen={openRemoveDomainModal}
            setOpen={setOpenRemoveDomainModal}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default DomainRow;
