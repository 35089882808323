// Remove SX props
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

const headCells = [
  {
    id: 'display_name',
    label: 'Name',
  },
  {
    id: 'user_email',
    label: 'Email',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'login',
    label: '',
  },
  {
    id: 'more',
    label: '',
  },
];

type UserTableHeadPropOptions = {
  order: 'asc' | 'desc';
  orderBy: string;
  onRequestSort: (event: any, property: string) => void;
};

const UserTableHead = (props: UserTableHeadPropOptions) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.id === orderBy}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              data-testid={`${headCell.id}-sort-btn`}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default UserTableHead;
