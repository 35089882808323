import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PublicIcon from '@mui/icons-material/Public';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { useSitesDomain } from '@newfold/huapi-js';

import StatusListItem from '~/components/StatusListItem';
import { useSiteContext } from '~/scenes/Site';
import getSiteUrlWithoutProtocol from '~/utils/getSiteUrlWithoutProtocol';

import BackupStatus from './BackupStatus';
import SslStatus from './SslStatus';

type SiteInformationPropOptions = {
  loading?: boolean;
};

const SiteInformation = ({ loading = false }: SiteInformationPropOptions) => {
  const { t } = useTranslation('site');
  const navigate = useNavigate();

  // @ts-ignore
  const { data: siteInfo, isSiteOrHostingDisabled: isDisabled } =
    useSiteContext();
  const hasPendingUrl = !!siteInfo?.pending_url;
  const focusedUrl = hasPendingUrl ? siteInfo.pending_url : siteInfo?.url;
  const ctaLinks = {
    empty: 'domains/add',
    pendingUrl: `domains/${getSiteUrlWithoutProtocol(focusedUrl)}/dns`,
    url: 'domains',
  };

  const handleLink = (url: string) => {
    if (hasPendingUrl) return navigate(url, { state: { visited: true } });
    navigate(url);
  };

  const { data: dnsStatus } = useSitesDomain(
    siteInfo?.id,
    {},
    {
      query: {
        staleTime: 0,
        retry: 5,
        retryDelay: (attempt) => attempt * 15000, // add 15 seconds per retry
        enabled: !!siteInfo?.id && hasPendingUrl,
        select: ({ data }) => ({
          aMatch: data?.a_match,
        }),
      },
    },
  );

  const domainUpdateInProgress = hasPendingUrl && dnsStatus?.aMatch;

  type urlStatusTypes = 'empty' | 'pendingUrl' | 'url';
  const urlStatus = ((!hasPendingUrl &&
    (!siteInfo?.url || siteInfo?.url_is_provisional) &&
    'empty') ||
    (domainUpdateInProgress && 'updateInProgress') ||
    (hasPendingUrl && 'pendingUrl') ||
    'url') as urlStatusTypes;

  const siteUrlCta = {
    text: t(`urlCta.${urlStatus}Text`),
    link: domainUpdateInProgress ? null : () => handleLink(ctaLinks[urlStatus]),
  };

  const hideCTA =
    t(`urlCta.${urlStatus}.text`) === 'Finish Setup' &&
    (siteInfo?.pending_url_data === null ||
      siteInfo?.pending_url_data?.is_temp === true)
      ? true
      : false;

  return (
    <Grid item xs={4} sm={4} md={4} data-testid="site-information">
      <Typography variant="h3">{t('webInfoTitle')}</Typography>
      <List dense>
        {/* @ts-expect-error */}
        <StatusListItem
          disableCTA={isDisabled}
          isLoading={loading}
          // @ts-expect-error
          avatarIcon={PublicIcon}
          // @ts-expect-error
          avatarIconColor={urlStatus === 'empty' ? 'error' : 'success'}
          // @ts-expect-error
          tooltipText={
            hasPendingUrl && (
              <Trans
                i18nKey="site:pendingUrlTooltip"
                values={{
                  pendingUrl: siteInfo?.pending_url,
                }}
              />
            )
          }
          ctaText={siteUrlCta.text}
          hideCTA={hideCTA}
          // @ts-expect-error
          handleCTA={siteUrlCta.link}
          // @ts-expect-error
          tooltipTitle={`${t('urlLabel')} ${focusedUrl}`}
          // @ts-expect-error
          updateInProgress={domainUpdateInProgress}
        >
          {t('urlLabel')}{' '}
          {focusedUrl ? (
            <Link href={focusedUrl} target="_blank">
              {focusedUrl}
            </Link>
          ) : (
            <>{t('urlNotAvailable')}</>
          )}
        </StatusListItem>
        <SslStatus siteId={siteInfo?.id} />
        <BackupStatus siteId={siteInfo?.id} siteType={siteInfo?.type} />
      </List>
    </Grid>
  );
};

export default SiteInformation;
