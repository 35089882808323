import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BackupIcon from '@mui/icons-material/Backup';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import StatusListItem from '~/components/StatusListItem';
import useSiteBackups from '~/hooks/useSiteBackups';

type BackupStatusPropOptions = {
  siteId?: number | string;
  siteType?: string;
};

const BackupStatus = ({
  siteId = undefined,
  siteType = undefined,
}: BackupStatusPropOptions) => {
  const { t } = useTranslation(['site', 'backups']);

  const {
    data: codeguardDetails,
    isLoading: codeguardIsLoading,
    isError: codeguardError,
  } = useSiteBackups(siteId, 'codeguard');

  const {
    data: jetpackDetails,
    isLoading: jetpackIsLoading,
    isError: jetpackError,
  } = useSiteBackups(siteId, 'jetpack');
  const isLoading = codeguardIsLoading || jetpackIsLoading;
  const isError = codeguardError || jetpackError;

  const genericBackupStatusOn =
    siteType === 'generic' && !!codeguardDetails?.website_id;

  const wordpressBackupStatusOn =
    siteType === 'wordpress' &&
    (codeguardDetails?.activated ||
      (jetpackDetails?.addon_id && jetpackDetails?.has_active_plugin));

  const backupStatus =
    genericBackupStatusOn || wordpressBackupStatusOn ? 'on' : 'off';

  const navigate = useNavigate();

  const handleLink = (url: string) => {
    navigate(url);
  };

  // Status will be displayed regardless the site type
  // if codeguard addon is not associated, manage link will provide an option navigate to backup page and purchase codeguard
  return (
    // @ts-expect-error
    <StatusListItem
      isLoading={isLoading}
      isError={isError}
      dataTestId={'on' === backupStatus ? 'backupStatusON' : 'backupStatusOFF'}
      // @ts-expect-error
      avatarIcon={'on' === backupStatus ? BackupIcon : CloudOffIcon}
      // @ts-expect-error
      avatarIconColor={'on' === backupStatus ? 'success' : 'error'}
      // @ts-expect-error
      tooltipText={
        'on' !== backupStatus
          ? t(`backups:status.${backupStatus}.tooltipDesc`)
          : ''
      }
      ctaText={t('backups:manageBtnLabel')}
      // @ts-expect-error
      handleCTA={() => handleLink('backups')}
    >
      {t('backupMsg', { siteBackupStatus: backupStatus })}
    </StatusListItem>
  );
};

export default BackupStatus;
