import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

const DiskUsageAlert = ({ alertState = '', handleClick = () => {} }) => {
  const { t } = useTranslation('hosting');

  if (alertState === '') return null;

  return (
    <Alert
      data-testid="alert-banner"
      severity={alertState === 'critical' ? 'error' : 'warning'}
      action={
        <Button onClick={handleClick}>
          {t('hosting:renewal.alerts.diskUsageAlert.ctaText')}
        </Button>
      }
    >
      {(alertState === 'alert' || alertState === 'warning') && (
        <AlertTitle>{t(`renewal.alerts.diskUsageAlert.warning`)}</AlertTitle>
      )}
      {alertState === 'critical' && (
        <AlertTitle>{t(`renewal.alerts.diskUsageAlert.critical`)}</AlertTitle>
      )}
    </Alert>
  );
};

export default DiskUsageAlert;
