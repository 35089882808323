import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

// @ts-ignore
import noSitePictureWide from '../../../../../../Sites/assets/placeholders/site-no-picture-wide.svg';

const thumbnailBgColorOneOff = '#d8e0eb';
const aspectX = 16;
const aspectY = 9;

export const siteThumbnailSkeletonSx = {
  aspectRatio: `${aspectX}/${aspectY}`,
  width: '100%',
};

export const siteThumbnailSx = {
  ...siteThumbnailSkeletonSx,
  backgroundColor: thumbnailBgColorOneOff,
};

const SiteThumbnail = () => {
  const theme = useTheme();
  return (
    <Box
      component="img"
      src={noSitePictureWide}
      sx={{
        ...siteThumbnailSx,
        border: `1px solid ${theme.palette.grey[400]}`,
      }}
    />
  );
};

export default SiteThumbnail;
