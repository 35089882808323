//@ts-nocheck
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import { useSitesBackupsDetails } from '@newfold/huapi-js';

import JetpackBackupInfo from '~/scenes/Site/scenes/Cloud/scenes/Backups/Jetpack/JetpackBackupInfo';
import JetpackBackupSso from '~/scenes/Site/scenes/Cloud/scenes/Backups/Jetpack/JetpackBackupSso';
import PurchaseJetpack from '~/scenes/Site/scenes/Cloud/scenes/Backups/Jetpack/PurchaseJetpack';

export default function Backups() {
  const { t } = useTranslation('site');
  const { siteId } = useParams();
  const {
    isFetching: backupDetailsLoading,
    data: backupDetails,
    refetch: getBackupDetails,
    isError: backupDetailsError,
  } = useSitesBackupsDetails(siteId, {
    query: {
      select: ({ data }) => {
        // Extract data from the `jetpack` object, with default values if not available
        const {
          plugin_exists: hasJetpackPlugin = false,
          addon_exists: hasJetpackAddon = false,
          addon_id: jetpackAddonId,
          status: jetpackAddonStatus,
          plan_name: jetpackPlanName,
          can_upgrade: canUpgradeJetpack,
          addon_back_reference: jetpackAddonBackReference,
          next_url: jetpackBackupNextUrl,
          last_backup_date: jetpackBackupTime,
        } = data?.jetpack || {};

        // Determine if Jetpack are available
        const hasJetpackProduct = !!jetpackAddonId;
        const isJetpackDeleted =
          !!jetpackAddonId && jetpackAddonStatus === 'deleted';

        // Return the formatted data
        return {
          hasJetpackProduct,
          ...(hasJetpackProduct && {
            isJetpackDeleted,
            hasJetpackPlugin,
            hasJetpackAddon,
            jetpackAddonId,
            jetpackAddonStatus,
            jetpackPlanName,
            canUpgradeJetpack,
            jetpackAddonBackReference,
            jetpackBackupNextUrl,
            jetpackBackupTime,
          }),
        };
      },
      onError: () => setAlert('error', t('fetchPlanInfoFailed')),
    },
  });

  //on load.
  if (backupDetailsLoading || backupDetailsError) {
    return (
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Stack direction="row" justifyContent={'space-between'}>
                <Skeleton width={250} />
                <Skeleton variant="rounded" width={235} height={40} />
              </Stack>
            }
          />
          <CardContent>
            <Stack direction="row" spacing={1} mb={2} mt={2}>
              <Skeleton variant="circular" height={24} width={24}>
                <Avatar />
              </Skeleton>
              <Skeleton width="30%" />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  //=================
  //if no addon
  const isPurchaseJetpack =
    !backupDetails?.hasJetpackProduct || backupDetails?.isJetpackDeleted;

  if (isPurchaseJetpack) return <PurchaseJetpack />;

  return (
    <Card>
      <CardHeader
        title={t('cloud.backups.title')}
        action={
          <JetpackBackupSso
            siteId={siteId}
            backupDetails={backupDetails}
            getBackupDetails={getBackupDetails}
          />
        }
        sx={{ mr: 1 }}
      />
      <CardContent>
        <JetpackBackupInfo
          planName={backupDetails?.jetpackPlanName}
          event_time={backupDetails?.jetpackBackupTime}
          status={backupDetails?.jetpackAddonStatus}
        />
      </CardContent>
    </Card>
  );
}
