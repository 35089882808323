import { Trans, useTranslation } from 'react-i18next';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useResponsive from '~/hooks/useResponsive';

import readyToUpdate from '../assets/ready-to-update.svg';

type FinalizeDomainPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  isLoading?: boolean;
};

/*
  A component that takes a Site object as defined by HUAPI and
  displays domain info as well as a button to perform an action
*/
const UpdateURLPrompt = ({
  site,
  isLoading = false,
}: FinalizeDomainPropOptions) => {
  /*
    The domain has passed all validation steps, and the site pending url is
    ready to be promoted to site url. We will show a button that will perform
    this change. (NOTE: Functionality does not yet exist for this manual action
    to occur)

    - This will likely be a useSiteUpdate HUAPI hook call.
    - We want to display the pending_url here, since it has not yet been promoted to site url.

    This component will need to be displayed in two locations:
    1. Within the stepper on the Hosting Overview, replacing the Temp Domain Info box
    2. On Site Overview, between the tab list and tab content
  */
  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();
  const { t } = useTranslation('domains', {
    keyPrefix: 'setupProgress.updateURLPrompt',
  });

  const pendingUrl = site?.pending_url;

  return (
    <Card
      data-testid="update-url-prompt"
      sx={{
        backgroundColor: 'primary.light',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'grey.300',
        boxShadow: 'none',
        overflow: 'visible',
      }}
    >
      {/* Currently this will never display, since the manual url update action isn't available from the HAL site yet */}
      <CardContent>
        <Stack spacing={2}>
          <Stack
            direction={'row'}
            spacing={5}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {!isTablet && (
              <CardMedia
                image={readyToUpdate}
                component="img"
                alt={t('imageAltText')}
                sx={{ width: 225, height: 178 }}
              />
            )}
            <Stack
              spacing={3}
              alignItems={'center'}
              textAlign={'center'}
              maxWidth={600}
            >
              {isLoading ? (
                <Skeleton height={25} width={isTablet ? 200 : 400} />
              ) : (
                <Typography variant={'h2'} sx={{ wordBreak: 'break-word' }}>
                  <Trans
                    i18nKey={'domains:setupProgress.updateURLPrompt.header'}
                    values={{ domain: pendingUrl }}
                  />
                </Typography>
              )}
              <Typography variant={'body1'} fontWeight={400}>
                {t('description')}
              </Typography>
              {/* TODO: add manual url update call when available */}
              <Button variant={'contained'} disabled={isLoading}>
                {t('button')}
              </Button>
            </Stack>
          </Stack>
          <Divider
            orientation={'horizontal'}
            variant="middle"
            flexItem
            sx={{ borderBottomWidth: 2 }}
          />
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <LightbulbOutlinedIcon />
            <Typography variant="body2">
              <Trans
                i18nKey="domains:setupProgress.updateURLPrompt.sessionNote"
                components={{ b: <b></b> }}
              />
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UpdateURLPrompt;
