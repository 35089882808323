import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';

import { useHostingInfo as useHostingAccountInfo } from '@newfold/huapi-js';

import InfoListItem from '~/components/InfoListItem';
import useHostingInfo from '~/hooks/useHostingInfo';

import ViewIPModal from '../../../../../Site/scenes/Settings/components/ViewIPModal';

const IPAddressInfo = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });

  const [openIPModal, setOpenIPModal] = useState(false);
  const showIPModal = () => setOpenIPModal(true);

  const { hostingId, isDisabled } = useHostingInfo();

  const { data } = useHostingAccountInfo(hostingId, {
    query: {
      enabled: !!hostingId,
    },
  });

  return (
    <>
      <InfoListItem
        title={t('ipAddress.title')}
        tooltipText={t('ipAddress.tooltip')}
      >
        {/* @ts-expect-error */}
        <Link component="button" onClick={showIPModal} disabled={isDisabled}>
          {t('ipAddress.viewBtn')}
        </Link>
      </InfoListItem>
      <ViewIPModal
        openIPModal={openIPModal}
        setOpenIPModal={setOpenIPModal}
        ip={data?.data?.ip}
      />
    </>
  );
};

export default IPAddressInfo;
