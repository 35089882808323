// Remove SX prop
import { bool, shape } from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { isDevMode } from '../DevMode';
import { removeCTB } from './includeCTB';

/**
 * Embed is broken out of CTB as a separate component to avoid MUI Dialog ref fowarding issues
 */
const propTypes = {
  callbacks: shape({}).isRequired,
  data: shape({}).isRequired,
  isLoading: bool.isRequired,
};

const Embed = ({ callbacks, data, isLoading }) => {
  const ref = useRef(null);
  // Handle modal loading overlay dismissal in non-prod env
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let intervalId;
    const initializeClickToBuy = () => {
      if (!initialized && ref?.current?.id && window.ClickToBuy) {
        window.ClickToBuy.init({
          selector: ref.current.id,
          callbacks,
          data,
        });
        setInitialized(true);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(initializeClickToBuy, 100);

    return () => {
      clearInterval(intervalId);
      removeCTB();
    };
  }, [callbacks, data, initialized]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '1070px',
      }}
      data-testid="click-to-buy-embed"
    >
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading && open}
        onClick={isDevMode ? handleClose : null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        id="ctb-container-uuid"
        // CTB requires its parent div to have a width of 100%
        style={{ width: '100%' }}
        ref={ref}
      />
    </Stack>
  );
};

Embed.propTypes = propTypes;
export default Embed;
