import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '../../../../components/MFEProvider';
import { getRenewalDateText } from '../../../../utils/hosting-renewal-status';
import { isValidDate } from '../../../../utils/isValidDate';

const RenewalSettings = () => {
  const {
    // @ts-expect-error
    data: { hostingData, renewalCenterLink },
    // @ts-expect-error
    hostingStatus: {
      renewalStatus: { isExpired },
    },
  } = useMFEContext();
  const { t } = useTranslation('hosting');

  const expiryDate = hostingData?.expiryDate;
  const hasAutoRenew = hostingData?.hasAutoRenew;

  const renewalDateText = t(
    `renewal.date.${getRenewalDateText(hasAutoRenew, isExpired)}`,
  );

  // reformat date "04/04/2020" as "April 04, 2020"
  const prettyExpDate = moment(expiryDate).format('MMMM DD, YYYY');

  const validExpiryDate = isValidDate(expiryDate);

  return (
    (expiryDate || hasAutoRenew) && (
      <Grid container alignItems="center">
        {/* Auto-Renew */}
        <Grid item xs={4}>
          <Typography variant="h4">{t('renewal.autoRenew.header')}</Typography>
        </Grid>

        <Grid item xs={8}>
          {hasAutoRenew ? (
            <Typography variant="body2" color="green" fontWeight={600}>
              Enabled
            </Typography>
          ) : (
            <FormControlLabel
              labelPlacement="start"
              sx={{ ml: 0 }}
              slotProps={{ typography: { variant: 'body2' } }}
              control={
                <Switch
                  checked={hasAutoRenew}
                  onChange={() => {
                    if (renewalCenterLink)
                      window.open(renewalCenterLink, '_blank');
                  }}
                  // color={hasAutoRenew ? 'success' : 'error'} // TODO: dependent on future styling
                  sx={{
                    '& .MuiSwitch-track': {
                      backgroundColor: (theme) =>
                        hasAutoRenew
                          ? theme.palette.primary.main
                          : theme.palette.error.main,
                      opacity: 1,
                    },
                  }}
                />
              }
              label={
                hasAutoRenew
                  ? t('renewal.autoRenew.on')
                  : t('renewal.autoRenew.off')
              }
            />
          )}
        </Grid>

        {/* Expires */}
        <Grid item xs={4}>
          {validExpiryDate && (
            <Typography variant="h4">{renewalDateText}</Typography>
          )}
        </Grid>

        <Grid item xs={8}>
          {validExpiryDate && (
            <Typography variant="body2">
              {prettyExpDate}{' '}
              {renewalCenterLink && (
                <Link
                  href={renewalCenterLink}
                  target="_blank"
                  sx={{ ml: 2, fontWeight: 'bold' }}
                >
                  Renew
                </Link>
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    )
  );
};

export default RenewalSettings;
