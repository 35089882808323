import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts';
import { lineElementClasses } from '@mui/x-charts/LineChart';

type SummaryChartsProps = {
  dimensions?: number[];
  metric?: string;
  xLabels?: number[];
  isLoading?: boolean;
  isError?: boolean;
};

const SummaryCharts = ({
  dimensions = undefined,
  metric = undefined,
  xLabels = undefined,
  isLoading = undefined,
  isError = undefined,
}: SummaryChartsProps) => {
  return (
    <Grid item xs>
      <Card sx={{ height: 120, minWidth: 250 }} variant="outlined">
        <CardActionArea href={`./metrics?metric=${metric}`}>
          <CardHeader
            title={<Typography variant="h4">{metric}</Typography>}
            action={
              <ArrowForwardIosIcon
                sx={{
                  cursor: 'pointer',
                  alignContent: 'right',
                  paddingTop: '6px',
                }}
                color="action"
              />
            }
          />
        </CardActionArea>

        <CardContent sx={{ height: '100%', padding: 0 }}>
          {isLoading ? (
            <Skeleton width={130} />
          ) : (
            <SparkLineChart
              margin={{
                bottom: 30,
                top: 0,
                left: 20,
                right: 20,
              }}
              data={dimensions as number[]}
              plotType="line"
              curve="natural"
              xAxis={{
                scaleType: 'point',
                data: xLabels,
              }}
              sx={{
                [`& .${lineElementClasses.root}`]: {
                  stroke: 'red',
                  strokeWidth: 2,
                },
              }}
            />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
export default SummaryCharts;
