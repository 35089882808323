import '@animxyz/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OpenInNew from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingAdvancedServer } from '@newfold/huapi-js';

import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';

import MultipleChoiceSsoDialog from '../../QuickLinks/MultipleChoiceSsoDialog';
import useServerEmailChoices from '../../QuickLinks/ServerEmailChoices';

interface QuickLink {
  name: string;
  type: string;
  queryParams?: { [key: string]: string };
  canShow: boolean;
}

const AdvancedHostingQuickLinks = () => {
  const { t } = useTranslation('quickLinks');
  const { id: accountId } = useAccount();
  const [serverEmailIsOpen, setServerEmailIsOpen] = useState<boolean>(false);

  const { hostingAccount, isDisabled, isLoading } = useHostingInfo();

  const [hasAdvancedHosting] = useFeature('advanced_hosting');
  const [hasCpanel, , isClientReady] = useFeature('cpanel');
  const [isPlesk] = useFeature('plesk');
  const [isWindows] = useFeature('windows');
  const [hasRootWhm] = useFeature('whm');

  const isReseller = hostingAccount?.data?.type === 'reseller';

  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    },
  });
  const serverStatus = serverData?.data?.status;
  // CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const getQuickLinks = (): QuickLink[] => {
    const listButtonLinks: QuickLink[] = [
      {
        name: 'whm',
        type: 'vps',
        canShow: hasRootWhm,
        queryParams: {
          app: 'whm',
        },
      },
      {
        name: 'cpanel',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
      },
      {
        name: 'filemanager',
        type: 'hosting',
        canShow: hasCpanel && !isReseller && !hasAdvancedHosting,
        queryParams: {
          app: 'filemanager',
        },
      },
      {
        name: 'resellerwhm',
        type: 'reseller',
        canShow: isReseller,
        queryParams: {
          app: 'resellerwhm',
        },
      },
      {
        name: 'resellercpanel',
        type: 'reseller',
        canShow: hasCpanel && isReseller,
        queryParams: {
          app: 'resellercpanel',
        },
      },
      {
        name: 'adminpanel',
        type: 'plesk',
        canShow: isPlesk,
      },
    ];

    const listOfQuickLinks = listButtonLinks.filter((item) => item.canShow);
    return listOfQuickLinks;
  };

  const choices = useServerEmailChoices();

  const handleServerEmailClose = () => {
    setServerEmailIsOpen(false);
  };

  const handleServerEmailClick = () => {
    setServerEmailIsOpen(true);
  };

  const quickLinks = getQuickLinks();

  if (isClientReady && isWindows) {
    return null;
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          titleTypographyProps={{ variant: 'h3' }}
          title={t('quickLinks.cardTitle')}
          subheader={t('advHostingQuicklinksDesc')}
          subheaderTypographyProps={{ variant: 'body2' }}
        />
        <CardContent>
          {(!isClientReady || isLoading) && t('advHostingLoadingTxt')}
          {/* quick links success (show them) */}
          {isClientReady && quickLinks.length > 0 && (
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              alignItems={'stretch'}
            >
              {Array.isArray(quickLinks) &&
                quickLinks.map(({ name, type, queryParams }) => (
                  <SsoLink
                    key={`sso-link-${name}`}
                    fluid
                    type={type}
                    advHostingQuickLink={true}
                    {...(queryParams && { queryParams })}
                  >
                    {t(`quickLinks.links.${name}`)}
                  </SsoLink>
                ))}
              {hasCpanel && !isReseller && (
                <>
                  <MultipleChoiceSsoDialog
                    isOpen={serverEmailIsOpen}
                    onClose={handleServerEmailClose}
                    title={t('serverEmail.title')}
                    description={t('serverEmail.description')}
                    choices={choices}
                  />
                  <Button
                    variant="outlined"
                    onClick={handleServerEmailClick}
                    data-testid="email-sso-button"
                    disabled={
                      serverStatus !== 'active' ||
                      isDisabled ||
                      hasPurchasedRecently
                    }
                    endIcon={<OpenInNew />}
                  >
                    {t('serverEmail.buttonLabel')}
                  </Button>
                </>
              )}
            </Stack>
          )}
          {isClientReady && quickLinks?.length <= 0 && (
            <Typography variant="h3" align="center">
              {t('quickLinks.noQuickLinks')}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default AdvancedHostingQuickLinks;
