import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import useFeature from '~/hooks/useFeature';

const FtpAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ftp } = t('scenes.advanced', { returnObjects: true }) as {
    ftp: {
      title: string;
      tooltipText: string;
      manageBtn: string;
    };
  };

  const [hasSites] = useFeature('sites');
  const [isDestiny] = useFeature('destiny');

  if (!hasSites) {
    return null;
  }

  return (
    <InfoListItem
      // @ts-expect-error
      title={ftp?.title}
      // @ts-expect-error
      tooltipText={ftp?.tooltip}
      {...{
        inlineCTA: isDestiny ? (
          <Button variant="text" href={'/ftp-access'} color="primary">
            {ftp?.manageBtn}
          </Button>
        ) : (
          <SsoLink variant="text" type={'hosting'} queryParams={{ app: 'ftp' }}>
            {ftp?.manageBtn}
          </SsoLink>
        ),
      }}
    />
  );
};

export default FtpAccessInfo;
