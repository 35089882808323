import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingOnboardingDataQueryKey,
  useHostingOnboardingDataUpdate,
} from '@newfold/huapi-js';
import {
  HostingOnboardingDataUpdateBody,
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import useResponsive from '~/hooks/useResponsive';

import updateCompleted from '../assets/update-completed.svg';
import { domainSetupCardSx } from '../utils/stepData';

type FinalizeDomainPropOptions = {
  site: SitesInfo200 | HostingSitesV2200ItemsItem;
  isLoading?: boolean;
};

/*
  A component that takes a Site object as defined by HUAPI and
  displays domain info as well as a button to perform an action
*/
const UpdateURLComplete = ({
  site,
  isLoading = false,
}: FinalizeDomainPropOptions) => {
  /*
    The site pending url has been successfully promoted to site url. We will
    show a button that unsets the onboarding flag (addon.meta.setup_flow), which
    will prevent this component from displaying anymore.

    - This will likely be a useHostingOnboardingDataUpdate HUAPI hook call.
    - We want to display the site url here, since the former pending url has now been promoted.

    This component will need to be displayed in two locations:
    1. Replacing the stepper on the Hosting Overview - NOT within the stepper
    2. On Site Overview, between the tab list and tab content
  */
  const { t } = useTranslation('domains', {
    keyPrefix: 'setupProgress.updateURLComplete',
  });

  const { isTablet: getIsTablet } = useResponsive();
  const isTablet = getIsTablet();
  const generateAlert = useAlert();

  const { id } = useAccount();
  const hostingId = String(id);

  const queryClient = useQueryClient();
  const hostingOnboardingQueryKey = getHostingOnboardingDataQueryKey(hostingId);

  const { mutate: hostingOnboardingDataUpdate } =
    useHostingOnboardingDataUpdate({
      mutation: {
        onMutate: () => {
          const previousOnboardingData:
            | { data: HostingOnboardingDataUpdateBody }
            | undefined = queryClient.getQueryData(hostingOnboardingQueryKey);

          queryClient.setQueryData(hostingOnboardingQueryKey, () => {
            if (!previousOnboardingData) return previousOnboardingData;

            return {
              ...previousOnboardingData,
              data: {
                ...previousOnboardingData.data,
                completed: true,
              },
            };
          });

          return { previousOnboardingData };
        },
        onSettled: (data, error, variables, context) => {
          if (error) {
            generateAlert({
              description: t('errorMessage'),
              severity: 'error',
              showCloseBtn: true,
            });

            if (context?.previousOnboardingData) {
              return queryClient.setQueryData(
                hostingOnboardingQueryKey,
                context?.previousOnboardingData,
              );
            }
          }

          return queryClient.invalidateQueries(hostingOnboardingQueryKey);
        },
      },
    });

  const handleUpdateOnboarding = () => {
    // Once we show the stepper in Site context, this will perform some other function (update localStorage value?)
    hostingOnboardingDataUpdate({ hostingId, data: { completed: true } });
  };

  const siteUrl = site?.url;
  if (!siteUrl || site.pending_url) return <></>;

  return (
    <Card
      data-testid="update-url-complete"
      sx={{
        ...domainSetupCardSx,
        backgroundColor: 'primary.light',
      }}
    >
      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction={isTablet ? 'column' : 'row'}
          spacing={5}
          alignItems={'center'}
        >
          <CardMedia
            image={updateCompleted}
            component="img"
            alt={t('imageAltText')}
            sx={{ width: 244, height: 168 }}
          />
          <Stack
            spacing={3}
            alignItems={'center'}
            textAlign={'center'}
            maxWidth={450}
          >
            {isLoading ? (
              <Skeleton height={25} width={isTablet ? 200 : 400} />
            ) : (
              <Typography variant={'h2'} sx={{ wordBreak: 'break-word' }}>
                <Trans
                  i18nKey={'domains:setupProgress.updateURLComplete.header'}
                  values={{ domain: siteUrl }}
                />
              </Typography>
            )}
            <Typography variant={'body1'} fontWeight={400}>
              {t('description')}
            </Typography>
            {/* TODO: determine which onboarding flag we want to set here, and add the call */}
            <Button
              data-testid={'update-url-complete-button'}
              variant={'contained'}
              sx={{ minWidth: '120px' }}
              disabled={isLoading}
              onClick={handleUpdateOnboarding}
            >
              {t('button')}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UpdateURLComplete;
