import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import {
  getSitesAutoupdatesInfoQueryKey,
  useSitesAutoupdatesUpdate,
} from '@newfold/huapi-js';
import { SitesAutoupdatesResponse } from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import StatusListItem from '~/components/StatusListItem';

import { useSiteContext } from '../..';

type AutoUpdateTypeOptions = {
  component?: string;
  type?: string;
  typeLabel?: string;
  wpDataAU?: any;
  isLoading?: boolean;
  disabled?: boolean;
};

const AutoUpdateType = ({
  component = '',
  type = '',
  typeLabel = '',
  wpDataAU = null,
  isLoading = false,
  disabled = false,
}: AutoUpdateTypeOptions) => {
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();

  const { siteId } = useParams();
  const siteIdNumber = Number(siteId);

  const { isSiteOrHostingDisabled } = useSiteContext();
  const isDisabled = isSiteOrHostingDisabled || disabled;

  const queryClient = useQueryClient();
  const queryKey = getSitesAutoupdatesInfoQueryKey(siteIdNumber);

  const updateList = {
    core: 'core',
    plugins: 'plugins',
    themes: 'themes',
  };

  const { mutate: setAutoupdates } = useSitesAutoupdatesUpdate({
    mutation: {
      onMutate: async () => {
        await queryClient.cancelQueries({ queryKey });

        const previousAutoupdatesData:
          | { data: SitesAutoupdatesResponse }
          | undefined = queryClient.getQueryData(queryKey);

        if (!previousAutoupdatesData) return { previousAutoupdatesData };

        queryClient.setQueryData(queryKey, () => ({
          ...previousAutoupdatesData,
          data: {
            ...previousAutoupdatesData.data,
            // @ts-expect-error
            [updateList[type]]: !previousAutoupdatesData?.data[type],
          },
        }));

        return { previousAutoupdatesData };
      },
      onSettled: (data, error, variables, context) => {
        if (error) {
          generateAlert({
            severity: 'error',
            description: t('updateStatus.autoUpdate.error', {
              item: typeLabel,
            }),
            showCloseBtn: true,
          });

          if (context?.previousAutoupdatesData) {
            return queryClient.setQueryData(
              queryKey,
              context?.previousAutoupdatesData,
            );
          }
        }

        return queryClient.invalidateQueries(queryKey);
      },
    },
  });

  const handleToggleChange = () => {
    setAutoupdates({
      siteId: siteIdNumber,
      data: {
        // @ts-expect-error
        type: type,
        enabled: !wpDataAU[type],
      },
    });
  };

  const autoUpdateEnabled = wpDataAU && wpDataAU[type];

  const formatStatusMsg = (status: string) => {
    const text = status ? 'on' : 'off';
    return t(`updateStatus.autoUpdate.statusText`, { status: text });
  };

  if (!['overview', 'settings'].includes(component)) return null;

  return (
    <>
      {component === 'overview' && (
        // @ts-expect-error
        <StatusListItem
          // @ts-expect-error
          avatarIcon={AutorenewIcon}
          // @ts-expect-error
          avatarIconColor={autoUpdateEnabled ? 'success' : 'error'}
          ctaText={t('turnOnBtn')}
          {...(!autoUpdateEnabled && { handleCTA: handleToggleChange })}
          disableCTA={isDisabled}
          isLoading={isLoading}
          dataTestId={`wp-${type}-AU`}
        >
          {`${typeLabel} ${formatStatusMsg(autoUpdateEnabled)}`}
        </StatusListItem>
      )}
      {component === 'settings' && (
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          alignItems={{ xs: 'center' }}
          key={type}
        >
          <label>{typeLabel}</label>
          <Switch
            onChange={handleToggleChange}
            checked={wpDataAU[type]}
            disabled={isDisabled}
            data-testid={`wp-${type}-AU`}
          />
        </Grid>
      )}
    </>
  );
};

export default AutoUpdateType;
