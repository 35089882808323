import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useSitesSecuritySslStatus } from '@newfold/huapi-js';

import StatusListItem from '~/components/StatusListItem';

type SslStatusPropOptions = {
  siteId?: number | string | undefined;
};

const SslStatus = ({ siteId = undefined }: SslStatusPropOptions) => {
  const { t } = useTranslation(['site', 'security']);

  // GET: SSL status
  const { data, isLoading, isError } = useSitesSecuritySslStatus(
    Number(siteId),
    {},
    {
      query: { enabled: !!siteId },
    },
  );

  const sslStatus = data?.data?.ssl_status || 'undefined';

  const navigate = useNavigate();

  const handleLink = (url: string) => {
    navigate(url);
  };

  return (
    // @ts-expect-error
    <StatusListItem
      isLoading={isLoading}
      isError={isError}
      dataTestId={'active' === sslStatus ? 'sslStatusON' : 'sslStatusOFF'}
      // @ts-expect-error
      avatarIcon={
        'active' === sslStatus ? LockOutlinedIcon : LockOpenOutlinedIcon
      }
      // @ts-expect-error
      avatarIconColor={'active' === sslStatus ? 'success' : 'error'}
      // @ts-expect-error
      tooltipText={
        'active' !== sslStatus
          ? t(`security:sslCertificate.stage.${sslStatus}.tooltipDesc`)
          : ''
      }
      ctaText={t('security:manageBtnLabel')}
      // @ts-expect-error
      handleCTA={() => handleLink('security#ssl-cert')}
    >
      {t('sslMsg', {
        siteSslStatus: t(
          `security:sslCertificate.stage.${sslStatus}.titleShort`,
        ),
      })}
    </StatusListItem>
  );
};

export default SslStatus;
