import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type WordpressModalProps = {
  siteId: number;
  openWordPressModal: boolean;
  setOpenWordPressModal: Function;
  currentWordPress: string;
  wordPressList: string[] | undefined;
  updateWordPress: Function;
  isLoading: boolean;
  WordPressListLoading: boolean;
  WordpressType: string;
  latestVersion?: string;
  isDeprecatedWordPressVersion?: boolean;
};

const WordPressModal = ({
  siteId,
  openWordPressModal,
  setOpenWordPressModal,
  currentWordPress,
  wordPressList,
  updateWordPress,
  isLoading,
  WordPressListLoading,
  WordpressType,
}: WordpressModalProps) => {
  const { t } = useTranslation('site');

  const [selectedVersion, setSelectedVersion] = useState(WordpressType);
  const [currentVersion, setCurrentVersion] = useState(currentWordPress);

  const handleChange = (event: any) => {
    setSelectedVersion(event.target.value);
  };

  const handleClose = (reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpenWordPressModal(false);
    setSelectedVersion(currentVersion);
  };

  const updateWordPressVersion = () =>
    updateWordPress(
      {
        siteId: siteId,
        data: {
          version_ptr: selectedVersion,
        },
      },
      {
        onSuccess: ({ data }: any) => {
          setCurrentVersion(selectedVersion);
          setOpenWordPressModal(false);
        },
      },
    );

  return (
    <Dialog open={openWordPressModal} data-testid="WordPress-modal">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DialogTitle fontSize="20px" sx={{ pl: 3 }}>
          {t('wordpressVersion.modal.title')}
        </DialogTitle>
      </Box>
      <Divider />
      <DialogContent>
        <>
          <Stack direction="row" spacing={1} pb={2}>
            <Typography>
              <Trans
                i18nKey={t('site:wordpressVersion.modal.desc')}
                components={{
                  Backup: <Link href="../backups" />,
                }}
              />
            </Typography>
          </Stack>

          <Typography variant="h4" pb={2}>
            {t('wordpressVersion.modal.subTitle')}
          </Typography>

          <FormControl fullWidth>
            <Select
              value={selectedVersion}
              labelId="wordpressVersion-dropdown-label"
              onChange={handleChange}
              data-testid="wordPress-list"
              id="wordpressVersion-dropdown"
            >
              {WordPressListLoading ? (
                <CircularProgress
                  sx={{ ml: 1, mt: 2, alignSelf: 'center' }}
                  data-testid="wordpress-loader"
                />
              ) : (
                wordPressList?.map((wordpress, index) => (
                  <MenuItem key={index} value={wordpress}>
                    <Stack direction="row" spacing={1}>
                      <ListItemIcon>
                        {wordpress === selectedVersion && <CheckIcon />}
                      </ListItemIcon>

                      <Typography variant="body1" sx={{ mt: 5 }}>
                        {wordpress.charAt(0).toUpperCase() + wordpress.slice(1)}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          <Button variant="text" onClick={handleClose}>
            {t('wordpressVersion.modal.cancelBtn')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={isLoading || WordpressType === selectedVersion}
            onClick={updateWordPressVersion}
            data-testid="change-wordpress"
          >
            {t('wordpressVersion.modal.applyBtn')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default WordPressModal;
