import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useResponsive from '~/hooks/useResponsive';

export default function LogsCard({
  type = '',
  total = 0,
  isLoading = false,
  isError = false,
}: PropsWithChildren<{
  type: string;
  total: number;
  isLoading: boolean;
  isError: boolean;
}>) {
  const { t } = useTranslation('cloud', { keyPrefix: 'logs' });
  const { isMobile } = useResponsive();
  const routeArgs =
    type === 'error' ? '?severity=Fatal error&severity=Deprecated' : '';

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Stack spacing={0.5}>
              <Typography variant="h2">{t(`${type}.title`)}</Typography>
              <Typography>{t(`${type}.desc`)}</Typography>
            </Stack>
            <Button variant="outlined" href={`${type}${routeArgs}`}>
              {isMobile() ? t(`${type}.mobileBtnText`) : t(`${type}.btnText`)}
            </Button>
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1} alignItems={'center'}>
              {isLoading ? (
                <Skeleton width={25} />
              ) : (
                <Typography fontWeight="bold">
                  {isError ? '-' : total}
                </Typography>
              )}
              <Typography variant="body2" fontWeight="bold">
                {t(`${type}.totalLabel`)}
              </Typography>
            </Stack>
            <Typography variant="body2">{t('dayRangeLabel')}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
