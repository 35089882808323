import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useBrandInfo } from '~/hooks/useBrandInfo';

type FixSiteModalPropOptions = {
  siteId: string | number;
  open: boolean;
  setOpen: (state: boolean) => void;
};

const FixSiteModal = ({
  siteId,
  open = false,
  setOpen,
}: FixSiteModalPropOptions) => {
  const navigate = useNavigate();
  const { t } = useTranslation('site');

  const { phoneNumberFormatted } = useBrandInfo();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCTA = (path: string) => {
    navigate(`/sites/${siteId}/${path}`);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="fix-site-dialog-title"
      aria-describedby="fix-site-dialog-description"
      data-testid={`fix-site-${siteId}-dialog`}
    >
      <DialogTitle id="fix-site-dialog-title">
        <Stack direction="row" spacing={2}>
          <WarningIcon color="warning" />
          <Typography variant="h2">{t('fixSite.title')}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="fix-site-dialog-description">
          <Trans
            i18nKey="site:fixSite.description"
            values={{ phoneNumber: phoneNumberFormatted }}
            components={{ b: <b></b> }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Stack spacing={3} alignItems="center">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="outlined" onClick={() => handleCTA('backups')}>
              {t('fixSite.restoreBtn')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleCTA('settings#delete-content')}
            >
              {t('fixSite.resetBtn')}
            </Button>
          </Stack>
          <Button
            variant="text"
            color="error"
            onClick={() => handleCTA('settings#delete')}
          >
            {t('fixSite.deleteBtn')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default FixSiteModal;
