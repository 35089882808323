import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useSitesInfo } from '@newfold/huapi-js';

import useHostingInfo from '~/hooks/useHostingInfo';
import useSitePath from '~/hooks/useSitePath';
import { getSiteDisabled } from '~/utils/getSiteDisabled';

import SiteFork from './scenes/SiteDetailFork';

export const SiteContext = createContext({});
export const useSiteContext = () => useContext(SiteContext);

export default function SiteDetail() {
  const { siteId } = useParams();

  const { isValid } = useSitePath(siteId);

  const { data: siteInfo, ...site } = useSitesInfo(Number(siteId), {
    query: {
      enabled: isValid,
      select: (data) => ({
        ...data?.data,
      }),
    },
  });

  const isDisabled = getSiteDisabled(siteInfo?.status) || site?.isLoading;

  const accountBackRef = siteInfo?.account_back_ref;
  const siteAccountId =
    !!accountBackRef && accountBackRef.startsWith('WN')
      ? siteInfo?.account_back_ref
      : siteInfo?.account_id;

  const { isDisabled: hostingDisabled, isLoading: hostingLoading } =
    useHostingInfo({
      // @ts-expect-error
      hostingId: siteAccountId,
      // @ts-expect-error
      options: {
        enabled: !!siteAccountId,
      },
    });

  const siteData = {
    data: { ...siteInfo },
    ...site,
    accountId: siteAccountId,
    isDisabled,
    isHostingDisabled: hostingDisabled || hostingLoading,
    isSiteOrHostingDisabled: isDisabled || hostingDisabled || hostingLoading,
  };

  return (
    <SiteContext.Provider value={siteData}>
      <SiteFork />
    </SiteContext.Provider>
  );
}
