import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

export default function TableLoading() {
  return (
    <TableBody>
      {[1, 2, 3].map((key) => (
        <TableRow key={`loader-row-${key}`} data-testid={`table-loader-${key}`}>
          {/* DomainName */}
          <TableCell>
            <Stack spacing={1} alignItems={'flex-start'}>
              <Typography variant="body2">
                <Skeleton
                  width={256}
                  data-testid={`domain-${key}name-loading`}
                />
              </Typography>
              <Skeleton variant={'rounded'} width={100}></Skeleton>
            </Stack>
          </TableCell>

          {/* DnsStatus */}
          <TableCell>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant={'h2'} component={'span'}>
                <Skeleton width={100} />
              </Typography>
            </Stack>
          </TableCell>

          {/* SslStatus */}
          <TableCell>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography variant={'h2'} component={'span'}>
                <Skeleton width={100} />
              </Typography>
            </Stack>
          </TableCell>

          {/* Actions */}
          <TableCell>
            <Skeleton width={25} height={25} variant={'rectangular'} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
