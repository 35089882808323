import vodien from './brands/vodien';

const getAdditionalStylesByBrand = (brand, color) => {
  switch (brand) {
    case 'vodien':
      return vodien(color);
    default:
      return {};
  }
};

export default getAdditionalStylesByBrand;
