import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';

import { useDomainInfo } from '@newfold/huapi-js';
import {
  DomainInfo200SetupProgress,
  HostingSitesV2200ItemsItem,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import useOnboarding from '~/hooks/useOnboarding';
import { checkCreatingStatus } from '~/utils/siteMeta';

import { useSiteContext } from '../..';
import DomainSetupProgress from './components/DomainSetupProgress';
import StepContent from './components/StepContent';
import UpdateURLComplete from './components/UpdateURLComplete';
import UpdateURLPrompt from './components/UpdateURLPrompt';
import {
  DomainSetupStep,
  domainUnregisteredError,
  pendingURLJobTimeout,
  stepKeys,
  stepStatuses,
} from './utils/stepData';
import { getIsActionableError, translateStatus } from './utils/stepDataHelpers';

export type DomainSetupPropOptions = {
  site?: SitesInfo200 | HostingSitesV2200ItemsItem;
};

/*
 * Wrapper component that orchestrates which part(s) of the domain setup process to show
 */
const DomainSetup = ({ site = undefined }: DomainSetupPropOptions) => {
  const siteContext = useSiteContext();
  const isHostingContext =
    siteContext &&
    Object.keys(siteContext).length === 0 &&
    siteContext.constructor === Object;

  const { t } = useTranslation('domains', { keyPrefix: 'setupProgress' });
  const [openChangeURLModal, setOpenChangeURLModal] = useState(false);

  const { onboarding, firstSite, numberOfSites } = useOnboarding(
    true, // When we eventually show the stepper in Site context, pass !site here
    // If hosting context is detected and a site wasn't passed in, have the onboarding hook fetch it
    isHostingContext && !site,
  );

  const siteInfo: SitesInfo200 | HostingSitesV2200ItemsItem | undefined =
    site ?? firstSite;

  const siteStatus: string | undefined = siteInfo?.status;
  const domainId: number | null | undefined = siteInfo?.pending_url_data
    ?.domain_id
    ? Number(siteInfo?.pending_url_data?.domain_id)
    : undefined;

  const { data: domainInfo, isInitialLoading: domainLoading } = useDomainInfo(
    domainId!,
    {
      query: {
        enabled: !!siteInfo?.pending_url && !!domainId,
        // cacheTime: 0,
      },
    },
  );

  const setupProgress: DomainInfo200SetupProgress | undefined =
    domainInfo?.data?.setup_progress;

  if (
    !siteInfo ||
    onboarding?.completed ||
    // Only check number of sites if onboarding gave us a site
    (firstSite && numberOfSites !== 1)
  )
    return <></>;

  const pendingUrl = siteInfo?.pending_url;

  if (domainLoading)
    return <Skeleton variant="rounded" width={'100%'} height={250} />;

  const siteStepStatus =
    siteStatus === 'active'
      ? 1 // site is active
      : checkCreatingStatus(siteStatus)
      ? 0 // site is installing
      : -1; // site has errored

  /*
    The functionality does not yet exist to manually update the site url as its own step
    so for now as long as everything up to cdn has passed validation, just consider it done.
    TODO: Once it is available and we change this, be sure to test thoroughly.
  */
  const urlStepStatus =
    !siteInfo?.pending_url &&
    !!setupProgress?.validate_ssl &&
    [1, 2].includes(setupProgress?.validate_ssl) &&
    !!setupProgress?.validate_cdn &&
    [1, 2].includes(setupProgress?.validate_cdn)
      ? 1
      : null;

  const isUnregisteredError: boolean = [
    setupProgress?.validate_dns_error,
    setupProgress?.validate_ssl_error,
    setupProgress?.validate_cdn_error,
  ].includes(domainUnregisteredError);

  const isTimeoutError: boolean =
    siteInfo?.pending_url_data?.job_failed ?? false;

  type RawStepStatus = {
    key: string;
    status: number | null;
    error?: string | null;
  };
  // Order of the steps is set here
  const rawStepStatuses: RawStepStatus[] = [
    { key: stepKeys.site, status: siteStepStatus },
    {
      key: stepKeys.dns,
      status: setupProgress?.validate_dns ?? null,
      error: setupProgress?.validate_dns_error ?? null,
    },
    {
      key: stepKeys.ssl,
      status: setupProgress?.validate_ssl ?? null,
      error: setupProgress?.validate_ssl_error ?? null,
    },
    {
      key: stepKeys.cdn,
      status: setupProgress?.validate_cdn ?? null,
      error: setupProgress?.validate_cdn_error ?? null,
    },
    { key: stepKeys.url, status: urlStepStatus },
  ];

  let foundActive: string | undefined;
  let prevRawStatus: RawStepStatus | undefined;
  let isActionableError: boolean = false;
  const steps: DomainSetupStep[] = [];

  /*
    - There will be periods during which a given step has completed but the
      next step has not yet gone active. In this case, the next null step after
      the latest completed step will be considered the active step.

    - It is possible that more than one step could be active (status = 0) at a time. In this
      case, we will consider the first active one in the list to be the active step.
  */
  rawStepStatuses.forEach((rawStatus) => {
    let status: number | null = null;

    // if we haven't seen an active status yet and we 1) find one, or 2) see a null immediately after a completed/skipped
    // then set this status to 0 (active), and track it
    if (
      !foundActive &&
      (rawStatus.status === 0 ||
        (rawStatus.status === null && [1, 2].includes(prevRawStatus?.status!)))
    ) {
      status = 0;
      foundActive = rawStatus.key;
    }
    // if we have seen an active status already and we see another active status, set it to null (not started)
    else if (foundActive && rawStatus.status === 0) {
      status = null;
    } else {
      status = rawStatus.status;
    }

    const translatedStatus = translateStatus(status);

    /*
      A job timeout error should take precedence over an unregistered error because the domain could be registered
      in the time between a timeout first occurring and a new job running; both take precedence over other errors.
    */
    const errorMessage = isTimeoutError
      ? pendingURLJobTimeout
      : isUnregisteredError
      ? domainUnregisteredError
      : rawStatus.error ?? setupProgress?.validate_error;

    if (getIsActionableError(errorMessage)) isActionableError = true;

    const step: DomainSetupStep = {
      key: rawStatus.key,
      name: t(`steps.${rawStatus.key}.name`),
      status:
        translatedStatus === stepStatuses.active && isActionableError
          ? stepStatuses.errored
          : translatedStatus,
      component: (
        <StepContent
          stepType={rawStatus.key}
          stepStatus={translatedStatus}
          stepError={errorMessage}
          handleRetry={setOpenChangeURLModal}
        />
      ),
    };

    steps.push(step);

    prevRawStatus = rawStatus;
  });

  // Determine the 'active' step; NOTE: the active step could have been marked as errored by now
  const activeStepIndex = steps.findIndex((step) =>
    [stepStatuses.active, stepStatuses.errored].includes(step.status),
  );
  const lastStep = steps.length - 1;
  const isOnLastStep = activeStepIndex === lastStep;
  const urlStepCompleted = !!steps.find(
    (step) =>
      step.key === stepKeys.url && step.status === stepStatuses.completed,
  );

  if (isHostingContext && pendingUrl && !urlStepCompleted) {
    return (
      <DomainSetupProgress
        activeStepIndex={activeStepIndex}
        isActionableError={isActionableError}
        isOnLastStep={isOnLastStep}
        isUnregisteredError={isUnregisteredError}
        openChangeURLModal={openChangeURLModal}
        setOpenChangeURLModal={setOpenChangeURLModal}
        site={siteInfo}
        steps={steps}
      />
    );
  }

  if (!isHostingContext && pendingUrl && isOnLastStep) {
    return <UpdateURLPrompt site={siteInfo} />;
  }

  if (!pendingUrl && !setupProgress) {
    // NOTE: This won't yet work past the initial site, as there is no per-site onboarding value to confirm against
    return <UpdateURLComplete site={siteInfo} />;
  }
};

export default DomainSetup;
