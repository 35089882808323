// import StorageIcon from '@mui/icons-material/Storage';
import { useQueryClient } from '@tanstack/react-query';
import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getSitesInfoQueryKey } from '@newfold/huapi-js';
import { HostingSitesV2200ItemsItem } from '@newfold/huapi-js/src/index.schemas';

import { invalidateMultipleQueries } from '~/components/MFEProvider/lib/HttpClient/queryClient';
import useAccount from '~/hooks/useAccount';
import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';
import useHostingInfo from '~/hooks/useHostingInfo';
import useRefetchInterval from '~/hooks/useRefetchInterval';
import AddSiteButton from '~/scenes/Hosting/components/AddSiteButton';

import SiteCard from './components/SiteCard';

const SitesLoading = () => {
  const { t } = useTranslation('sites');
  return (
    <Paper variant="outlined" sx={{ p: 4, textAlign: 'center' }}>
      <Stack spacing={2} alignItems={'center'}>
        <Typography variant="h3">{t('cloud.loadingSites')}</Typography>
      </Stack>
    </Paper>
  );
};

const SitesEmpty = () => {
  const { t } = useTranslation('sites');
  const addSiteBtnTranslation = t('cloud.addSiteBtn', {
    wp: `${t('platform.wordpress')} `,
  });

  const { isDisabled } = useHostingInfo();

  return (
    <Paper
      variant="outlined"
      sx={{ borderStyle: 'dashed', p: 4, textAlign: 'center' }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <Typography variant="h3">{t('cloud.emptySites.header')}</Typography>
        <Typography variant="body2">
          {t('cloud.emptySites.description')}
        </Typography>

        <Button variant="outlined" href="./sites/add" disabled={isDisabled}>
          {addSiteBtnTranslation}
        </Button>
      </Stack>
    </Paper>
  );
};

const Sites = () => {
  const { t } = useTranslation('sites');
  const addSiteBtnTranslation = t('cloud.addSiteBtn', {
    wp: `${t('platform.wordpress')} `,
  });
  const queryClient = useQueryClient();

  const { id: hostingId } = useAccount();
  const { refetchTime } = useRefetchInterval();

  const [pendingSiteQueries, setPendingSiteQueries] = useState<
    SetStateAction<any[]>
  >([]);

  const { data: hostingSites, isLoading } = useFilteredHostingSites(hostingId, {
    query: {
      enabled: !!hostingId,
      refetchInterval: (data: any) => {
        const sites = data?.data?.items;
        const keepPolling: any = sites?.some((i: any) => i.status !== 'active');
        return keepPolling && refetchTime;
      },
      refetchIntervalInBackground: true,
      onSuccess: async (data: any) => {
        const sites = data?.data?.items;
        const pendingSites = sites?.filter((i: any) => i.status !== 'active');
        const hasPendingSites = pendingSites?.length > 0;
        const hasPendingSiteQueries = pendingSiteQueries?.length > 0;
        const donePolling = !hasPendingSites && hasPendingSiteQueries;

        // Track sites that are pending, once they become active,
        // invalidate the site info call(s) to keep cache in sync
        if (hasPendingSites) {
          pendingSites?.forEach((site: HostingSitesV2200ItemsItem) => {
            const siteQueryKey = getSitesInfoQueryKey(Number(site?.id));
            const queryKeys = hasPendingSiteQueries
              ? [pendingSiteQueries, siteQueryKey]
              : [siteQueryKey];
            setPendingSiteQueries(queryKeys);
          });
        }
        if (donePolling) {
          setPendingSiteQueries([]);
          await invalidateMultipleQueries(queryClient, pendingSiteQueries);
        }
        return data;
      },
    },
  });

  const sitesList = hostingSites?.data?.items;

  return (
    <Card>
      <CardHeader
        title={t('cloud.header')}
        action={
          <AddSiteButton isLoading={isLoading}>
            {addSiteBtnTranslation}
          </AddSiteButton>
        }
        direction={{ xs: 'column', sm: 'row' }}
      />
      <CardContent>
        <Stack spacing={2}>
          {isLoading && <SitesLoading />}

          {/* 0 sites */}
          {!isLoading && sitesList?.length === 0 && <SitesEmpty />}

          {/* 1+ sites */}
          {!isLoading &&
            sitesList &&
            Array.isArray(sitesList) &&
            sitesList.length > 0 &&
            sitesList.map((site: HostingSitesV2200ItemsItem) => (
              <SiteCard site={site} key={site.id} />
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Sites;
