// Remove SX prop

/*
  MultipleChoiceModalDialog

    This is a generic modal dialog with multiple choice options that can be selected by clicking,
    and two buttons, Cancel and one that you specify, to use to either exit the dialog or to initiate
    the action of the selected choice. Each choice is for the same type of action, which you must
    also specify.

    Use this to build your own custom dialog component.  For an example, see
    src/scenes/Hosting/scenes/HostingDetail/components/MultipleChoiceSsoDialog/index.js

  Properties:

    isOpen: A boolean to indicate whether or not to show the dialog box.  This might be a
      state variable in the calling component.
    backAction: Optional function that defines a behavior for clicking the Cancel button besides
      closing the modal.
    onClose: A function to call when the dialog is closed.  It should update the isOpen
      state variable at a minimum, and should probably also reset the choices to their default
      state for the next time the dialog is opened.
    title: The title of the dialog.
    description: Optional. The description of the dialog.
    choices: An array of React Components, one for each choice that can be selected.
    actionButton: A React Component that will be used to initiate the action of the selected choice.

  State:

    None.
*/
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type MultipleChoiceModalDialogPropOptions = {
  isOpen: boolean;
  backAction?: VoidFunction;
  onClose: VoidFunction;
  title: string;
  description?: string;
  choices?: ReactNode[] | null;
  actionButton: ReactNode;
};

const MultipleChoiceModalDialog = ({
  isOpen,
  backAction = undefined,
  onClose,
  title,
  description = '',
  choices = null,
  actionButton,
  ...props
}: MultipleChoiceModalDialogPropOptions) => {
  const { t } = useTranslation('common');

  const handleCloseClick = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} {...props} sx={{ maxHeight: 600, mt: 10 }}>
      <DialogContent>
        <Typography variant="h2">{title}</Typography>
        <Divider variant="fullWidth" sx={{ m: 1, p: 0 }} />
        <Stack direction="column" spacing={2}>
          <Typography>{description}</Typography>
          {choices}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="text"
            onClick={!!backAction ? backAction : handleCloseClick}
            data-testid="mcmd-cancel"
          >
            {t('actions.cancel')}
          </Button>
          {actionButton}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleChoiceModalDialog;
