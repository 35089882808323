import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useAccountHostingList, useDbUsage } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import { useMFEContext } from '~/components/MFEProvider';
import useFeature from '~/hooks/useFeature';
import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';
import useHostingInfo from '~/hooks/useHostingInfo';
import ResourcesWarningModal from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/components/ResourcesWarningModal';

export interface ResourceLimitType {
  limitReached: boolean;
}

const AddSiteValidation = ({
  open = false,
  showingAllSites = false,
  setSelected,
  setIsDBUsageLoading,
}: PropsWithChildren<{
  open: boolean;
  setSelected: Function;
  setIsDBUsageLoading: Function;
  showingAllSites?: boolean;
}>) => {
  const { t } = useTranslation('sites');
  const [isAtomic] = useFeature('atomic');

  const resourceLimitMap: { [key: string]: ResourceLimitType } = {};

  const generateAlert = useAlert();
  // @ts-ignore
  const { hostingId } = useMFEContext();

  const { data: hostingSites } = useFilteredHostingSites(hostingId, {
    query: {
      enabled: !!hostingId,
    },
  });

  const availableSites = hostingSites?.data?.available_sites;

  const { data: hostingList, isError } = useAccountHostingList({
    query: {
      enabled: showingAllSites,
    },
  });

  const { hostingAccount, isDisabled } = useHostingInfo();

  const cloudHosting = hostingAccount?.data?.type === 'cluster_atomic';
  const workers = hostingAccount?.data?.resources?.workers;
  const storage = hostingAccount?.data?.resources?.storage;

  const isRestrictDbUsageCall = isDisabled ? false : !!hostingId;
  const { data: dbUsage, isLoading } = useDbUsage(hostingId, {
    query: {
      enabled: isRestrictDbUsageCall && open && !isAtomic,
    },
  });

  const db_limit = dbUsage?.data?.db_limit;
  const db_count = dbUsage?.data?.db_count;

  // handle close validation
  const handleCloseValidation = () => {
    return setSelected(false);
  };

  if (!open) return null;

  // I. Multi Hosting checks
  if (showingAllSites) {
    //@ts-ignore
    const { accounts } = hostingList?.data;

    // A. If there is an error or no accounts, show error alert
    if (isError || !accounts) {
      generateAlert({
        severity: 'error',
        description: t('addSiteErrMsg'),
        showCloseBtn: true,
        persist: false,
      });
      setSelected(false);
      return null;
    }

    // B. If there is only one account, go to add site flow
    if (accounts?.length === 1) {
      const { current_sites, max_sites } = accounts[0];

      const maxSitesValue =
        max_sites === 'unlimited' ? Infinity : parseInt(max_sites) || null;

      const availableSites = maxSitesValue
        ? Math.max(maxSitesValue - current_sites, 0)
        : null;

      // i. If there are no available sites, show error alert
      resourceLimitMap.sites = { limitReached: !availableSites };

      // ii. Cloud Hosting specific check - PHP workers limit
      const noAvailableWorkers =
        typeof workers?.available === 'number' && workers?.available < 2;

      resourceLimitMap.phpWorker = {
        limitReached: cloudHosting && noAvailableWorkers,
      };

      // iii. Cloud Hosting specific check - Data Storage limit
      const noAvailableStorage =
        typeof storage?.available === 'number' && storage?.available < 1;

      resourceLimitMap.storage = {
        limitReached: cloudHosting && noAvailableStorage,
      };

      // iv. Check Database limit
      const noAvailableDB = db_limit && db_count && db_count >= db_limit;

      resourceLimitMap.db = {
        limitReached: !isAtomic && (noAvailableDB as boolean),
      };

      if (
        !isLoading &&
        Object.keys(resourceLimitMap).filter(
          (resource) => resourceLimitMap[resource].limitReached,
        ).length > 0
      ) {
        setIsDBUsageLoading(false);
        return (
          <ResourcesWarningModal
            resourceLimitMap={resourceLimitMap}
            open
            setOpen={handleCloseValidation}
          />
        );
      }
      // iv. If there are available sites, go to add site flow
      return <Navigate to="./add" />;
    }

    // C. If there are multiple accounts, go to hosting list so the user can select an account
    if (accounts?.length > 1) {
      window.location.href = '/my-account/hosting/list';
      return null;
    }
  }

  // II. Check Sites limit
  // if availableSites = null, then it means unlimited sites
  const noAvailableSites =
    typeof availableSites === 'number' && availableSites <= 0;

  resourceLimitMap.sites = { limitReached: noAvailableSites };

  // III. Check Database limit
  const noAvailableDB = db_limit && db_count && db_count >= db_limit;

  resourceLimitMap.db = {
    limitReached: !isAtomic && (noAvailableDB as boolean),
  };

  // IV. Cloud Hosting specific check - PHP workers limit
  const noAvailableWorkers =
    typeof workers?.available === 'number' && workers?.available < 2;

  resourceLimitMap.phpWorker = {
    limitReached: cloudHosting && noAvailableWorkers,
  };

  // V. Cloud Hosting specific check - Data Storage limit
  const noAvailableStorage =
    typeof storage?.available === 'number' && storage?.available < 1;

  resourceLimitMap.storage = {
    limitReached: cloudHosting && noAvailableStorage,
  };

  const handlingWarningModelLoadingState = isAtomic || !isLoading;

  if (
    handlingWarningModelLoadingState &&
    Object.keys(resourceLimitMap).filter(
      (resource) => resourceLimitMap[resource].limitReached,
    ).length > 0
  ) {
    setIsDBUsageLoading(false);
    return (
      <ResourcesWarningModal
        resourceLimitMap={resourceLimitMap}
        open
        setOpen={handleCloseValidation}
      />
    );
  }
  // VI. All checks pass, go to add site flow
  if (isLoading && !isAtomic) {
    setIsDBUsageLoading(true);
    return null;
  }
  return <Navigate to="sites/add" />;
};

export default AddSiteValidation;
