import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingOnboardingDataQueryKey,
  useHostingOnboardingDataUpdate,
  useSitesInfo,
  useSitesSso,
} from '@newfold/huapi-js';
import { HostingOnboardingData200 } from '@newfold/huapi-js/src/index.schemas';

import InstallationModal from '~/components/InstallationModal';
import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';
import useFullStory from '~/hooks/useFullStory';
import useResponsive from '~/hooks/useResponsive';
import getBrandLogo from '~/utils/getBrandLogo';

import {
  ModalIconAiHelp,
  ModalIconWonderblocks,
  ModalIconWordpress,
} from './assets';

type FirstSiteInstallingPropOptions = {
  siteId?: string | number;
  onboarding?: boolean;
};

const FirstSiteInstalling = ({
  siteId = undefined,
  onboarding = undefined,
}: FirstSiteInstallingPropOptions) => {
  const { t } = useTranslation('site');
  const queryClient = useQueryClient();
  // @ts-expect-error
  const { brand } = useMFEContext();
  const { id: hostingId } = useAccount();
  const { event } = useFullStory();
  const { isDesktop, isMobile } = useResponsive();

  const [open, setOpen] = useState(true);

  const slides = [
    {
      heading: 'Your new WordPress site is installing!',
      body: 'While we work our magic, take a quick peek at some amazing new features we’ve brought to WordPress.',
      image: ModalIconWordpress,
    },
    {
      heading: 'Unleashing the Power of AI',
      body: 'No more scanning for answers.  Our new AI-powered Help Center provides a highly personalized and seamless  support experience inside of WordPress.',
      image: ModalIconAiHelp,
    },
    {
      heading: `Your ${brand} account is always with you`,
      body: `We’ve introduced effortless switching between managing your ${brand} account and WordPress site.`,
      image: getBrandLogo(brand)?.icon,
    },
    {
      heading: 'Building is a snap with WonderBlocks!',
      body: 'The WonderBlocks builder tool allows you to select from a library of pre-made layouts.  It’s as easy as drag, drop and save.',
      image: ModalIconWonderblocks,
    },
  ];

  const clearOnboarding = () => {
    hostingOnboardingDataUpdate({
      hostingId,
      data: { completed: true },
    });
    setOpen(false);
  };

  const {
    data: ssoData,
    refetch: getSSO,
    isError: isSSOError,
    isSuccess,
    isFetching: ssoLoading,
  } = useSitesSso(
    Number(siteId),
    {},
    {
      query: {
        enabled: false,
      },
    },
  );

  const refetchSiteCount = useRef(0);
  const refetchSiteLimit = 30;
  const siteInstallSlow = refetchSiteCount.current >= refetchSiteLimit;

  const { data, isInitialLoading, isError } = useSitesInfo(Number(siteId), {
    query: {
      enabled: !!siteId,
      staleTime: 0,
      refetchInterval: (data) =>
        !data || data?.data?.status === 'active' || siteInstallSlow ? 0 : 6000,
      onSuccess: (data) => {
        refetchSiteCount.current += 1;
        data?.data?.status === 'active' && getSSO();
        return data;
      },
    },
  });

  const siteStatus = data?.data?.status;
  const siteInstalling =
    (isInitialLoading || siteStatus !== 'active' || !isSuccess) &&
    refetchSiteCount.current < refetchSiteLimit &&
    !isError &&
    !isSSOError;

  const hostingQueryKey = getHostingOnboardingDataQueryKey(hostingId);
  const { mutate: hostingOnboardingDataUpdate } =
    useHostingOnboardingDataUpdate({
      mutation: {
        onMutate: () => {
          queryClient.setQueryData<AxiosResponse<HostingOnboardingData200>>(
            hostingQueryKey,
            (oldData) => ({
              ...oldData!,
              data: {
                ...oldData?.data!,
                onboarding: { ...oldData?.data, completed: true },
              },
            }),
          );
        },
        onSettled: async () => {
          queryClient.invalidateQueries(hostingQueryKey);
          const siteSsoHref = ssoData?.data?.sso;
          if (siteSsoHref) {
            await event('Hosting MFE - Onboarding - Redirected to WordPress', {
              halHostingId_str: String(hostingId),
              halSiteId_str: String(siteId),
            });
            window.open(siteSsoHref, '_blank', 'noopener,noreferrer');
          }
        },
      },
    });

  const getModalBottomBar = () => {
    if (siteInstalling) {
      return {
        node: (
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularProgress size={20} />
            <Typography fontWeight={600}>
              {t('firstSiteInstall.installing')}
            </Typography>
          </Stack>
        ),
      };
    }

    if (isError || isSSOError) {
      return {
        variant: 'error',
        node: (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <ErrorOutlineIcon color="error" />
            <Typography fontWeight={600}>
              {t('firstSiteInstall.genericError')}
            </Typography>
          </Stack>
        ),
      };
    }

    if (siteInstallSlow) {
      return {
        variant: 'warning',
        node: (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyItems="center"
          >
            <ErrorOutlineIcon color="error" />
            <Typography fontWeight={600}>
              {t('firstSiteInstall.installSlow')}
            </Typography>
          </Stack>
        ),
      };
    }

    if (isDesktop() && siteStatus === 'active' && isSuccess) {
      return {
        variant: 'success',
        node: (
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Typography fontWeight={600} textAlign="center">
              {isDesktop()
                ? t('firstSiteInstall.installComplete')
                : t('firstSiteInstall.installCompleteMobile')}
            </Typography>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={ssoLoading}
              disabled={ssoLoading || isSSOError}
              onClick={() => clearOnboarding()}
            >
              {t('loginToWordPress')}
            </LoadingButton>
          </Stack>
        ),
      };
    }
  };

  const siteReadyMobile = [
    {
      body: (
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <Typography fontWeight={600} textAlign="center">
            {isDesktop()
              ? t('firstSiteInstall.installComplete')
              : t('firstSiteInstall.installCompleteMobile')}
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={ssoLoading}
            disabled={ssoLoading || isSSOError}
            onClick={() => clearOnboarding()}
          >
            {t('mobileLoginWordPress')}
          </LoadingButton>
        </Stack>
      ),
      image: ModalIconWordpress,
    },
  ];

  const handleClose = () => {
    setOpen(false);
    clearOnboarding();
  };

  return (
    <InstallationModal
      open={open}
      showClose
      // @ts-expect-error
      bottomBar={getModalBottomBar()}
      // @ts-expect-error
      slides={
        siteStatus === 'active' && isSuccess && isMobile()
          ? siteReadyMobile
          : slides
      }
      circular={false}
      onClose={handleClose}
    />
  );
};

export default FirstSiteInstalling;
