import { Trans } from 'react-i18next';

import Chip from '@mui/material/Chip';

import { stepColors } from '../utils/stepData';

type StatusChipPropOptions = {
  // If the label is a key in domains:setupProgress.statusChips, the corresponding value is used
  // Otherwise, the label itself will be used
  label?: string;
  textColor?: string;
  backgroundColor?: string;
  icon?: JSX.Element;
  blank?: boolean;
  sx?: Object;
};

/* A Chip component designed for use in the Domain Progress component */
const StatusChip = ({
  label = '',
  textColor = stepColors.notStarted,
  backgroundColor = stepColors.notStarted,
  icon = <></>,
  blank = false,
  sx = {},
}: StatusChipPropOptions) => {
  const borderRadius = 2;
  const fontSize = 12;
  const size = 'small';

  // This is used to balance spacing in Steps where there is not yet a status
  if (blank)
    return (
      <Chip
        data-testid={`status-chip-blank`}
        size={size}
        sx={{
          visibility: 'hidden',
        }}
      />
    );

  return (
    <Chip
      data-testid={`status-chip-${label}`}
      size={size}
      label={
        <Trans
          i18nKey={`domains:setupProgress.statusChips.${label}`}
          defaults={label}
        />
      }
      icon={icon}
      sx={{
        borderRadius,
        fontSize,
        color: textColor,
        backgroundColor: backgroundColor,
        ...sx,
      }}
    />
  );
};

export default StatusChip;
