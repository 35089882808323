import { PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

// HighlightedBox expects prop bgColor: 'warning', 'success', 'error', 'info', and 'secondary'.
// If no prop is given the component will default to 'secondary'.
const HighlightedBox = ({
  children = undefined,
  testId = 'highlighted-box',
  bgColor = 'secondary',
}: PropsWithChildren<{
  testId?: string;
  bgColor?: 'primary' | 'warning' | 'success' | 'error' | 'info' | 'secondary';
}>) => (
  <Box
    data-testid={testId}
    sx={{
      bgcolor: (theme: Theme) =>
        bgColor
          ? theme.palette[bgColor][
              theme.palette.mode === 'dark' ? 'dark' : 'light'
            ]
          : undefined,
      p: 1,
      borderRadius: 1,
      alignSelf: 'start',
      justifySelf: 'start',
    }}
  >
    {children}
  </Box>
);

export default HighlightedBox;
