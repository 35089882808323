// import TabsContent from "./components/Tabs";
//@ts-nocheck
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Backup from '@mui/icons-material/Backup';
import Brush from '@mui/icons-material/Brush';
import Cached from '@mui/icons-material/Cached';
import CircleRounded from '@mui/icons-material/CircleRounded';
import ContentCopy from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import Power from '@mui/icons-material/Power';
import Sensors from '@mui/icons-material/Sensors';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import useTheme from '@mui/material/styles/useTheme';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  getListCronsQueryKey,
  useSiteMalwareStatus,
  useSitePluginsToggle,
  useSitesDomain,
  useSitesSecuritySslStatus,
} from '@newfold/huapi-js';

import useAlertStore from '~/components/Alerts/alertsStore';
import Copy from '~/components/Copy';
import { useNewfoldBrandTheme } from '~/components/MFEProvider/lib/MUI/themeDefault';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useResponsive from '~/hooks/useResponsive';
import AllocationText from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/components/AllocationText';
import { PhpWorkersTooltip } from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/components/PhpWorkersTooltip';
import SiteCardScan from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/scenes/Sites/components/SiteCardScan';
import { useSiteContext } from '~/scenes/Site';
import StatusChip from '~/scenes/Site/scenes/Cloud/components/StatusChip';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import getSiteUrlWithoutProtocol from '~/utils/getSiteUrlWithoutProtocol';

import SiteThumbnail from '../../Overview/components/SiteThumbnail';

export default function Overview() {
  const { t } = useTranslation('site');
  const { isMobile } = useResponsive();
  const themeDark = useNewfoldBrandTheme('dark');
  const refetchInterval = 60000;
  const { siteId } = useParams();
  const { id: hostingId } = useAccount();
  const queryClient = useQueryClient();
  const queryKey = getListCronsQueryKey(hostingId);
  const [, { generateAlert }] = useAlertStore();
  const [isPollingScan, setIsPollingScan] = useState(false);
  const { phoneNumberFormatted } = useBrandInfo();
  // GET: SSL status
  const {
    data,
    isError,
    isLoading: isSslStatusLoading,
  } = useSitesSecuritySslStatus(
    siteId,
    {},
    {
      query: { enabled: !!siteId },
    },
  );

  const siteSSLStatus = data?.data?.ssl_status;

  const {
    data: site,
    isLoading,
    isHostingDisabled,
    isSiteOrHostingDisabled: isDisabled,
  } = useSiteContext();

  const siteStatus = site?.status;
  const storageUsed = site?.resources?.storage?.used;
  const storageTotal = site?.resources?.storage?.total;
  const storageUnit = site?.resources?.storage?.unit;
  const phpWorkers = site?.resources?.workers;
  const datacenter = site?.datacenter;
  const wordpressVersion = site?.version;
  const phpVersion = site?.php_version;
  const siteUrl = site?.pending_url ? site?.pending_url : site?.url;
  const urlIsProvisional = site?.url_is_provisional;
  const pendingUrl = site?.pending_url_data?.domain;
  const { data: dnsStatus } = useSitesDomain(
    siteId,
    {},
    {
      query: {
        staleTime: 0,
        retry: 5,
        retryDelay: (attempt) => attempt * 15000, // add 15 seconds per retry
        enabled: !!siteId && !!pendingUrl,
        select: ({ data }) => ({
          aMatch: data?.a_match,
        }),
      },
    },
  );

  // As per requirement we refetching to check malware status for a minute if scaniing is in
  const {
    data: malwareScanStatus,
    isLoading: isMalwareScanStatusLoading,
    isError: isMalwareScanFailed,
  } = useSiteMalwareStatus(siteId, {
    query: {
      refetchInterval: (data: any) => {
        return isPollingScan ? refetchInterval : false;
      },
      onSuccess: (data) => {
        setIsPollingScan(data?.data?.scanner_state === 'scanning');
        return data;
      },

      onError: (error) => {
        setIsMalwareToggle(false);
        generateAlert({
          severity: 'error',
          description: (
            <Trans
              i18nKey={t('cloud.malwareError')}
              values={{ phoneNumber: phoneNumberFormatted }}
            />
          ),
          showCloseBtn: true,
          persist: false,
        });
      },
    },
  });

  //Activation Toggle
  const { mutate: pluginToggle, isLoading: isToggleActivating } =
    useSitePluginsToggle({
      mutation: {
        onSuccess: (data) => {
          if (data?.status === 200) {
            setIsMalwareToggle(isMalwareToggle);
            generateAlert({
              severity: 'success',
              description: t('cloud.toggleSuccess', {
                activated: isMalwareToggle
                  ? t('cloud.activated')
                  : t('cloud.deactivated'),
              }),
              showCloseBtn: true,
              persist: false,
            });
          }
          // TODO: handle error or invalid response
        },
        onError: ({ response }) => {
          setIsMalwareToggle(false);
          generateAlert({
            severity: 'error',
            description: t('cloud.toggleFailure'),
            showCloseBtn: true,
            persist: false,
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries(queryKey);
        },
      },
    });

  const malwareStatus = malwareScanStatus?.data.malware_status;
  const scannerState = malwareScanStatus?.data.scanner_state;
  const showToolTip = malwareStatus === 'off' || isMalwareScanFailed;
  const aMatch = dnsStatus?.aMatch;

  const [isMalwareToggle, setIsMalwareToggle] = useState(false);
  const { palette } = useTheme();

  const white = palette?.common?.white;

  const siteUrlWithoutProtocol = getSiteUrlWithoutProtocol(siteUrl);

  const handleMalwareStatus = async () => {
    if (scannerState !== 'unavailable') {
      setIsMalwareToggle(!isMalwareToggle);
      pluginToggle({
        siteId: siteId,
        data: {
          id: 'jetpack-protect',
          activate: !isMalwareToggle,
        },
      });
    } else {
      generateAlert({
        severity: 'error',
        description: t('pluginUnavailble'),
        showCloseBtn: true,
        persist: false,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Overview */}
        <ThemeProvider theme={themeDark}>
          <Card raised={false}>
            <CardContent sx={{ p: 3 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <SiteThumbnail siteInfo={site} isLoadingSite={isLoading} />

                <Stack spacing={1} flex={1} justifyContent={'space-around'}>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                  >
                    <div>
                      <Stack spacing={1}>
                        <Typography variant="h2">
                          <b>{site?.name || <Skeleton width={120} />}</b>
                        </Typography>

                        {siteUrl && (
                          <Copy
                            copyValue={siteUrl}
                            icon={
                              <ContentCopy
                                sx={{
                                  color: white,
                                  fontSize: '1rem',
                                }}
                              />
                            }
                          >
                            <Link
                              variant="body1"
                              href={siteUrl}
                              color={white}
                              target="_blank"
                            >
                              {siteUrlWithoutProtocol}
                            </Link>
                          </Copy>
                        )}

                        {/* There is no custom URL, yet  */}
                        {!pendingUrl ? (
                          <>
                            {urlIsProvisional &&
                              (isLoading ? (
                                <Skeleton width={120} />
                              ) : (
                                <Typography variant="body2">
                                  <Link
                                    href={`./domains`}
                                    disabled={isDisabled}
                                    color={white}
                                  >
                                    {t('cloud.connectDomain')}
                                  </Link>
                                </Typography>
                              ))}
                          </>
                        ) : (
                          // Custom URL set
                          <>
                            {/* Custom URL matches */}
                            {aMatch ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems={'center'}
                              >
                                <CircularProgress
                                  size={14}
                                  sx={{ color: white }}
                                />
                                <Typography variant="body2" color={white}>
                                  {t('urlCta.updateInProgressText')}
                                </Typography>
                              </Stack>
                            ) : (
                              // Custom URL does not match
                              <Link
                                href={`domains/${pendingUrl}/dns`}
                                disabled={isDisabled}
                                variant="body2"
                                color={white}
                              >
                                {t('urlCta.pendingUrlText')}
                              </Link>
                            )}
                          </>
                        )}
                      </Stack>
                      {/* end url section */}
                    </div>

                    <Stack spacing={1} direction={'row'}>
                      {!isError && !isSslStatusLoading && (
                        <StatusChip type="ssl" status={siteSSLStatus} />
                      )}
                      {!isLoading && <StatusChip status={siteStatus} />}
                      {scannerState === 'scanning' &&
                      !isMalwareScanFailed &&
                      !isMalwareScanStatusLoading ? (
                        <Chip
                          label={'Scanning'}
                          variant="outlined"
                          sx={{
                            paddingLeft: '10px',
                            fontWeight: 'bold',
                          }}
                          icon={<CircularProgress size={20} />}
                        />
                      ) : !isMalwareScanStatusLoading &&
                        (isMalwareScanFailed || malwareStatus === 'off') ? (
                        <Typography variant="h4" component="span">
                          {t('cloud.malwareScan')}
                          {isToggleActivating && (
                            <CircularProgress
                              size={16}
                              sx={{ color: white, marginLeft: '7px' }}
                            />
                          )}
                          <Switch
                            checked={isMalwareToggle}
                            sx={{
                              '& .MuiSwitch-thumb': {
                                backgroundColor: white,
                              },
                              '& .Mui-checked.Mui-checked +.MuiSwitch-track': {
                                backgroundColor: '#196bde',
                              },
                            }}
                            disabled={
                              isMalwareScanFailed || malwareStatus === 'off'
                            }
                            onChange={handleMalwareStatus}
                          />
                        </Typography>
                      ) : (
                        !isMalwareScanStatusLoading && (
                          <Chip
                            label={t('cloud.malwareScan')}
                            variant="outlined"
                            color={
                              malwareStatus === 'red' ? 'error' : 'default'
                            }
                            sx={{
                              paddingLeft: '10px',
                              background:
                                malwareStatus === 'red' ? 'white' : '',
                            }}
                            icon={
                              <CircleRounded
                                sx={{ fontSize: 12, fontWeight: 'medium' }}
                                color={
                                  malwareStatus === 'green'
                                    ? 'success'
                                    : 'error'
                                }
                              />
                            }
                          />
                        )
                      )}
                      {showToolTip && (
                        <Tooltip
                          title={t('cloud.malwareUnavailble')}
                          arrow
                          size="small"
                          placement="top"
                          data-testid="tooltip"
                          sx={{
                            height: '36px',
                            left: '-18px',
                          }}
                        >
                          <IconButton>
                            <InfoIcon sx={{ color: 'grey.400' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>

                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={1}
                    justifyContent={{ xs: undefined, md: 'space-between' }}
                  >
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Typography variant="h4" component="span">
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <WordPressIcon color="white" />
                          <span>{t('cloud.wordPress')}</span>{' '}
                          <span>
                            <b>{wordpressVersion ? wordpressVersion : 'N/A'}</b>
                          </span>
                        </Stack>
                      </Typography>

                      <Typography variant="h4" component="span">
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <span>{t('cloud.php')}</span>{' '}
                          <span>
                            <b>{phpVersion ? phpVersion : 'N/A'} </b>
                          </span>
                        </Stack>
                      </Typography>
                    </Stack>

                    <Typography
                      variant="body2"
                      sx={{ alignSelf: { xs: undefined, md: 'flex-end' } }}
                      component="span"
                    >
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <span>{t('cloud.dataCenter')}</span> <Sensors />{' '}
                        <span className="location">
                          <span>
                            <b>{datacenter ? datacenter : 'N/A'}</b>
                          </span>
                        </span>
                      </Stack>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </ThemeProvider>
      </Grid>

      {/* - Updates */}
      <Grid item xs={12} md={9}>
        <Card>
          <CardHeader
            title={t('cloud.updates')}
            subheader={
              false && (
                <Typography variant="body2">
                  <i>
                    {t('cloud.lastUpdate')}
                    <Skeleton
                      width={180}
                      sx={{ mx: 1, display: 'inline-block' }}
                    />
                    {/* Jan 04, 2024 11:49 AM CST */}
                  </i>{' '}
                  <Cached />
                </Typography>
              )
            }
          />
          <CardContent>
            <Card variant="outlined">
              <CardContent>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  justifyContent={'space-evenly'}
                  divider={
                    <Divider
                      flexItem
                      orientation={isMobile() ? 'horizontal' : 'vertical'}
                    />
                  }
                  gap={{ xs: 3, sm: 1 }}
                >
                  {isMalwareScanStatusLoading && (
                    <Skeleton
                      width={180}
                      sx={{ mx: 1, display: 'inline-block' }}
                    />
                  )}
                  {!isMalwareScanStatusLoading && (
                    <SiteCardScan status={malwareScanStatus?.data} />
                  )}

                  <Stack spacing={1} alignItems={'center'}>
                    <Backup />
                    <Typography variant="h3">
                      {t('cloud.latestBackups')}
                    </Typography>
                    <Typography variant="body2">
                      <Link href={`./backups`} disabled={isDisabled}>
                        {t('cloud.viewBackups')}
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack spacing={1} alignItems={'center'}>
                    <Power />
                    <Typography variant="h3">{t('cloud.plugins')}</Typography>
                    <Typography variant="body2">
                      <SsoLink
                        type="site"
                        variant="textLink"
                        disabled={!siteId || isDisabled}
                        queryParams={{ app: 'plugins' }}
                      >
                        {t('cloud.pluginsAction')}
                      </SsoLink>
                    </Typography>
                  </Stack>

                  <Stack spacing={1} alignItems={'center'}>
                    <Brush />
                    <Typography variant="h3">{t('cloud.themes')}</Typography>
                    <Typography variant="body2">
                      <SsoLink
                        type="site"
                        variant="textLink"
                        disabled={!siteId || isDisabled}
                        queryParams={{ app: 'themes' }}
                      >
                        {t('cloud.viewThemes')}
                      </SsoLink>
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>

      {/* - Resources */}
      <Grid item xs={12} md={3}>
        <Stack spacing={2}>
          <Card sx={{ backgroundColor: '#E4ECF0' }} variant="outline">
            <CardActionArea href={`./performance`} disabled={isHostingDisabled}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="h4" noWrap>
                        {t('cloud.phpWorkers.title')}
                      </Typography>
                      <PhpWorkersTooltip
                        arrow
                        placement="top-start"
                        size="small"
                      />
                    </Stack>

                    {isLoading ? (
                      <Skeleton sx={{ display: 'inline-block' }} width={70} />
                    ) : (
                      <>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="h1">
                            {phpWorkers ? phpWorkers : '-'}
                          </Typography>
                          <Typography variant="h5">
                            {t('cloud.phpWorkers.allocated')}
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </Stack>
                  <ArrowForwardIosIcon
                    sx={{ cursor: 'pointer', alignContent: 'right' }}
                    data-testid="card-header"
                    color="action"
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ backgroundColor: '#E4ECF0' }} variant="outline">
            <CardActionArea href={`./settings`} disabled={isHostingDisabled}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="h4" noWrap>
                        {t('cloud.storageUsed')}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                      {storageUsed && (
                        <Typography variant="h1">
                          {Math.round((storageUsed / storageTotal) * 100)}%
                        </Typography>
                      )}
                      <AllocationText
                        sx={{
                          fontSize: '12px',
                        }}
                        isLoading={isLoading}
                        used={storageUsed}
                        total={storageTotal}
                        unit={storageUnit}
                      />
                    </Stack>
                  </Stack>
                  <ArrowForwardIosIcon
                    sx={{ cursor: 'pointer', alignContent: 'right' }}
                    color="action"
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
