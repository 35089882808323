// Clean up component comments, commented out code, and sx props
// import {} from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { useSitesInfo } from '@newfold/huapi-js';

import OverviewCard from './components/OverviewCard';
import QuickConnect from './components/QuickConnect';
import QuickLinks from './components/QuickLinks';

//import UsageStats from '../../../Hosting/scenes/HostingDetail/components/UsageStats';
//import AdvancedServerInfo from '../../../Hosting/scenes/HostingDetail/components/AdvancedServerInfo';

const Overview = () => {
  const { siteId } = useParams();

  const { isInitialLoading, isError, data } = useSitesInfo(Number(siteId));

  // TODO: flatten this
  const siteInfo = data?.data;

  return (
    <>
      <Box sx={{ transition: '2s' }}>
        {!isError && (
          <Grid container spacing={3} columns={12} data-testid="site-overview">
            <Grid item xs={12} className="xyz-nested">
              <OverviewCard loading={isInitialLoading} siteInfo={siteInfo} />
            </Grid>

            {/* Dummy Component added for alignment with just the headers */}
            <Grid item xs={12} md={8} className="xyz-nested">
              <Card>
                <CardContent>
                  <QuickConnect />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} className="xyz-nested">
              <QuickLinks />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Overview;
