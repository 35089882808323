import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Storage from '@mui/icons-material/Storage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesDatabaseInfoQueryKey,
  useSitesDatabaseInfo,
  useSitesDatabasePasswordsync,
  useSitesInfo,
} from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import { isDevMode } from '~/components/DevMode';
import SsoLink from '~/components/SsoLink';
import useResponsive from '~/hooks/useResponsive';
import { useSiteContext } from '~/scenes/Site';

import ResetDBPassword from './ResetDBPassword';

export default function Database() {
  const [openModal, setOpenModal] = useState(false);
  const [, { generateAlert }] = useAlerts();
  const { t } = useTranslation('cloud', { keyPrefix: 'databases' });
  const { siteId } = useParams();
  const { isMobile } = useResponsive();
  const queryClient = useQueryClient();

  const viewIsMobile = isMobile();

  const { data: database, isLoading: siteInfoLoading } = useSitesInfo(
    Number(siteId),
    {
      query: {
        enabled: !!siteId,
        select: ({ data }) => {
          const installedId = data?.installed_id;
          const name = installedId?.substring(0, installedId?.indexOf('/'));
          const prefix = installedId?.slice(installedId?.indexOf('/') + 1);
          return { name, prefix };
        },
      },
    },
  );

  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const { data: databaseInfo } = useSitesDatabaseInfo(Number(siteId), {
    query: {
      enabled: !!siteId && isDevMode,
      select: ({ data }) => data,
    },
  });

  const charset = databaseInfo?.charset;
  const collation = databaseInfo?.collation;

  const handleClose = () => setOpenModal(false);

  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: descKey,
      showCloseBtn: true,
    });
  };

  const handleContinue = () => {
    handlePasswordSync({
      siteId: Number(siteId),
    });
    handleClose();
  };

  const siteDatabaseQueryKey = getSitesDatabaseInfoQueryKey(Number(siteId));
  const { mutate: handlePasswordSync, isLoading: passwordSyncLoading } =
    useSitesDatabasePasswordsync({
      mutation: {
        onSuccess: ({ data }) => {
          if (data?.success || Object.keys(data).length === 0) {
            return setAlertMessage('success', t('resync.successMsg'));
          }
        },
        onError: () => {
          setAlertMessage('error', t('resync.errorMsg'));
        },
        onSettled: () => {
          queryClient.invalidateQueries(siteDatabaseQueryKey);
        },
      },
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={t('title')}
            action={
              <SsoLink
                type="site"
                variant="contained"
                queryParams={{ app: 'phpmyadmin' }}
                // @ts-expect-error
                startIcon={<Storage />}
              >
                {t(viewIsMobile ? 'launchBtnMobile' : 'launchBtn')}
              </SsoLink>
            }
            sx={{ mr: 1 }}
          />
          <CardContent>
            <Stack spacing={2} alignItems={'flex-start'}>
              {siteInfoLoading ? (
                <>
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </>
              ) : (
                <>
                  {[
                    {
                      key: 'databaseName',
                      value: database?.name,
                      show: true,
                    },
                    {
                      key: 'prefix',
                      value: database?.prefix,
                      show: true,
                    },
                    {
                      key: 'charset',
                      value: charset,
                      show: isDevMode,
                    },
                    {
                      key: 'collation',
                      value: collation,
                      show: isDevMode,
                    },
                  ].map(
                    ({ value, show, key }) =>
                      show && (
                        <Typography variant="body1">
                          <Trans
                            i18nKey={t(key)}
                            values={{ value }}
                            components={{
                              Value: <span style={{ fontWeight: 600 }} />,
                            }}
                          />
                        </Typography>
                      ),
                  )}
                </>
              )}
              <Link
                component="button"
                onClick={() => !isDisabled && setOpenModal(true)}
                color="inherit"
                disabled={isDisabled}
              >
                {t('resetBtn')}
                {passwordSyncLoading && (
                  <CircularProgress size={15} sx={{ ml: 1 }} />
                )}
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <ResetDBPassword
        open={openModal}
        handleClose={handleClose}
        handleContinue={handleContinue}
        isDisabled={isDisabled}
      />
    </Grid>
  );
}
