// import StorageIcon from '@mui/icons-material/Storage';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { getSitesInfoQueryKey } from '@newfold/huapi-js';
import { HostingSitesV2200ItemsItem } from '@newfold/huapi-js/src/index.schemas';

import { invalidateMultipleQueries } from '~/components/MFEProvider/lib/HttpClient/queryClient';
import SearchBar from '~/components/VisionUI/SearchBar';
import useAccount from '~/hooks/useAccount';
import useFilteredHostingSites from '~/hooks/useFilteredHostingSites';
import useRefetchInterval from '~/hooks/useRefetchInterval';
import AddSiteButton from '~/scenes/Hosting/components/AddSiteButton';
import getListBySearch from '~/utils/getListBySearch';
import paginate from '~/utils/paginate';

import SiteCard from './components/SiteCard';
import SitesEmpty from './components/SitesEmpty';
import SitesLoading from './components/SitesLoading';
import SitesNotFound from './components/SitesNotFound';

const Sites = () => {
  const { t } = useTranslation('sites');
  const addSiteBtnTranslation = t('cloud.addSiteBtn', {
    wp: `${t('platform.wordpress')} `,
  });
  const queryClient = useQueryClient();

  const { id: hostingId } = useAccount();
  const { refetchTime } = useRefetchInterval();

  const [pendingSiteQueries, setPendingSiteQueries] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);

  const { data: hostingSites, isLoading } = useFilteredHostingSites(hostingId, {
    query: {
      enabled: !!hostingId,
      refetchInterval: (data: any) => {
        const sites = data?.data?.items;
        const keepPolling: any = sites?.some((i: any) => i.status !== 'active');
        return keepPolling && refetchTime;
      },
      refetchIntervalInBackground: true,
      onSuccess: async (data: any) => {
        const sites = data?.data?.items;
        const pendingSites = sites?.filter((i: any) => i.status !== 'active');
        const hasPendingSites = pendingSites?.length > 0;
        const hasPendingSiteQueries = pendingSiteQueries?.length > 0;
        const donePolling = !hasPendingSites && hasPendingSiteQueries;

        // Track sites that are pending, once they become active,
        // invalidate the site info call(s) to keep cache in sync
        if (hasPendingSites) {
          pendingSites?.forEach((site: HostingSitesV2200ItemsItem) => {
            const siteQueryKey = getSitesInfoQueryKey(Number(site?.id));
            const queryKeys = hasPendingSiteQueries
              ? [pendingSiteQueries, siteQueryKey]
              : [siteQueryKey];
            setPendingSiteQueries(queryKeys);
          });
        }
        if (donePolling) {
          setPendingSiteQueries([]);
          await invalidateMultipleQueries(queryClient, pendingSiteQueries);
        }
        return data;
      },
    },
  });

  const sitesList = hostingSites?.data?.items || [];

  const searchBy = ['name', 'url', 'pending_url'];
  const searchedSites = getListBySearch(sitesList, searchQuery, searchBy);
  const paginatedList = paginate(searchedSites, 10);
  const filteredSitesList = paginatedList?.[page - 1] || [];

  const noFilteredSites = filteredSitesList?.length < 1;
  const noSites = sitesList?.length < 1;
  const showPagination = paginatedList?.length > 1;

  const handleSearch = (value: string) => {
    setPage(1);
    setSearchQuery(value);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <Card>
      <CardHeader
        title={t('cloud.header')}
        action={
          <AddSiteButton isLoading={isLoading}>
            {addSiteBtnTranslation}
          </AddSiteButton>
        }
        direction={{ xs: 'column', sm: 'row' }}
      />
      <CardContent>
        <Stack spacing={2}>
          <SearchBar
            searchQuery={searchQuery}
            placeholder={t('searchSites')}
            handleSearch={handleSearch}
            inputProps={{ sx: { width: { xs: '100%', sm: 400 } } }}
          />

          {isLoading && <SitesLoading />}

          {/* 0 sites */}
          {!isLoading && noSites && !noFilteredSites && <SitesEmpty />}

          {/* 0 sites found after search */}
          {!isLoading && (noSites || noFilteredSites) && <SitesNotFound />}

          {/* 1+ sites */}
          {!isLoading &&
            filteredSitesList &&
            Array.isArray(filteredSitesList) &&
            filteredSitesList.length > 0 &&
            filteredSitesList.map((site: HostingSitesV2200ItemsItem) => (
              <SiteCard site={site} key={site.id} />
            ))}
        </Stack>
      </CardContent>
      <CardContent>
        {showPagination && (
          <Pagination
            shape="rounded"
            count={paginatedList?.length}
            page={page}
            onChange={handlePaginationChange}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Sites;
