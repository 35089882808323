import { SiteUsers200UsersItem } from '@newfold/huapi-js/src/index.schemas';

export const filterByRole =
  (dropdownFilter: string) =>
  ({ role }: SiteUsers200UsersItem) => {
    if (dropdownFilter) {
      return role.toLowerCase() === dropdownFilter.toLowerCase();
    }
    return true;
  };

export const filterByNameEmailRole =
  (textFilter: string) =>
  ({ display_name, user_email, role }: SiteUsers200UsersItem) => {
    if (textFilter) {
      return Object.values({ display_name, user_email, role })
        .join(' ')
        .toLowerCase()
        .includes(textFilter.toLowerCase());
    }
    return true;
  };
