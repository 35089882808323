import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesSshUserListV2QueryKey,
  useAccountPublicKeyDelete,
  useSitesSshUserDelete,
} from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';

type RemoveUserPropOptions = {
  siteId?: number;
};

const RemoveUser = ({ siteId = undefined }: RemoveUserPropOptions) => {
  const { t } = useTranslation('sshUserManager');

  const location = useLocation();
  const { userName, publicKeyId, isGlobal } = location.state;

  const navigate = useNavigate();
  const generateAlert = useAlert();

  const queryClient = useQueryClient();
  const queryKey = getSitesSshUserListV2QueryKey(siteId!);

  const handleDeleteSSHMutation = () => {
    return {
      onSuccess: async () => {
        await queryClient.cancelQueries(queryKey);
        const previousKeysData: any = queryClient.getQueryData(queryKey);

        const removeKey = previousKeysData.data.users.filter(
          (item: any) => item !== userName,
        );

        queryClient.setQueryData(queryKey, (oldKeysData: any) => {
          return {
            ...oldKeysData,
            data: { ...oldKeysData.data, rows: [...removeKey] },
          };
        });
        return { previousKeysData };
      },
      onError: (error: any, _key: any, context: any) => {
        queryClient.setQueryData(queryKey, context.previousKeysData);
        generateAlert({
          severity: 'error',
          description:
            error?.response?.data?.error === 'sshKeyExists'
              ? t('errorExistingKeyNameToast')
              : t('removeSSHUSER.removeUserError'),
          showCloseBtn: true,
          persist: false,
        });
        navigate('..');
      },
      onSettled: (data: any) => {
        if (data?.status === 200) {
          generateAlert({
            severity: 'success',
            description: t('removeSSHUSER.success'),
            showCloseBtn: true,
            persist: false,
          });
          navigate('..');
        }
        queryClient.invalidateQueries(queryKey);
      },
    };
  };

  const { mutate: deleteSshUser, isLoading: isDeleteLoading } =
    useSitesSshUserDelete({ mutation: handleDeleteSSHMutation() });

  // TODO:Future story created to handle this in useSitesSshUserDelete in HUAPI.
  // Once the backend updated , this call no longer needed.
  // Remove the abstraction once HUAPI changes are made.
  const { mutate: deleteGlobalSshUser, isLoading: isDeleteGlobalSshLoading } =
    useAccountPublicKeyDelete({
      mutation: handleDeleteSSHMutation(),
    });

  const handleDelete = () => {
    if (isGlobal) {
      deleteGlobalSshUser({ publicKeyId });
    } else {
      deleteSshUser({
        siteId: siteId!,
        data: {
          ssh_user: userName,
        },
      });
    }
  };

  return (
    <Dialog open onClose={() => navigate('..')}>
      <DialogTitle>
        <Stack direction="row" justifyContent="left" spacing={1}>
          <Typography variant="h2" color="error">
            {t('removeSSHUSER.title')}
          </Typography>
          <Typography variant="h2" color="error">
            {userName.toUpperCase()}
          </Typography>
        </Stack>
      </DialogTitle>
      {isGlobal ? (
        <DialogContent>
          <DialogContentText>
            {t('removeSSHUSER.globalSSHUserdesc')}
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>{t('removeSSHUSER.desc')}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button href=".." sx={{ mr: 1 }}>
          {t('removeSSHUSER.cancelTxt')}
        </Button>
        <LoadingButton
          loading={isDeleteLoading || isDeleteGlobalSshLoading}
          variant="contained"
          onClick={handleDelete}
          color="error"
        >
          {t('removeSSHUSER.removeUser')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUser;
