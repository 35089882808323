/*
  Choice

    This component is used by MultipleChoiceModalDialog to display an single choice
    in the list displayed by the dialog.  It has no state.

  Properties:

    title: The name of the choice.
    description: A detailed description of the choice. May be a blank string.
    onClick: A function to call when the choice is clicked. MultipleChoiceModalDialog uses this to update
      the style when a click occurs and also to know which choice is selected.
    variant: The variant to use for the card.  Should be either "selected" or "outlined".

  State:

    None.

  */
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type ChoicePropOptions = {
  title: string;
  description: string;
  variant: 'elevation' | 'outlined' | 'selected';
  onClick: VoidFunction;
};

const Choice = ({
  title,
  description,
  onClick,
  variant,
  ...props
}: ChoicePropOptions) => {
  return (
    <Card variant={variant} {...props} onClick={onClick}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Choice;
