//TODO Code clean needed, will be handled in another story.

const PASSWORD =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const validation = (value: string) => {
  return PASSWORD.test(value);
};

export const onValidUsername = (val: string) => {
  const USERNAME = /^[a-zA-Z0-9]+$/;
  return USERNAME.test(val);
};

export const onValidUpperCase = (value: string) => {
  const UPPERCASE = /[A-Z]/;
  if (UPPERCASE.test(value)) {
    return {
      isUpperCaseValid: UPPERCASE.test(value),
    };
  }
  return {
    isUpperCaseValid: false,
  };
};

export const onValidSpecialCase = (value: string) => {
  const SPECIAL_CHARACTERS = /[!@#$%^&*()]/;

  if (SPECIAL_CHARACTERS.test(value)) {
    return {
      isSpecialCaseValid: SPECIAL_CHARACTERS.test(value),
    };
  }
  return {
    isSpecialCaseValid: false,
  };
};

export const onValidNumberCase = (value: string) => {
  const NUMERIC = /[0-9]/;
  if (NUMERIC.test(value)) {
    return {
      isNumberCaseValid: NUMERIC.test(value),
    };
  }
  return {
    isNumberCaseValid: false,
  };
};

export const onValidLengthCase = (value: string) => {
  const MIN_LENGTH = /^.{8,}$/;

  if (MIN_LENGTH.test(value)) {
    return {
      isTextLengthCaseValid: MIN_LENGTH.test(value),
    };
  }

  return {
    isTextLengthCaseValid: false,
  };
};
