import { XyzTransition } from '@animxyz/react';
import { ReactNode, useEffect, useState } from 'react';
// @ts-expect-error
import SwipeableViews from 'react-swipeable-views';
// @ts-expect-error
import { autoPlay } from 'react-swipeable-views-utils';

import ArrowForward from '@mui/icons-material/ArrowForward';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import green from '@mui/material/colors/green';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useResponsive from '~/hooks/useResponsive';

// @ts-ignore
import HeaderImg from './assets/InstallModalHeader.svg';

type InstallationModalPropOptions = {
  slides?: {
    heading: string;
    body: string | ReactNode;
    image: string;
  }[];
  autoAdvance?: boolean;
  autoAdvanceDuration?: number;
  open?: boolean;
  showClose?: boolean;
  circular?: boolean;
  bottomBar?: {
    node: ReactNode;
    variant?: 'success' | 'warning' | 'error' | 'primary';
  };
  noHeaderImage?: boolean;
  onClose?: VoidFunction;
};

const fabSx = {
  height: '3em',
  width: '3em',
  borderRadius: 500,
  bgcolor: 'secondary.dark',
  color: 'white',
  border: 'none',
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const InstallationModal = ({
  slides = undefined,
  open = false,
  showClose = true,
  circular = true,
  autoAdvance = false,
  autoAdvanceDuration = 5000,
  bottomBar = undefined,
  noHeaderImage = undefined,
  onClose = undefined,
}: InstallationModalPropOptions) => {
  const { isDesktop } = useResponsive();

  const [currentStep, setCurrentStep] = useState(0);

  const imgHeaderMax = 630;
  const stepCount = +slides?.length!;

  const advanceStep = () => {
    if (!circular && currentStep + 1 >= stepCount) return;
    setCurrentStep((prevCurrentStep) =>
      prevCurrentStep + 1 >= stepCount! ? 0 : prevCurrentStep + 1,
    );
  };

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const bottomBarBg = () => {
    switch (bottomBar?.variant) {
      case 'warning':
        return 'warning.light';
      case 'error':
        return 'error.light';
      case 'success':
        return green['A100'];
      default:
        return 'secondary.light';
    }
  };

  const CloseButton = () => (
    <IconButton
      data-testid="close-btn"
      aria-label="close"
      onClick={onClose}
      sx={[
        {
          position: 'absolute',
          right: 8,
          top: 8,
          bgcolor: (theme) => theme.palette.grey[100],
          opacity: '.8',
          borderRadius: 600,
          height: 30,
          width: 30,
          fontSize: 11,
          zIndex: (theme) => theme.zIndex.modal,
        },
        {
          '&:hover': {
            boxShadow: (theme) => `0 3px 12px ${theme.palette.grey[500]}`,
            bgcolor: (theme) => theme.palette.grey[100],
            transition: '.2s ease',
          },
        },
      ]}
    >
      <CloseIcon />
    </IconButton>
  );
  useEffect(() => {
    stepCount === 1 && setCurrentStep(0);
  }, [stepCount]);
  return (
    <Dialog
      data-testid="installation-modal"
      open={open}
      keepMounted
      PaperProps={{ sx: { borderRadius: '16px' } }}
    >
      <Box
        sx={{
          bgcolor: bottomBarBg(),
          transition: '1s',
        }}
      >
        {showClose && <CloseButton />}
        {!noHeaderImage && (
          <CardMedia
            component="img"
            alt=" "
            height="120"
            image={HeaderImg}
            sx={{
              transition: '1s ease-in-out',
              objectPosition: -(imgHeaderMax / stepCount) * currentStep,
            }}
          />
        )}
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '0 0 16px 16px',
            marginTop: !noHeaderImage ? '-120px' : 0,
          }}
        >
          <CardContent sx={{ px: 0 }} data-testid="swipe-section">
            <AutoPlaySwipeableViews
              autoplay={autoAdvance}
              axis={'x'}
              index={currentStep}
              onChangeIndex={handleStepChange}
              interval={autoAdvanceDuration}
              enableMouseEvents
              springConfig={{
                duration: '1s',
                easeFunction: 'ease-in-out',
                delay: '0s',
              }}
              disabled
            >
              {slides?.map((step, index) => (
                <Stack
                  sx={{ px: isDesktop() ? 6 : 3 }}
                  key={`slide-${index + 1}`}
                  direction="column"
                  spacing={3}
                  data-testid={`slide-${index + 1}`}
                >
                  <Box
                    data-testid={`slide-image-${index + 1}`}
                    component="img"
                    sx={{
                      display: 'block',
                      margin: '0 auto',
                      maxWidth: 100,
                      overflow: 'hidden',
                    }}
                    src={step.image}
                  />
                  <Stack spacing={1.25}>
                    <Typography data-testid={`slide-heading-${index + 1}`}>
                      {step.heading}
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid={`slide-body-${index + 1}`}
                    >
                      {step.body}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </AutoPlaySwipeableViews>
            {stepCount > 1 && (
              // @ts-expect-error
              <MobileStepper
                data-testid="slide-stepper"
                sx={{ mt: 3, gap: 1.5, px: isDesktop() ? 6 : 3 }}
                steps={stepCount}
                position="static"
                activeStep={currentStep}
                nextButton={
                  <XyzTransition xyz="small-100% duration-1">
                    {currentStep + 1 < slides?.length! ? (
                      <Fab
                        data-testid="stepper-next-btn"
                        key="0"
                        sx={[
                          fabSx,
                          {
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          },
                        ]}
                        onClick={advanceStep}
                      >
                        <ArrowForward />
                      </Fab>
                    ) : (
                      <Fab
                        data-testid="stepper-end-btn"
                        disabled={!circular && currentStep + 1 === stepCount}
                        key="1"
                        sx={[
                          fabSx,
                          {
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                            '&:disabled': {
                              color: 'secondary.dark',
                            },
                          },
                        ]}
                        onClick={advanceStep}
                      >
                        <Check />
                      </Fab>
                    )}
                  </XyzTransition>
                }
              />
            )}
          </CardContent>
        </Card>
        {bottomBar && (
          <Box
            data-testid="bottom-bar"
            sx={{
              py: 3,
              px: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack alignItems="center" spacing={2} px={2}>
              <XyzTransition xyz="fade" mode="out-in">
                {bottomBar?.node}
              </XyzTransition>
            </Stack>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default InstallationModal;
