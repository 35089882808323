import { useTranslation } from 'react-i18next';

import { useHostingAddons } from '@newfold/huapi-js';
import { HostingAddons200RowsItem } from '@newfold/huapi-js/src/index.schemas';

import useAccount from '~/hooks/useAccount';
import useFeature from '~/hooks/useFeature';

export type ServerEmailChoiceTypes = {
  key: string;
  title: string;
  description: string;
  app: string;
  canShow: boolean;
  addonId?: number;
  addonList?: HostingAddons200RowsItem[];
};

const useServerEmailChoices = () => {
  const { id: accountId } = useAccount();
  const { t } = useTranslation('quickLinks');
  const [hasCpanel] = useFeature('cpanel');

  // check addons list, filtering to spam experts to verify that the customer has this product
  const { data: addonList } = useHostingAddons(
    accountId,
    {
      type: 'spamexperts',
      status: 'active',
    },
    {
      query: {
        enabled: hasCpanel && !!accountId,
      },
    },
  );

  // Does this account have any SE addons?
  const hasSpamExperts = addonList?.data?.rows?.length! > 0;

  // If only one SE addon, will function as usual
  const hasOneSpamExperts = addonList?.data?.rows?.length === 1;
  const spamExpertsAddonId =
    hasOneSpamExperts &&
    addonList?.data?.rows?.find((item) => item.status === 'active')?.id;

  // If multiple SE addons, a different flow will trigger in MultipleChoiceSsoDialog
  const hasMultipleSpamExperts = hasSpamExperts && !hasOneSpamExperts;

  // return a list of quick links for the server email choices dialog
  return [
    'accounts',
    'spamExperts',
    'forwarders',
    'filters',
    'globalFilters',
    'spamFilters',
    'autoresponders',
    'routing',
    // TODO: encryption is missing
  ].map((key) => ({
    // embellish each choice with the title, description, and app name (translations)
    key,
    title: t('serverEmail.choices.' + key + '.title'),
    description: t('serverEmail.choices.' + key + '.description'),
    app: t('serverEmail.choices.' + key + '.app'),
    // canShow: whether an item can be shown
    canShow: key === 'spamExperts' ? hasSpamExperts : true,
    // addonId is required for spamexperts SSO link
    addonId:
      key === 'spamExperts' && hasOneSpamExperts && spamExpertsAddonId
        ? parseInt(spamExpertsAddonId)
        : undefined,
    addonList:
      key === 'spamExperts' && hasMultipleSpamExperts
        ? addonList?.data?.rows
        : undefined,
  }));
};

export default useServerEmailChoices;
