import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { useHostingSitesV2 } from '@newfold/huapi-js';
import { HostingSitesV2200 } from '@newfold/huapi-js/src/index.schemas';

import { removeDeletedSites } from '~/utils/remove-deleted-sites';

type TData = AxiosResponse<HostingSitesV2200, any>;
type TError = AxiosError<unknown, any>;
type QueryOptionsTypes =
  | {
      query?: UseQueryOptions<TData, TError, TData, QueryKey> | undefined;
      axios?: AxiosRequestConfig<any> | undefined;
    }
  | undefined;

const useFilteredHostingSites = (
  hostingId: number,
  options?: QueryOptionsTypes,
) => {
  const { data, isLoading, isError, isSuccess, ...rest } = useHostingSitesV2(
    hostingId,
    options,
  );

  if (isLoading || isError) {
    return { ...rest, data, isLoading, isError, isSuccess };
  }

  const sites = data?.data?.items;
  const filteredSites = removeDeletedSites(sites);

  const sitesWithTypeWPForced = filteredSites?.map((site) => ({
    ...site,
    name:
      site.name === null && site.migration_id ? 'Migrating Site' : site.name, // Use site.name if not null, otherwise use default value
    type: site.type || 'wordpress', // TODO: Once the backend returns the type this default wordpress type can be removed
  }));

  return {
    ...rest,
    isLoading,
    isError,
    isSuccess,
    data: {
      ...data,
      data: { ...data?.data, items: sitesWithTypeWPForced },
    },
  };
};

export default useFilteredHostingSites;
