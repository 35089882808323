// Remove SX props
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

interface TextFilterInputPropOptions
  extends Omit<StandardTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
}

const TextFilterInput = ({ onChange, ...rest }: TextFilterInputPropOptions) => {
  const { t } = useTranslation('users');

  return (
    <TextField
      fullWidth
      id="text-filter-input"
      placeholder={t('filterUsers')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  );
};

type UsersFilterPropOptions = {
  roles: string[];
  setTextFilter: Dispatch<SetStateAction<string>>;
  dropdownFilter: string;
  setDropdownFilter: Dispatch<SetStateAction<string>>;
};

const UsersFilter = ({
  roles,
  setTextFilter,
  dropdownFilter,
  setDropdownFilter,
}: UsersFilterPropOptions) => {
  const { t } = useTranslation('users');

  return (
    <>
      <Grid xs={12} md={4} item>
        <TextFilterInput
          onChange={setTextFilter}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            mb: 3,
          }}
        />
      </Grid>
      <Grid xs={12} md={8} item>
        <Box display="flex" justifyContent="flex-end">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={dropdownFilter}
              onChange={(e) => setDropdownFilter(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                mb: 3,
              }}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextFilterInput
          onChange={setTextFilter}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
            mb: 3,
          }}
        />
      </Grid>
      <Grid xs={12} md={8} item>
        <Box display="flex" justifyContent="flex-end">
          <FormControl fullWidth>
            <Select
              value={dropdownFilter}
              onChange={(e) => setDropdownFilter(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
                mb: 3,
              }}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </>
  );
};

export default UsersFilter;
